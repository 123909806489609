import React, { useState } from 'react';
import { withFormik, FormikProps } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { IBidInfoForm, IWarningModal } from 'modules/BidInfoForm/interfaces';
import {
  defaultValues,
  defaultPostPayload as payload,
} from '../../shared/bidDefaultValues';
import { FormProvider, GenericModal } from 'core/components';
import ActionAlertModal from 'core/components/ActionAlertModal';
import { getQueryString, axiosInstance } from 'core/utils';
import { stripHTMLTags, decodeEntities, isWebView } from 'core/utils';
import { getBaseURL } from 'modules/BidInfoForm/utils';
import { parseFromApi, parseGetToPost } from 'modules/BidInfoForm/parser';
import { IAPIResponse } from 'modules/BidInfoForm/interfaces';
import { formatNumberByMask } from 'core/utils';
import SignNowScreen from 'modules/BidInfoForm/screens/SignNowScreen';
import BlockingPopupModal from 'modules/BidInfoForm/screens/BlockingPopupModal';
import {
  postBidInfo,
  getByUniqID,
  uploadOneTimeAttachment,
  getEasterEggSSTemplate,
  getBidInfoById,
  saveDraft,
  saveTemplate,
  postToApi,
  checkIsGeneratingPDF,
  isAlive,
  isTabIdValid,
  removeTemp,
} from 'modules/BidInfoForm/services/bidInfoService';
import {
  LoadingPage,
  BlockingOverlay,
} from 'modules/BidInfoForm/components/organisms';
import { toast } from 'react-toastify';
import EventEmitter from 'core/utils/events';
import {
  Header,
  ClientInfo,
  RoomInventory,
  FloorTypeTotals,
  Footer,
  AdditionalQuotes,
  ConfirmationModal,
} from 'modules/shared/sections';

import { ServiceProvider } from './sections';

import { BidFormMenuScreen } from 'modules/shared/Menu';

// import './styles.scss';
import { validateForm } from 'core/validators/BidInfoFormValidator';
import { getSpecialtyURL } from '../../BidInfoForm/utils';
import {
  getFranchiseById,
  verifyServerUnavailable,
} from '../../BidInfoForm/services/bidInfoService';
import { useActivityTracking } from '../../../core/hooks/useActivityTracking';
import { useAuthenticationHandler } from '../../../core/hooks/useAuthenticationHandler';
import { useGenericErrorHandler } from 'core/hooks/useGenericErrorHandler';
import { RenderPdf } from '../../../core/components';
import { ANAGO_CLEANSOURCE_DEV_LOGIN_URL } from '../../../core/utils';
import { getCookie } from 'core/utils/cookiesUtils';
export * from './SummarySuccess';

sessionStorage.removeItem('sessionStorageCleaningSpecification');

type BidFormType = FormikProps<IBidInfoForm> & RouteComponentProps;

const compareValues = (v1, v2) => {
  const orderedV1 = {},
    orderedV2 = {};

  Object.keys(v1)
    .sort()
    .forEach((key) => {
      if (
        key != 'uid' &&
        key != 'bidInfoId' &&
        key != 'originalBidUid' &&
        key != 'isTemp'
      ) {
        orderedV1[key] = v1[key];
      }
    });

  Object.keys(v2)
    .sort()
    .forEach((key) => {
      if (
        key != 'uid' &&
        key != 'bidInfoId' &&
        key != 'originalBidUid' &&
        key != 'isTemp'
      ) {
        orderedV2[key] = v2[key];
      }
    });
  const equal = JSON.stringify(orderedV1) == JSON.stringify(orderedV2);
  return equal;
};

const SpecialtyForm = ({
  values,
  setFieldValue,
  setValues,
  dirty,
  ...props
}: BidFormType) => {
  const [showExit, setShowExit] = useState(false);
  const [ready, setReady] = useState(false);
  const [tabId, setTabId] = useState(new Date().toISOString());

  const setAllValues = (params: any) => {
    return setValues({ ...values, ...params });
  };
  const [warningModal, setWarningModal] = useState<IWarningModal>(null);
  const [blockingModal, setBlockingModal] = useState<IWarningModal>(null);
  const [hadChanges, setHadChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirmation, setShowConfirmationModal] = useState(false);
  const [showMenu, setShowMenu] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [showAlreadySignedModal, setShowAlreadySignedModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showExpiredBid, setShowExpiredBid] = useState(false);
  const [needsSaving, setNeedsSaving] = useState(false);
  const [successModalData, setSuccessModalData] = useState({
    emails: '',
    emailBody: '',
    location: props.location,
    formUniqueId: '',
    franchiseId: '',
    bidLink: '',
  });

  const { userSignNowStatus } = values;
  const [userStatus, setUserStatus] = React.useState(null);
  const shouldShowSignNowModal = userStatus !== null && userStatus !== 2;
  const [showCustomizeTerms, setShowCustomizeTerms] = React.useState(false);

  const [minorThan768, setMinorThan768] = React.useState(false);

  const [autoSaveTimeout, setAutoSaveTimeout] = React.useState(1);
  const [executeAutoSave, setExecuteAutoSave] = React.useState(null);
  const [tempData, setTempData] = React.useState({});
  const [completeButtonEnabled, setCompleteButtonEnabled] = useState(true);
  const [blockAutoSave, setBlockAutoSave] = React.useState(false);
  const [showConnectionWarning, setShowConnectionWarning] =
    React.useState(false);
  const [showEmptyPidWarning, setShowEmptyPidWarning] = React.useState(false);
  const [newForm, setNewForm] = React.useState(false);

  const [showAutoPidTooltip, setShowAutoPidTooltip] = React.useState(false);

  const { state = {} } = props.location;
  const [franchiseData, setFranchiseData] = useState(null);

  React.useEffect(() => {
    if (state.formValues) {
      setTimeout(() => {
        setAllValues(state.formValues);
        toast.info('The proposal was duplicated successfully.');
        window.scrollTo(0, 0);
      }, 2000);
    }
  }, [state]);

  const setBreakpoints = () => {
    setMinorThan768(window.innerWidth < 768);
  };

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  React.useEffect(() => {
    const message =
      'This form is designed to be rendered on iPad sized screens or larger. Utilizing this form on a smaller screen will impact its usability. It is strongly recommended to change to larger device before proceeding.';
    if (minorThan768) {
      setWarningModal({
        message: message,
        proceed: () => {
          setWarningModal(null);
        },
      });
    } else {
      if (warningModal && warningModal.message == message) {
        setWarningModal(null);
      }
    }
  }, [minorThan768]);

  const onOpenSearch = () => {
    clearTimeout(autoSaveTimeout);
    setAutoSaveTimeout(null);
    setExecuteAutoSave(null);
    setReady(false);

    setShowMenu({
      renderTemplateSection: true,
      renderDraftSection: true,
      renderSentSection: true,
      renderSignedSection: true,
      title: 'SPECIALTY SERVICE SHEET',
    });
  };

  React.useEffect(() => {
    if (
      userSignNowStatus != null &&
      userSignNowStatus != undefined &&
      userSignNowStatus != userStatus
    ) {
      setUserStatus(userSignNowStatus == 1 ? 2 : userSignNowStatus);
    }
  }, [userSignNowStatus]);

  React.useEffect(() => {
    const openingPDF =
      getQueryString(window.location.href, 'openingPDF') === 'true';

    if (isWebView() && openingPDF) {
      props.history.goBack();
    }
  }, [props.history]);

  const showDeleteConfirmationModal = (
    proceedFunction: Function,
    isPlural: boolean = false
  ) => {
    setWarningModal({
      message: isPlural
        ? 'Are you sure you want to delete these items?'
        : 'Are you sure you want to delete this item?',
      proceed: () => {
        proceedFunction();
        setWarningModal(null);
      },
      dismiss: () => setWarningModal(null),
    });
  };

  const [bidData, setBidData] = useState({ id: '' });
  const [bidFullData, setBidFullData] = useState({});
  const [errors, setErrors] = useState({});

  const userId = getQueryString(window.location.href, 'userId');
  const [franchiseId, setFranchiseId] = useState(null);
  const { search } = props.location;

  // React.useEffect(() => {
  //   setFranchiseId(values.cityId);
  // }, [values.cityId]);

  React.useEffect(() => {
    if (values.cities) {
      if (values.cities.filter((i) => i['Id'] == values.cityId).length > 0) {
        setFranchiseId(values.cityId);
      } else if (values.cityId != values.cities[0]['Id']) {
        setFranchiseId(values.cities[0]['Id']);
      }
    } else if (!!franchiseId) {
      try {
        const cities = JSON.parse(sessionStorage.getItem('CITIES'));
        if (cities && cities.length > 0) {
          if (cities.filter((i) => i['Id'] == values.cityId).length > 0) {
            setFranchiseId(values.cityId);
            setFieldValue('franchiseId', franchiseId);
          }
        } else {
          setFieldValue('franchiseId', franchiseId);
        }
      } catch (e) {}
    }
  }, [values.cityId, values.cities, franchiseId]);

  React.useEffect(() => {
    const formId = getQueryString(search, 'formId');
    const uniqueId = getQueryString(search, 'formUniqueId');
    const urlTabId = getQueryString(search, 'tabId');
    const easterEgg = window.location.href.indexOf('pre-filled') >= 0;

    if (!!urlTabId) {
      setTabId(urlTabId);
    }

    const validTabId = urlTabId || tabId;

    const fetch = async (askIfTemplate = true) => {
      const goGetIt = easterEgg
        ? () => getEasterEggSSTemplate()
        : uniqueId
        ? () => getByUniqID(uniqueId)
        : () => getBidInfoById(formId);
      const foundValues = await goGetIt();
      const parsedValues = parseFromApi(foundValues.data['Data']);

      const isCityUser = getCookie('ANAGO_USER_IS_CITY_USER') === 'true';

      if (askIfTemplate && parsedValues.isTemplate && !easterEgg) {
        const askForTemplateMode = () =>
          setWarningModal({
            title: 'Template',
            message:
              'Do you want to edit or start a new draft based on the current template?',
            proceed: async () => {
              setIsLoading(true);
              // setBlockAutoSave(true);
              setExecuteAutoSave(null);
              setReady(false);

              parsedValues.tabId = tabId;
              const saveDraftResponse = await saveDraft(parsedValues, userId);

              window.location.replace(
                `#${
                  parsedValues.isSpecialty
                    ? '/specialty-form'
                    : '/bid-info-form'
                }?userId=${userId}&formUniqueId=${
                  saveDraftResponse.data.id
                }&tabId=${tabId}&v=${Math.random().toString(36).substring(7)}`
              );
              // setIsLoading(true);
              // setWarningModal(null)
            },
            dismiss: () => {
              fetch(false);
              setIsLoading(true);
              setWarningModal(null);
            },
            confirmText: 'NEW DRAFT',
            dismissText: 'EDIT TEMPLATE',
          });

        if (parsedValues.isCityTemplate) {
          if (!isCityUser) {
            // setError({status: 401})
            // return;
            setIsLoading(false);
            setWarningModal({
              title: 'Template',
              message:
                'This will crate a new draft based on your City template',
              proceed: async () => {
                setIsLoading(true);
                setBlockAutoSave(true);
                setExecuteAutoSave(null);
                setReady(false);

                parsedValues.tabId = tabId;
                const saveDraftResponse = await saveDraft(parsedValues, userId);
                window.location.replace(
                  `#${
                    parsedValues.isSpecialty
                      ? '/specialty-form'
                      : '/bid-info-form'
                  }?userId=${userId}&formUniqueId=${
                    saveDraftResponse.data.id
                  }&tabId=${tabId}&v=${Math.random().toString(36).substring(7)}`
                );
                // setIsLoading(true);
                // setWarningModal(null)
              },
            });
          } else {
            setIsLoading(false);
            askForTemplateMode();
          }
        } else {
          setIsLoading(false);
          askForTemplateMode();
        }
        return;
      }

      const userData = await axiosInstance.get(`GetUserById?userid=${userId}`);

      if (easterEgg) {
        parsedValues['uid'] = null;
        parsedValues['isTemplate'] = false;
        parsedValues['sentStatus'] = false;
        parsedValues['status'] = 0;
        parsedValues['bidInfoId'] = null;
        parsedValues['originalBidInfoId'] = null;
        parsedValues['createdBy'] = userId;
        parsedValues['userId'] = userId;
        parsedValues['bidDate'] = null;
        parsedValues['isExpired'] = false;
        parsedValues['createdByName'] = userData.data['Name'];
        parsedValues['expirationDays'] = 90;
      }

      let userHasBetaOptIn = false;
      let userSignNowStatus = -1;
      if (
        userData.data['IsOptIn'] != null &&
        userData.data['IsOptIn'] != undefined
      ) {
        userHasBetaOptIn = userData.data['IsOptIn'];
      }
      userSignNowStatus =
        userData.data['SignatureProviderStatus'] != null
          ? userData.data['SignatureProviderStatus']
          : -1;

      const openForm = (tempValues = false) => {
        const v = tempValues || parsedValues || {};
        setIsLoading(true);
        setTimeout(() => {
          setAllValues({
            ...v,
            userSignNowStatus,
            userHasBetaOptIn,
          });
          setNeedsSaving(false);
          if (isLoading) {
            setIsLoading(false);
          }
          // if (tempValues && v.status !== 2) {
          //   toast.info('Draft Auto-Save Enabled');
          //   startAutoSaveTimeout();
          // }
          setTimeout(() => {
            setAutoSaveTimeout(1);
            setHadChanges(false);
            setReady(true);
            EventEmitter.emit('VALIDATE_FIELDS', '');
          }, 1500);
        }, 1000);

        setShowAlreadySignedModal(v.status === 2);
        setShowExpiredBid(v.isExpired && v.status != 2);
        setWarningModal(null);
      };

      const continueFlow = async () => {
        if (foundValues.data['Data']['Status'] != 2 && !easterEgg) {
          try {
            if (foundValues.data['Data']['BidUniqueID'] && validTabId) {
              await isTabIdValid(
                foundValues.data['Data']['BidUniqueID'],
                validTabId
              );
            } else {
              await isAlive();
            }
          } catch (e) {
            const msg =
              e &&
              e.response &&
              e.response.data &&
              typeof e.response.data == 'string'
                ? e.response.data
                : undefined;

            setTimeout(() => {
              handleError(e);
            }, 0);
            openForm();
            logSentry(e, values);
            return;
          }
        }

        const tempValues = foundValues.data['DataTemp'];

        if (
          tempValues &&
          foundValues.data['Data']['Status'] != 2 &&
          !easterEgg &&
          !foundValues.data['Data']['IsTemplate']
        ) {
          setIsLoading(false);
          setWarningModal({
            message:
              'We found a draft for this form on the server. Do you want to start from where stopped?',
            proceed: () => {
              setIsLoading(true);

              const parsedTempValues = tempValues
                ? parseFromApi(tempValues)
                : null;

              openForm({
                ...parsedTempValues,
                uid: parsedValues.uid,
                bidInfoId: parsedValues.bidInfoId,
                isTemp: parsedValues.isTemp,
                originalBidUid: parsedValues.originalBidUid,
                sentStatus: parsedValues.sentStatus,
                isTemplate: parsedValues.isTemplate,
                isAutoSave: false,
              });

              setTempData(parsedTempValues);
              // setShowAutoPidTooltip(true);
            },
            dismiss: async () => {
              setIsLoading(true);

              await removeTemp(parsedValues.uid);

              setTempData(null);

              setWarningModal(null);

              openForm();
            },
            confirmText: 'YES',
            dismissText: 'NO',
          });
        } else {
          if (!!parsedValues.sentStatus) {
            openForm();
          } else {
            openForm();
            setTimeout(() => {
              setTempData(null);
              // setShowAutoPidTooltip(true);
              setAutoSaveTimeout(1);
            }, 1500);
          }
        }
      };

      continueFlow();
    };

    if (formId || uniqueId || easterEgg) {
      fetch().catch((e) => {
        logSentry(e, { uid: formId || uniqueId || 'easterEgg' });
        if (e.status === 500) {
          props.history.push(`/forms/unable-to-open`);
        }
      });
      setNewForm(false);
    } else {
      setTempData(null);
      setAutoSaveTimeout(null);
      setShowAutoPidTooltip(true);
      setNewForm(true);
      setHadChanges(false);
    }
  }, [search]);

  React.useEffect(() => {
    if (franchiseId) {
      getFranchiseById(franchiseId).then(({ data }) => {
        setFranchiseData(data);
        setTimeout(() => {
          if (newForm) {
            setHadChanges(false);
            setReady(true);
          }
        }, 1000);
      });
    }
  }, [franchiseId]);

  const confirmationProps = {
    isOpen: showConfirmation,
    setWarningModal,
    showDeleteConfirmationModal,
    onSaveButtonClick: async (data) => {
      setIsLoading(true);
      let serverData = await getByUniqID(bidData.id);
      serverData = serverData.data['Data'];
      const values = parseGetToPost(serverData);
      const obj = {
        ...values,
        GenerateBlankPDF: false,
        GenerateAttachmentsPDF: true,
        SelectedAttachments: data.attachments.others,
        SelectedSystemAttachments: data.attachments.systemAttachments,
        SelectedFranchiseAttachments: data.attachments.franchiseAttachments,
        CreatedBy: serverData['CreatedBy'] || userId,
      };

      if (data.attachments) {
        if (
          data.attachments.others.filter((i) => i != '' && i != 'industry-na')
            .length === 0 &&
          data.attachments.systemAttachments.length === 0 &&
          data.attachments.franchiseAttachments.length === 0
        ) {
          obj.GenerateAttachmentsPDF = false;
        }
      }

      if (Object.keys(data.attachments.oneTimeAttachment).length > 0) {
        await uploadOneTimeAttachment(
          bidData.id,
          data.attachments.oneTimeAttachment
        );
        obj.IncludeOneTimeAttachment = true;
      }

      const postResponse: IAPIResponse = await postBidInfo(obj);
      const formInfo: IAPIResponse = await getByUniqID(bidData.id);
      const formInfoData = formInfo.data['Data'];
      const emails = data.emailProposal;
      const emailBody = decodeEntities(stripHTMLTags(formInfoData['BidEmail']));
      setBidData(postResponse.data as any);
      setSuccessModalData({
        ...successModalData,
        emails,
        emailBody,
        history: props.history,
        bidLink: formInfoData['BidLink'],
        franchiseId: formInfoData['FranchiseId'],
        formUniqueId: postResponse.data.id,
      });
      setShowSuccessModal(true);
      setIsLoading(false);
    },
    onBackButtonClick: async (data, terms) => {
      try {
        if (values.uid && tabId) {
          await isTabIdValid(values.uid, tabId);
        } else {
          await isAlive();
        }
      } catch (e) {
        handleError(e);
        logSentry(e, values);
      }

      setBlockAutoSave(true);
      setReady(false);

      setFieldValue('terms', terms);

      setTimeout(() => {
        setBlockAutoSave(false);
        setReady(ready);
        setShowConfirmationModal(false);
      });
    },
    onCustomizeTermsButtonClick() {},
    success: showSuccessModal,
    successModalData,
    bidData,
    bidFullData,
    isSpecialty: true,
    franchiseId: franchiseId,
    franchiseData: franchiseData,
  };

  const doPost = async (
    values,
    callback = () => undefined,
    showToastr = true
  ) => {
    const response = await postToApi({
      values,
      userId,
      showToastr,
    });
    if (response.error) {
      setWarningModal({
        message: response.error as string,
        proceed: () => {
          setWarningModal(null);
        },
      });
      return false;
    }
    if (response.data) {
      // needs refactoring
      const resp = await getByUniqID(response.data.id);
      setBidFullData(resp.data.Data);
      setFieldValue('uid', response.data.id);
      setBidData(response.data as any);
      setAllValues({
        ...values,
        uid: response.data.id,
        sentStatus: true,
        isTemplate: false,
      });
      setSuccessModalData({
        ...successModalData,
        formUniqueId: response.data.id,
        userId,
      });
    }

    callback(response.data);
    return response.data;
  };

  const openProposalPDF = () => {
    // const url = `${getBaseURL()}/Pdf/Bid-Signed/AnagoProposal-${
    //   values.uid
    // }.pdf`;
    // window.location.href = url;
    // if (isWebView()) {
    //   props.history.push(
    //     `/bid-info-form/new-pdf-view?formUniqueId=${values.uid}&openingNewBidPDF=true`
    //   );
    // } else {
    //   window.open(
    //     `${getBaseURL()}/Pdf/Bid-Signed/AnagoProposal-${values.uid}.pdf`,
    //     '_blank'
    //   );
    //   // props.history.push(
    //   //   `/bid-info-form/new-pdf-view?formUniqueId=${values.uid}&openingNewBidPDF=true`
    //   // );
    // }
    setShowPdf(true);
  };

  const onDuplicateProposal = async () => {
    try {
      if (values.uid && tabId) {
        await isTabIdValid(values.uid, tabId);
      } else {
        await isAlive();
      }
    } catch (e) {
      handleError(e);
      logSentry(e, values);
      return;
    }

    const proceed = () => {
      setIsLoading(true);
      setTimeout(() => {
        props.history.push('/loading');
      }, 0);

      setTimeout(() => {
        props.history.push({
          pathname: '/specialty-form',
          search: `?userId=${userId}`,
          state: {
            formValues: {
              ...values,
              pid: null,
              uid: null,
              id: null,
              bidInfoId: null,
              isTemplate: false,
              isCityTemplate: false,
              sentStatus: false,
              status: null,
            },
          },
        });
      }, 0);
    };

    if (values.status == 2) {
      if (values.bidVersion == 1) {
        setWarningModal({
          title: 'WARNING: Removing Bid Overrides',
          message: (
            <>
              This proposal was created using previous version of Anago
              CleanSource™. The duplicate of this proposal will clear any Bid
              Overrides entered previously.
            </>
          ),
          dismiss: () => setWarningModal(null),
          proceed: proceed,
        });
      } else {
        proceed();
      }
    } else {
      if (values.bidVersion == 1) {
        setWarningModal({
          title: 'WARNING: Removing Bid Overrides',
          message: (
            <>
              This proposal was created using previous version of Anago
              CleanSource™. The duplicate of this proposal will clear any Bid
              Overrides entered previously.
              <br />
              <br />
              Proceeding will lose any unsaved changes. Are you sure you will
              wish to proceed?"
            </>
          ),
          dismiss: () => setWarningModal(null),
          proceed: proceed,
        });
      } else {
        setWarningModal({
          message:
            'Proceeding will lose any unsaved changes. Are you sure you will wish to proceed?',
          dismiss: () => setWarningModal(null),
          proceed: proceed,
        });
      }
    }
  };

  const onCompleteButtonClick = async (
    saveAndComplete = true,
    callback = () => {}
  ) => {
    setBlockAutoSave(true);
    try {
      if (values.uid && tabId) {
        await isTabIdValid(values.uid, tabId);
      } else {
        await isAlive();
      }
    } catch (e) {
      handleError(e);
      callback();
      setBlockAutoSave(false);
      logSentry(e, values);
      return;
    }

    if (values.status === 2) {
      openProposalPDF();
      callback();
      setBlockAutoSave(false);
      return;
    }

    const errors = validateForm(values, true);
    const hasErrors = errors.required.length > 0 || errors.incorrect.length > 0;
    setErrors(hasErrors ? errors : {});
    if (hasErrors) {
      callback();
      setBlockAutoSave(false);
      return;
    }

    if (values.uid && saveAndComplete) {
      try {
        const blankPdfResult = await checkIsGeneratingPDF(values.uid);
      } catch (e) {
        const generating = e.data['isGenerating'];
        if (generating) {
          setWarningModal({
            message:
              'Previous PDF Version still being generated. Please try again.',
            proceed: () => {
              setWarningModal(null);
            },
          });
          callback();
          setBlockAutoSave(false);
          return;
        }
      }
    }

    // const hasCleaningSpecs = !!sessionStorage.getItem(
    //   'sessionStorageCleaningSpecification'
    // );
    // const cleanSpecObject = JSON.parse(
    //   sessionStorage.getItem('sessionStorageCleaningSpecification')
    // );
    // if (hasCleaningSpecs && !!cleanSpecObject) {
    //   values.cleaningFrequency = cleanSpecObject;
    // }

    clearTimeout(autoSaveTimeout);
    setAutoSaveTimeout(null);
    setExecuteAutoSave(null);

    // Cleaning Background Data
    if (values.frequencyService === 'monthly') {
      values.frequency = values.secondFrequency = {
        selecteds: [],
        timeWindow: null,
      };
      values.addFrequency = false;
      values.smartClean = false;
      values.disinfection = null;
      values.secondDisinfection = null;
    } else {
      values.cleaning = null;
      values.secondCleaning = null;
      values.timeWindow = null;
      values.secondTimeWindow = null;
      values.monthlyDisinfection = null;
      values.secondMonthlyDisinfection = null;
      values.monthlyAddFrequency = null;
    }
    if (values.uid && values.isTemplate) {
      values.uid = null;
    }
    values.sentStatus = true;
    values.isTemplate = false;
    values.isCityTemplate = false;
    values.isSpecialty = true;

    if (saveAndComplete) {
      values.generateBlankPDF = true;
    } else {
      values.generateBlankPDF = false;
    }

    values.additionalQuotesTotal = 0;

    if (
      values.quotes.filter(
        (item) => item.unit || item.uponRequest || item.perHour || item.perMonth
      ).length > 0
    ) {
      values.additionalQuotesTotal = `${values.quotes.length} - Special Pricing`;
    } else {
      values.quotes
        .filter((item) => !!item.quoteSelect)
        .map((item) => {
          values.additionalQuotesTotal += parseFloat(item.quoteOverride)
            ? parseFloat(item.quoteOverride)
            : parseFloat(item.quoteTotal) || 0;
        });
      if (values.quotes.filter((item) => !!item.quoteSelect).length > 0) {
        values.additionalQuotesTotal = `${
          values.quotes.filter((item) => !!item.quoteSelect).length
        } - $${formatNumberByMask(values.additionalQuotesTotal, {
          decimalLimit: 2,
          thousandsSeparatorSymbol: ',',
        })}`;
      } else {
        values.additionalQuotesTotal = null;
      }
    }

    //set TabId before save
    values.tabId = tabId;
    values.bidVersion = 2;
    values.isAutoSave = false;

    setIsLoading(true);

    const data = await doPost(values, () => {}, false);
    // setShowConfirmationModal(true);

    setHadChanges(false);

    setIsLoading(false);

    if (data) {
      if (values.uid && !!values.sentStatus) {
        await removeTemp(values.uid);
      }

      setTempData(null);
      if (saveAndComplete) {
        setShowConfirmationModal(true);
      }

      let successMessage = '';

      if (values.isCityTemplate) {
        successMessage = 'Your city template was saved successfully';
      } else if (values.isTemplate) {
        successMessage = 'Your template was saved successfully';
      } else if (values.sentStatus) {
        successMessage = 'Your sent proposal was saved successfully';
      } else {
        successMessage = 'Your draft was saved successfully';
      }

      toast.info(successMessage);

      // setTimeout(() => {
      //   setIsLoading(false);
      // }, 100);

      // setTimeout(() => {
      //   props.history.push(`/specialty-form/summary-success/${data.id}`);
      // }, 1000);

      callback();

      setTimeout(() => {
        setBlockAutoSave(false);
        setAutoSaveTimeout(1);
      }, 0);
    } else {
      callback();
    }
  };

  const onSaveDraft = React.useCallback(
    async (isAutoSave = false, tempData = null) => {
      const v = tempData || values;

      try {
        if (values.uid && tabId) {
          await isTabIdValid(values.uid, tabId);
        } else {
          await isAlive();
        }
      } catch (e) {
        handleError(e, isAutoSave);
        logSentry(e, values);
        return { error: true };
      }

      if (!isAutoSave) {
        setIsLoading(true);
      } else {
        if (showConnectionWarning) {
          setShowConnectionWarning(false);
          toast.info('Draft Auto-Save Enabled');
        }
      }

      v.isSpecialty = true;
      v.userId = userId;
      //set TabId before save
      v.tabId = tabId;

      if (!isAutoSave) {
        v.isAutoSave = false;
      }

      setCompleteButtonEnabled(false);
      const response = await saveDraft(v, userId, isAutoSave);
      setCompleteButtonEnabled(true);
      setHadChanges(false);
      setReady(false);
      if (response.data) {
        if (!isAutoSave) {
          setFieldValue('uid', response.data.id);
          setBidData(response.data as any);
        } else {
          setFieldValue('isAutoSave', true);
        }
      }

      if (!isAutoSave) {
        setIsLoading(false);
      }

      setTimeout(() => {
        setReady(true);
      }, 0);

      return response;
    },
    [
      values,
      isAlive,
      setWarningModal,
      setIsLoading,
      saveDraft,
      setFieldValue,
      setHadChanges,
      setBidData,
      setShowConnectionWarning,
      showConnectionWarning,
    ]
  );

  const onSaveTemplate = async (isCityTemplate = false, isSaveAs = false) => {
    setBlockAutoSave(true);
    setExecuteAutoSave(null);
    const isCityUser = getCookie('ANAGO_USER_IS_CITY_USER') === 'true';
    try {
      const newValues =
        isCityTemplate && !isCityUser
          ? JSON.parse(JSON.stringify(values))
          : values;

      if (isCityTemplate && !isCityUser) {
        newValues.uid = null;
        newValues.bidInfoId = null;
      }

      try {
        if (newValues.uid && tabId) {
          await isTabIdValid(newValues.uid, tabId);
        } else {
          await isAlive();
        }
      } catch (e) {
        handleError(e);
        logSentry(e, newValues);
        return;
      }

      const proceed = async () => {
        try {
          setWarningModal(null);
          setIsLoading(true);

          newValues.isSpecialty = true;

          //set TabId before save
          newValues.tabId = tabId;

          const response = await saveTemplate(
            newValues,
            userId,
            isCityTemplate,
            isSaveAs
          );

          if (response.data) {
            if ((isCityTemplate && isCityUser) || isSaveAs) {
              setFieldValue('uid', response.data.id);
              setFieldValue('createdBy', userId);
              setFieldValue('userId', userId);
              setBidData(response.data as any);
            }
            await removeTemp(response.data.id);
          }

          setIsLoading(false);
          setBlockAutoSave(false);
          setHadChanges(false);

          if (isCityTemplate && !isCityUser) {
            setWarningModal({
              title: 'Success',
              message:
                'The Bid Form was successfully saved as a City Template. A Draft copy of the template will now be opened for further edits.',
              proceed: async () => {
                setIsLoading(true);
                setBlockAutoSave(true);
                setExecuteAutoSave(null);
                setReady(false);

                newValues.tabId = tabId;
                const saveDraftResponse = await saveDraft(newValues, userId);
                window.location.replace(
                  `#${
                    newValues.isSpecialty ? '/specialty-form' : '/bid-info-form'
                  }?userId=${userId}&formUniqueId=${
                    saveDraftResponse.data.id
                  }&tabId=${tabId}&v=${Math.random().toString(36).substring(7)}`
                );
              },
            });
          }

          return response;
        } catch (error) {
          setGenericError(error);
        }
      };

      if (values.status == 2) {
        if (values.bidVersion == 1) {
          setWarningModal({
            title: 'WARNING: Removing Bid Overrides',
            message: (
              <>
                This proposal was created using previous version of Anago
                CleanSource™. Save this proposal as template will clear any Bid
                Overrides entered previously.
              </>
            ),
            dismiss: () => setWarningModal(null),
            proceed: proceed,
          });
          return;
        }
      }

      proceed();
    } catch (e) {
      setGenericError(e);
    }
  };

  React.useEffect(() => {
    if (executeAutoSave && !blockAutoSave && values.status !== 2) {
      if (!values.pid) {
        setTimeout(() => {
          startAutoSaveTimeout();
        });
        return;
      }

      const save = async () => {
        const hasTemp = !!tempData && Object.keys(tempData).length > 0;
        let tValues = null;
        if (hasTemp) {
          tValues = {
            ...values,
            uid: tempData.uid,
            bidInfoId: tempData.bidInfoId,
            originalBidUid: tempData.originalBidUid,
            isTemp: true,
            userId: userId,
            isAutoSave: true,
          };
        } else if (values.uid && !!values.sentStatus) {
          tValues = {
            ...values,
            uid: null,
            bidInfoId: null,
            originalBidUid: values.uid,
            isTemp: true,
            userId: userId,
            isAutoSave: true,
          };
        } else {
          tValues = { ...values, userId: userId, isAutoSave: true };
        }

        if (
          (hasTemp && !compareValues(tValues, tempData)) ||
          (!hasTemp && hadChanges)
        ) {
          const response = await onSaveDraft(true, { ...tValues });
          if (!response.error) {
            // console.error('saved draft! id:' + response.data.id);
            if (!hasTemp && !values.sentStatus) {
              setFieldValue('uid', response.data.id);
            } else {
              tValues.uid = response.data.id;
              setTempData(tValues);
            }
            setTimeout(() => {
              setHadChanges(false);
            }, 0);
          } else {
            if (response.error !== true) {
              // toast.error('Error trying to Auto-Save');
              console.error('Error:' + response.error);

              if (response.status == '409') {
                setTempData(null);
                setAutoSaveTimeout(null);
                setExecuteAutoSave(null);
                clearTimeout(autoSaveTimeout);
                setBlockAutoSave(true);

                setWarningModal({
                  title: 'Warning!',
                  message:
                    'Autosave did not complete correctly. Please manually save this form to prevent loss of any data.',
                  proceed: () => setWarningModal(null),
                });
              }
            }
          }
        }
        setTimeout(() => {
          startAutoSaveTimeout();
        });
      };
      save();
    }
  }, [executeAutoSave]);

  const startAutoSaveTimeout = React.useCallback(() => {
    // console.error('starting auto-save timeout');
    setAutoSaveTimeout(
      setTimeout(() => {
        if (!blockAutoSave) {
          setExecuteAutoSave(Math.random());
        }
      }, 10 * 1000)
    );
  }, [hadChanges, blockAutoSave]);

  const initAutoSave = async () => {
    if (values.isTemplate) {
      return;
    }
    setShowEmptyPidWarning(false);
    try {
      if (values.uid && tabId) {
        await isTabIdValid(values.uid, tabId);
      } else {
        await isAlive();
      }
    } catch (e) {
      handleError(e, true);
      logSentry(e, values);
      if (!executeAutoSave) {
        setBlockAutoSave(false);
        setTimeout(() => {
          startAutoSaveTimeout();
        });
      }
      return;
    }

    setShowConnectionWarning(false);
    if (!executeAutoSave) {
      setBlockAutoSave(false);
      setTimeout(() => {
        startAutoSaveTimeout();
      });
      toast.info('Draft Auto-Save Enabled');
    }
  };

  const initAutoSaveOnBlur = () => {
    if (values.status != 2) {
      if (values.pid && !showEmptyPidWarning) {
        if (!autoSaveTimeout || autoSaveTimeout == 1) {
          initAutoSave();
        }
      }
    }
  };

  React.useEffect(() => {
    if (ready) {
      if (values.status != 2 && (!hadChanges || showConnectionWarning)) {
        setHadChanges(true);
        if (autoSaveTimeout == 1) {
          if (values.uid && !showConnectionWarning) {
            if (!values.pid) {
              setShowEmptyPidWarning(true);
            } else {
              initAutoSave();
            }
          }
        } else if (autoSaveTimeout == null) {
          if (!values.pid && newForm) {
            setShowEmptyPidWarning(true);
          } else if (newForm && !showConnectionWarning) {
            initAutoSave();
          }
        }
      }
    }
  }, [values]);

  React.useEffect(() => {
    return () => {
      setAutoSaveTimeout(null);
      setExecuteAutoSave(null);
      clearTimeout(autoSaveTimeout);
    };
  }, []);

  const { error, setError } = useAuthenticationHandler();
  const { error: genericError, setError: setGenericError } =
    useGenericErrorHandler();

  React.useEffect(() => {
    if (!!error && error.status == 401) {
      setIsLoading(false);
      setWarningModal({
        title: 'Access Denied',
        message:
          'You do not have permissions to access this form. Please contact Anago Support if you believe you have reached this in error.',
        proceed: () => {
          // setWarningModal(null);
          // setError(undefined)
          window.location.href = ANAGO_CLEANSOURCE_DEV_LOGIN_URL.replace(
            'login',
            'home'
          );
        },
      });
    } else if (!!error && error.status == 403) {
      try {
        if (error.data.includes('CLIENT_VERSION_MISMATCH')) {
          setIsLoading(false);
          setWarningModal({
            title: 'Attention',
            message:
              'Seems like your app is outdated. This may happen due to browser cache issues. Click OK to get the latest version from server.',
            proceed: () => {
              window.location.reload(true);
            },
          });
        }
      } catch (e) {
        setGenericError(e);
      }
    }
  }, [error]);

  React.useEffect(() => {
    if (!!genericError) {
      setIsLoading(false);
      logSentry(genericError, values);
      setWarningModal({
        title: 'Attention',
        message:
          'An unexpected error has occurred. Please contact AFISupport with Error ID: ' +
          sessionStorage.getItem('transaction_id'),
        proceed: () => {
          setWarningModal(null);
          setGenericError(undefined);
        },
      });
    }
  }, [genericError]);

  const logSentry = useActivityTracking(() => values);

  const handleError = (e, showBanner = false) => {
    const msg =
      e && e.response && e.response.data && typeof e.response.data == 'string'
        ? e.response.data
        : undefined;

    if (e && e.response && e.response.status == '401') {
      setBlockingModal({
        message: msg || e.response.status,
        proceed: () => {
          setWarningModal(null);
        },
      });
    } else if (e && e.response && e.response.status == '503') {
      setWarningModal({
        message: msg || e.response.status,
        proceed: () => {
          setWarningModal(null);
        },
      });
    } else {
      if (showBanner) {
        setShowConnectionWarning(true);
      } else {
        setWarningModal({
          message:
            "It looks like you don't have internet at the moment, please ensure you have internet access and try again.",
          proceed: () => {
            setWarningModal(null);
          },
        });
      }
    }
  };

  /// end

  React.useEffect(() => {
    (async () => {
      try {
        const result = await verifyServerUnavailable(userId);
        if (!result || result.error) {
          setBlockingModal({
            message:
              'Server under maintenance at this time, please try again later',
          });
        } else {
          setBlockingModal(null);
        }
      } catch (e) {
        if (e.response.status == '503') {
          setBlockingModal({
            message:
              'Server under maintenance at this time, please try again later',
          });
        }
      }
    })();
  }, []);

  return (
    <FormProvider
      needsSaving={needsSaving}
      setNeedsSaving={setNeedsSaving}
      formValues={values}
      onChange={setFieldValue}
      setFormValues={setAllValues}
      showWarningModal={setWarningModal}
      showConfirmationModal={setShowConfirmationModal}
      showDeleteConfirmationModal={showDeleteConfirmationModal}
    >
      <div className="inspection-root-div bid-form-root">
        <div className="inspection-content-screen bid-form-content-screen">
          {isLoading && <LoadingPage />}

          {/* <div style={{ display: showCustomizeTerms ? 'block' : 'none' }}>
            <CustomizeTerms
              location={{ search: `?formId=${bidData.id}` }}
              uuid={bidData.id}
              data={values}
              showing={showCustomizeTerms}
              franchiseId={franchiseId}
              setAllValues={setAllValues}
              onSave={(termsValues) => {
                setShowCustomizeTerms(false);
                setAllValues(termsValues);
              }}
              onCancel={() => setShowCustomizeTerms(false)}
            />
          </div> */}

          <ActionAlertModal
            message="This form has already been signed, and cannot be changed."
            isVisible={showAlreadySignedModal && !shouldShowSignNowModal}
            onConfirm={() => {
              setShowAlreadySignedModal(false);
            }}
          />

          <ActionAlertModal
            message="This Proposal has expired. Making any changes will reactivate the Proposal."
            isVisible={showExpiredBid}
            onConfirm={() => {
              setShowExpiredBid(false);
            }}
          />

          {blockingModal && (
            <BlockingOverlay>
              <BlockingPopupModal message={blockingModal.message} />
            </BlockingOverlay>
          )}

          {shouldShowSignNowModal && (
            <BlockingOverlay>
              <SignNowScreen
                userId={userId}
                status={userStatus}
                setStatus={setUserStatus}
                setLoading={setIsLoading}
              />
            </BlockingOverlay>
          )}
          <Header
            showConnectionWarning={showConnectionWarning}
            showAutoPidTooltip={showAutoPidTooltip}
            showEmptyPidWarning={showEmptyPidWarning}
            setShowEmptyPidWarning={setShowEmptyPidWarning}
            initAutoSave={initAutoSaveOnBlur}
            onExit={() => setShowExit(true)}
            openSearch={onOpenSearch}
            setIsLoading={setIsLoading}
            setNeedsSaving={setNeedsSaving}
            title="Specialty Service(s) Info"
            searchButtonLabel="Open Specialty forms"
          />
          <ClientInfo />
          <ServiceProvider />
          <RoomInventory isSpecialty />
          <FloorTypeTotals />
          <AdditionalQuotes isSpecialty />
          <Footer
            onCompleteButtonClick={onCompleteButtonClick}
            completeButtonEnabled={completeButtonEnabled}
            saveDraft={onSaveDraft}
            saveTemplate={onSaveTemplate}
            // onClickCustomizeTerms={() => setShowCustomizeTerms(true)}
            duplicateProposal={onDuplicateProposal}
          />
        </div>
      </div>
      {warningModal && (
        <ActionAlertModal
          confirmText={warningModal.confirmText}
          dismissText={warningModal.dismissText}
          title={warningModal.title}
          message={warningModal.message}
          onConfirm={warningModal.proceed}
          onDismiss={warningModal.dismiss}
          isVisible={true}
        />
      )}
      <ActionAlertModal
        title="Exit without saving?"
        message="Remember, that if you exit without saving, you will lose all progress."
        onConfirm={() => {
          setShowExit(true);
          if (isWebView()) {
            props.history.push('/close-form');
          } else {
            document.location.href = getSpecialtyURL();
          }
        }}
        onDismiss={() => setShowExit(false)}
        isVisible={showExit}
      />

      <ActionAlertModal
        message={errors}
        onConfirm={() => setErrors({})}
        isVisible={Object.keys(errors).length > 0}
      />

      {showMenu && (
        <GenericModal
          isOpen={showMenu}
          showHeader={false}
          clickOutsideToClose={false}
          fullscreen={false}
          height="710px"
          width="600px"
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <BidFormMenuScreen
            isSpecialty={true}
            {...props}
            showBackground={false}
            {...showMenu}
            doneAction={(state) => {
              setShowMenu(null);
              setIsLoading(state);
              if (!state) {
                setAutoSaveTimeout(1);
                setExecuteAutoSave(Math.random());
                setReady(true);
              }
            }}
          />
        </GenericModal>
      )}

      {showPdf && (
        <GenericModal
          isOpen={showPdf}
          showHeader={false}
          clickOutsideToClose={false}
          fullscreen={false}
          height="100%"
          width="100%"
          // maxWidth="1200px"
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <RenderPdf
            onClickClose={() => setShowPdf(false)}
            pdfName={`SpSvcAgreement_${values.pid}`}
            type={21}
            uid={values.uid}
          />
        </GenericModal>
      )}

      {showConfirmation && <ConfirmationModal {...confirmationProps} />}
    </FormProvider>
  );
};

const mapForm = {
  mapPropsToValues: () => ({
    ...defaultValues,
    isSpecialty: 1,
  }),
  handleSubmit: () => '',
  enableReinitialize: true,
};

const FormEnhanced = withFormik<any, IBidInfoForm>(mapForm)(
  withRouter(SpecialtyForm)
);

export { FormEnhanced as SpecialtyForm };
