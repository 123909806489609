import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faFilePdf,
  faEnvelope,
  faTimes,
  faCopy,
} from '@fortawesome/free-solid-svg-icons';
import { GenericAccordion, Button } from '../../../../core/components';
import {
  isWebView,
  decodeEntities,
  stripHTMLTags,
  isSafari14,
} from '../../../../core/utils';
import { getBidURL, getSpecialtyURL } from '../../utils';
import { LoadingPage } from '../../components/organisms';
import { getByUniqID, getBidInfoById } from '../../services/bidInfoService';
import {
  Header,
  Heading,
  Text,
  AccordionHeader,
  ButtonHolder,
  CellHolder,
  EmailTextHolder,
  Actions,
  ActionButtonWrapper,
  ActionButtonLabel,
  AccordionBody,
  IconWrapper,
  ModalTopActions,
  ButtonLabel,
  EmailItem,
} from './styles';
import {
  Cell,
  Row,
  RowHolder,
  Button as Btn,
} from 'modules/shared/sections/Summary/styles';
import { toast } from 'react-toastify';
import { ModalWrapper, Layout } from '../../components/layout';

const openEmailApp = (data: any) => {
  const url = `mailto:${data.to}?subject=${data.subject}&body=${
    isSafari14() ? data.body : encodeURIComponent(data.body)
  }`;

  window.open(url, 'New Email');
};

const openProposal = (url: string) => {
  //let str = '';
  //if (!isWebView()) {
  //  if (url.indexOf('?') >= 0) {
  //    const split = url.split('?');
  //    str = split[0] + '?code=print-pdf&' + split[1];
  //  } else {
  //    str = url + '?code=print-pdf';
  //  }
  //} else {
  //  str = url;
  //}

  // const str = !isWebView()
  //   ? `${url}${url.indexOf('?') >= 0 ? '&' : '?'}code=print-pdf`
  //   : url;

  const str = isWebView() ? `${url}?code=print-pdf` : url;

  // const str = `${url}?code=print-pdf`;
  window.open(str, '_blank');
};

const isUniqueId = (hash: string) => hash.split('-').length > 1;

export const SummarySuccess = (props) => {
  const { params } = props.match;
  const buttonProps = {
    color: '#fff',
    bgColor: '#216eab',
    width: '115px !important',
    padding: '0 10px',
    skewable: false,
    borderRadius: '3px',
    height: '44px',
  };
  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const emails = params.emails ? params.emails.split(';') : [];
  const emailBody = data['BidEmail']
    ? decodeEntities(stripHTMLTags(data['BidEmail']))
    : '';
  const bodyRef = React.useRef<HTMLPreElement>();
  const toRef = React.useRef<HTMLDivElement>();

  const textCopy = !data['IsSS']
    ? data['CreatedByName'] +
      ' has shared (A) an Anago Proposal and (B) additional information with you.\n' +
      '(A): ' +
      data['BidLink'] +
      '\n(B): ' +
      data['AttachmentLink']
    : data['CreatedByName'] +
      ' has shared an Anago Specialty Services proposal with you. Use the link below to view the Services.\n' +
      'Proposal: ' +
      data['BidLink'];

  function executeCopy(text) {
    var input = document.createElement('textarea');
    document.body.appendChild(input);
    input.value = text;
    input.focus();
    input.select();
    document.execCommand('Copy');
    input.remove();
  }

  const copyTo = () => {
    if (!navigator.clipboard) {
      var doc = new DOMParser().parseFromString(
        document.getElementById('copyTo').innerHTML,
        'text/html'
      );
      executeCopy(doc.body.textContent);
      showToast();
      return;
    }

    const text = Array.from(toRef.current.querySelectorAll('div'))
      .map((x) => x.textContent)
      .concat([''])
      .join(',');

    navigator.clipboard.writeText(text);
    showToast();
  };

  const copyBody = () => {
    if (!navigator.clipboard) {
      var doc = new DOMParser().parseFromString(
        document.getElementById('copyBody').innerHTML,
        'text/html'
      );
      executeCopy(doc.body.textContent);
      showToast();
      return;
    }

    const text = decodeEntities(bodyRef.current.innerHTML);
    navigator.clipboard.writeText(text);
    showToast();
  };

  const onDoneButtonClick = () => {
    if (isWebView()) {
      // It's a webview, so we call the close-form route
      // to trigger the Xamarin webview
      // setLoadingOnCloseState(true);
      props.history.replace('close-form');
    } else {
      /*
        It's a regular desktop browser.
        In this case, we redirect to the
        Bid Information List screen on the
        Backend App Portal

        App URLs:
        https://anago-forms-app-stag.herokuapp.com
        https://anago-forms-app-prod.herokuapp.com/
        https://anago-forms-app-rc.herokuapp.com/
      */
      const url = data['IsSS'] ? getSpecialtyURL() : getBidURL();

      document.location.href = url;
    }
  };

  const [emailContent, setEmailContent] = React.useState('');

  const onOpenEmailProposalClick = () => {
    setEmailContent(encodeURIComponent(stripHTMLTags(emailBody)));
    const body = stripHTMLTags(emailBody);

    if (isSafari14()) {
      executeCopy(body.toString());
    }

    openEmailApp({
      to: emails.join(','),
      subject: 'Anago Service Procurement Agreement',
      body: body,
    });
  };

  const onCopyToClipBoard = () => {
    executeCopy(textCopy);
    showToast();
  };

  const onOpenProposalClick = () => {
    openProposal(data['BidLink']);
  };

  const showToast = () => {
    toast.info('Copied to Clipboard!');
  };

  const onCloseModalClick = () => {
    onDoneButtonClick();
  };

  React.useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      const method = isUniqueId(params.id) ? getByUniqID : getBidInfoById;
      const response = await method(params.id);

      setData(response.data.Data);
      setIsLoading(false);
    };

    if (params.id) {
      fetch();
    }
  }, [params.id]);

  if (isLoading) {
    return <LoadingPage />;
  }
  console.debug({ params });
  return (
    <ModalWrapper>
      <Layout
        padding="50px 20px"
        margin="40px"
        maxWidth="900px"
        width="90%"
        backgroundColor="#014984"
        position="relative"
        overflow="auto"
        borderRadius="10px"
      >
        {!params.id && <Heading>The ID parameter is missing</Heading>}

        {params.id && (
          <>
            <ModalTopActions>
              <button onClick={onCloseModalClick}>
                <FontAwesomeIcon size="2x" icon={faTimes} />
              </button>
            </ModalTopActions>
            <Header>
              <IconWrapper>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="5x"
                  color="#80c066"
                />
              </IconWrapper>
              {!data['IsSS'] && (
                <Heading>Your Proposal was saved successfully</Heading>
              )}
              {data['IsSS'] && (
                <Heading>
                  Your Specialty Service Proposal was saved successfully
                </Heading>
              )}
            </Header>

            <Actions>
              <Button
                color="#fff"
                bgColor="#F7A11D"
                reverseSkew={true}
                onClick={onOpenProposalClick}
              >
                <ActionButtonWrapper>
                  <FontAwesomeIcon icon={faFilePdf} />
                  <ActionButtonLabel>View Proposal</ActionButtonLabel>
                </ActionButtonWrapper>
              </Button>
              <Button
                color="#fff"
                bgColor="#F7A11D"
                reverseSkew={true}
                onClick={onOpenEmailProposalClick}
              >
                <ActionButtonWrapper>
                  <FontAwesomeIcon icon={faEnvelope} />
                  <ActionButtonLabel>Email Proposal</ActionButtonLabel>
                </ActionButtonWrapper>
              </Button>
              <Button
                color="#fff"
                bgColor="#F7A11D"
                reverseSkew={true}
                onClick={onCopyToClipBoard}
              >
                <ActionButtonWrapper>
                  <FontAwesomeIcon icon={faCopy} />
                  <ActionButtonLabel>Copy Link</ActionButtonLabel>
                </ActionButtonWrapper>
              </Button>
            </Actions>
            <GenericAccordion
              contentHeight={400}
              containerStyle={{ overflow: 'auto' }}
              headerStyle={{
                backgroundColor: '#014984',
                padding: '18px',
                paddingTop: 0,
              }}
              contentStyle={{ overflow: 'auto' }}
              iconStyle={{ marginLeft: 'unset', fill: '#fff' }}
              hideAccordion
              buttonActiveText="View Email"
              buttonInactiveText="Hide Email"
              renderHeader={() => (
                <AccordionHeader style={{ width: 'fit-content' }}>
                  <Text>You may also copy the email content manually.</Text>
                </AccordionHeader>
              )}
            >
              <AccordionBody>
                <RowHolder>
                  <Row>
                    <Cell>
                      <ButtonHolder>
                        <Btn {...buttonProps} onClick={copyTo}>
                          <ButtonLabel>Copy To:</ButtonLabel>
                        </Btn>
                      </ButtonHolder>
                    </Cell>
                    <Cell ref={toRef} id="copyTo">
                      {emails.length > 0 &&
                        emails.map((email) => <EmailItem>{email}</EmailItem>)}
                    </Cell>
                  </Row>
                </RowHolder>
                <RowHolder>
                  <Row>
                    <Cell>
                      <ButtonHolder>
                        <Btn {...buttonProps} onClick={copyBody}>
                          <ButtonLabel>Copy Body:</ButtonLabel>
                        </Btn>
                      </ButtonHolder>
                    </Cell>
                    <Cell>
                      <EmailTextHolder
                        id="copyBody"
                        ref={bodyRef}
                        dangerouslySetInnerHTML={{
                          __html: emailBody,
                        }}
                      ></EmailTextHolder>
                    </Cell>
                  </Row>
                </RowHolder>
              </AccordionBody>
            </GenericAccordion>
          </>
        )}
      </Layout>
    </ModalWrapper>
  );
};
