import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
// redux
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { InspectFormActions } from 'modules/InspectForm/redux/actions';
import * as Sentry from '@sentry/react';
// interfaces
import { IReduxState } from 'core/interfaces';
import {
  IStateToProps,
  IDispatchToProps,
} from 'modules/InspectForm/interfaces';
import { getRatingClassName, getQueryString } from 'core/utils';
import { isAlive } from 'modules/BidInfoForm/services/bidInfoService';
// ui
import calculatorIcon from 'assets/icons/calculator.svg';
import { CalculatorImg } from './styles';
import './styles.scss';
import 'styles/colors.scss';
import {
  ActionAlertModal,
  BubbleButton,
} from '../../../../../../core/components';
import {
  validatePhone,
  validatePostalCode,
} from '../../../../../../core/validators/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import addNoteIcon from '../../../../../../assets/icons/add-note.svg';

interface IOwnProps {
  setNeedsSaving(needsSaving: boolean): void;
}

type IProps = IDispatchToProps &
  IStateToProps &
  IOwnProps &
  RouteComponentProps;

class FooterBar extends React.PureComponent<IProps> {
  public state = {
    showSaveBubble: false,
    hideButtonDesktop: '',
    warningModal: null,
  };

  public componentDidMount() {
    const currentUrl = this.props.location.search;
    const hideButtonDesktop = getQueryString(currentUrl, 'hideButtonDesktop');

    this.setState({
      hideButtonDesktop,
    });
  }

  public onClickSave = () => {
    // const { isSent } = this.props;
    const { showSaveBubble } = this.state;
    // if (!isSent) {
    this.setState({ showSaveBubble: !showSaveBubble });
    // }
  };

  public validateFormData = () => {
    const incomp = [];

    if (this.props.Phone) {
      var el = document.querySelectorAll(
        '.walk-thru-contact-phone-container > div'
      )[0];
      if (!validatePhone(this.props.Phone)) {
        incomp.push('PHONE');
        if (el) {
          el.classList.add('is-invalid');
        }
      } else {
        if (el && el.classList.contains('is-invalid')) {
          el.classList.remove('is-invalid');
        }
      }
    }

    if (this.props.Zip) {
      var el = document.querySelectorAll(
        '.postal-code-field-container > div'
      )[0];
      if (!validatePostalCode(this.props.Zip.replace(/\_/, ''))) {
        incomp.push('POSTAL CODE');
        if (el) {
          el.classList.add('is-invalid');
        }
      } else {
        if (el && el.classList.contains('is-invalid')) {
          el.classList.remove('is-invalid');
        }
      }
    }

    return incomp;
  };

  public onClickOptions = async (
    type: 'draft' | 'template' | 'sent' | 'citytemplate' | 'duplicate',
    newForm?: 'new'
  ) => {
    const { isDraft, isTemplate, isCityTemplate, isSent, transformedToDraft } =
      this.props;
    try {
      const errors = this.validateFormData();
      if (errors.length > 0) {
        this.setState({
          warningModal: {
            message: { incorrect: errors, required: [] },
            proceed: () => {
              this.setState({ warningModal: null });
            },
          },
        });
        return;
      }
    } catch (e) {
      console.log({ e });
      // Sentry.captureException(e);
      return;
    }

    try {
      await isAlive();
    } catch (e) {
      this.setState({
        warningModal: {
          message:
            "It looks like you don't have internet at the moment, please ensure you have internet access and try again.",
          proceed: () => {
            this.setState({ warningModal: null });
          },
        },
      });

      Sentry.captureException(e);
      return;
    }

    window.scrollTo(0, 0);
    // const { isSent } = this.props;

    const currentUrl = this.props.location.search;

    // const franchiseId = params.get("FranchiseId");
    const franchiseId = getQueryString(currentUrl, 'FranchiseId');
    // const userId = params.get("UserId");
    const userId = getQueryString(currentUrl, 'UserId');

    if (!(type === 'duplicate')) {
      // if (!isSent) {
      const { saveReportRequest } = this.props;
      this.setState({ showSaveBubble: false });
      // saveReportRequest(type, this.props.setNeedsSaving, newForm);
      if (isDraft) {
        if (type == 'draft' || type == 'sent') {
          saveReportRequest(type);
        } else {
          saveReportRequest(type, 'saveas');
        }
      } else {
        saveReportRequest(type, newForm);
      }

      if (this.props.onSaveCallback) {
        this.props.onSaveCallback();
      }
      // }
    } else {
      const proceed = () => {
        this.props.setLoadingState(true);
        const newValues = { ...this.props.values };
        newValues.id = null;
        newValues.InspectionReportId = null;
        newValues.inspectionReportId = null;
        newValues.formId = null;

        localStorage.setItem('INSPECTION_DUPLICATE', JSON.stringify(newValues));
        window.location.replace(
          `#/inspection-form?userId=${userId}&isDuplicate=true&v=${Math.random()
            .toString(36)
            .substring(7)}`
        );
        window.location.reload();
      };
      //askconfirmation

      if (!isSent) {
        this.setState({
          warningModal: {
            message:
              'Proceeding will lose any unsaved changes. Are you sure you will wish to proceed?',
            dismiss: () => this.setState({ warningModal: null }),
            proceed: proceed,
          },
        });
      } else {
        proceed();
      }
    }
  };

  public closeSaveBubble = () => {
    this.setState({ showSaveBubble: false });
  };

  public render() {
    const { showSaveBubble, hideButtonDesktop } = this.state;
    const {
      FacilityRating,
      setNotesModalState,
      isDraft,
      isTemplate,
      isCityTemplate,
      isSent,
      status,
      transformedToDraft,
    } = this.props; // saveReportRequest
    const areaClassName = getRatingClassName(FacilityRating);

    return (
      <div className="form-footer">
        <div className="content">
          {/* <div className="score-overall-container">
            <div className="content">
              <p>Overall Rating:</p>
              <span className={`score ${areaClassName}`}>
                {FacilityRating.toFixed(1)}
              </span>
            </div>
          </div> */}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {/* <button
              style={{
                maxWidth: '140px'
              }}
              className={this.props.Notes ? 'button-orange' : 'button-dark'}
              onClick={() => setNotesModalState(true)}
            >
              {isSent
                ? 'VIEW NOTE'
                : this.props.Notes
                ? 'EDIT NOTE'
                : 'ADD NOTE'}
            </button> */}

            <div
              style={{
                background: `url(${addNoteIcon}) no-repeat`,
                width: '32px',
                height: '32px',
              }}
              onClick={() => setNotesModalState(true)}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {status !== 2 && !isSent && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    pointerEvents: isSent ? 'none' : 'auto',
                  }}
                  onClick={() => {
                    const type =
                      !isSent && isDraft
                        ? 'draft'
                        : isTemplate && !isCityTemplate
                        ? 'template'
                        : isTemplate && isCityTemplate
                        ? 'citytemplate'
                        : 'draft';

                    const isNew = type === 'draft' && transformedToDraft;

                    if (type) {
                      if (isNew) {
                        this.onClickOptions(type, 'new');
                      } else {
                        this.onClickOptions(type);
                      }
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSave}
                    style={{ fontSize: 36 }}
                    size={'1x'}
                    color={'black'}
                  />
                </div>
              )}

              <button
                style={{
                  marginLeft: '35px',
                }}
                className="button-secondary"
                onClick={() => {
                  // this.onClickSave()
                  hideButtonDesktop === 'true'
                    ? this.onClickOptions('template', 'new')
                    : this.onClickSave();
                }}
              >
                {/* SAVE AS */}
                {hideButtonDesktop === 'true' ? 'SAVE TEMPLATE' : 'SAVE AS'}
                {showSaveBubble && (
                  <>
                    <div
                      onClick={this.closeSaveBubble}
                      className="save-as-backdrop"
                    />
                    <div className="bubble">
                      <div className="content">
                        {(!(isDraft || isTemplate || isCityTemplate) ||
                          isSent) && (
                          <>
                            <div
                              className="first-row underlined"
                              onClick={() => {
                                this.onClickOptions('duplicate', 'new');
                              }}
                            >
                              DUPLICATE
                            </div>
                            <div
                              className="second-row underlined"
                              onClick={() =>
                                this.onClickOptions('template', 'new')
                              }
                            >
                              MY TEMPLATE
                            </div>
                            <div
                              className="second-row underlined"
                              onClick={() =>
                                this.onClickOptions('citytemplate', 'new')
                              }
                            >
                              CITY TEMPLATE
                            </div>
                            {!isSent && (
                              <div
                                className="second-row"
                                onClick={() =>
                                  this.onClickOptions('draft', 'new')
                                }
                              >
                                NEW DRAFT
                              </div>
                            )}
                          </>
                        )}

                        {!isSent && isDraft && (
                          <>
                            <div
                              className="first-row underlined"
                              onClick={() => {
                                this.onClickOptions('duplicate', 'new');
                              }}
                            >
                              DUPLICATE
                            </div>
                            <div
                              className="second-row underlined"
                              onClick={() =>
                                this.onClickOptions('template', 'new')
                              }
                            >
                              MY TEMPLATE
                            </div>
                            <div
                              className="second-row"
                              onClick={() =>
                                this.onClickOptions('citytemplate', 'new')
                              }
                            >
                              CITY TEMPLATE
                            </div>
                          </>
                        )}

                        {!isSent && isTemplate && (
                          <>
                            <div
                              className="first-row underlined"
                              onClick={() => {
                                this.onClickOptions('duplicate', 'new');
                              }}
                            >
                              DUPLICATE
                            </div>
                            <div
                              className="second-row underlined"
                              onClick={() =>
                                this.onClickOptions(
                                  isCityTemplate ? 'template' : 'citytemplate',
                                  'new'
                                )
                              }
                            >
                              {isCityTemplate ? 'MY TEMPLATE' : 'CITY TEMPLATE'}
                            </div>
                            <div
                              className="second-row"
                              onClick={() =>
                                this.onClickOptions('draft', 'new')
                              }
                            >
                              NEW DRAFT
                            </div>
                          </>
                        )}

                        {/* {!(
                          isDraft ||
                          isTemplate ||
                          isSent ||
                          hideButtonDesktop === 'true'
                        ) && (
                          <>
                            <div
                              className="first-row underlined"
                              onClick={() => this.onClickOptions('draft', 'new')}
                            >
                              NEW DRAFT
                            </div>
                            <div
                              className="second-row"
                              onClick={() =>
                                this.onClickOptions('template', 'new')
                              }
                            >
                              NEW TEMPLATE
                            </div>
                          </>
                        )} */}
                      </div>
                    </div>
                  </>
                )}
              </button>
              <button
                style={{
                  marginLeft: '35px',
                }}
                disabled={isTemplate || hideButtonDesktop === 'true'}
                className={`button ${
                  isTemplate || hideButtonDesktop === 'true'
                    ? 'disabled button-dark'
                    : ''
                }`}
                onClick={() =>
                  isSent
                    ? this.props.openSentModal()
                    : this.onClickOptions('sent')
                }
              >
                {isSent ? 'EMAIL REPORT' : 'COMPLETE'}
              </button>
            </div>
          </div>
        </div>
        {this.state.warningModal && (
          <ActionAlertModal
            style={{ color: 'black' }}
            message={this.state.warningModal.message}
            onConfirm={this.state.warningModal.proceed}
            onDismiss={this.state.warningModal.dismiss}
            isVisible={true}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: IReduxState) => ({
  ...state.inspectForm,
  values: state.inspectForm,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...InspectFormActions }, dispatch);

const Connected = connect<IStateToProps, IDispatchToProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FooterBar));

export { Connected as FooterBar };
