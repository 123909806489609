import { AnyAction } from 'redux';
import arraySort from 'array-sort';
import { Types } from './types';
import { inspectionReportDefaultAreas } from '../../../core/utils/defaultAreas';

import {
  IStateToProps,
  IChangeArea,
  IError,
  ISetArea,
  ISetNote,
  ISetFirstForm,
  ISetSiteInfo,
  ICalculateRates,
  IGetEditFormSuccess,
  ISuccessOnSave,
  IControlModalState,
  IControlWarningModalState,
  IControlPdfModalState,
  IGetInspectionsSuccess,
  IOpenForm,
  IAddPreDefinedArea,
  IGetNextInspectionPageSuccess,
  ISortingOption,
  ISetUserAndFranchiseId,
  ISetFormClicked,
  IInspectedBy,
  ISetItem,
} from '../interfaces';
import { IArea } from '../../../core/interfaces';

const initialState: IStateToProps = {
  // state fields
  error: null,
  selectedArea: null,
  FacilityRating: 0,
  Token: '',
  editing: false,
  loading: false,
  isDraft: false,
  isTemplate: false,
  isSent: false,
  successOnSave: null,
  successResult: null,
  successOnArchive: null,
  // modal fields
  draftItems: [],
  totalOfDraftItems: 0, // totals in backend
  sentItems: [],
  totalOfSentItems: 0,
  templateItems: [],
  totalOfTemplateItems: 0,
  cityTemplateItems: [],
  totalOfCityTemplateItems: 0,
  // api fields
  Owner: '',
  FranchiseId: 0,
  CreatedBy: 0,
  PID: '',
  InspectionClassNumber: '',
  ContactForm: '1',
  Date: '',
  AccountName: '',
  AccountAddress: '',
  Suite: '',
  City: '',
  State: '',
  Zip: '',
  DaysCleanedMonday: false,
  DaysCleanedTuesday: false,
  DaysCleanedWednesday: false,
  DaysCleanedThursday: false,
  DaysCleanedFriday: false,
  DaysCleanedSaturday: false,
  DaysCleanedSunday: false,
  Contact: '',
  Phone: '',
  FranchiseOwnerName: '',
  Time: '',
  Areas: inspectionReportDefaultAreas,
  Notes: '',
  GrandTotal: 0,
  showNotesModal: false,
  showPhotosModal: false,
  showRatingModal: false,
  showExitModal: false,
  showAddAreasModal: false,
  showAddItemModal: false,
  showWarningModal: {
    status: false,
    message: '',
  },
  showPdfModal: {
    status: false,
    pdfUrl: '',
  },
  MasterCity: '',
  InspectionReportId: 0,
  transformedToDraft: false,
  InspectedByInStore: '',
};

export default function inspectForm(state = initialState, action: AnyAction) {
  switch (action.type) {
    case Types.SET_ITEM: {
      const { areaItem } = (action as ISetItem).payload;

      const areaSelected = state.selectedArea;

      return {
        ...state,
        Areas: state.Areas.map((item: IArea) => {
          if (item.Name === areaSelected.Name) {
            return {
              ...item,
              AreaItems: [...item.AreaItems, areaItem],
            };
          }
          return item;
        }),
      };
    }
    case Types.SET_AREA: {
      return {
        ...state,
        error: null,
        Areas: (action as IChangeArea).payload.Areas,
      };
    }
    case Types.SET_ERROR: {
      return {
        ...state,
        error: (action as IError).payload.error,
      };
    }
    case Types.SET_AREA_SELECTED:
      return {
        ...state,
        selectedArea: (action as ISetArea).payload.Area,
      };
    case Types.SET_NOTE:
      return {
        ...state,
        Notes: (action as ISetNote).payload.note,
      };
    case Types.SET_FIRST_FORM:
      return {
        ...state,
        ...(action as ISetFirstForm).payload.firstForm,
      };
    case Types.SET_SHOW_POPUP:
      return {
        ...state,
        showPopup: (action as ISetFirstForm).payload.showPopup,
      };
    case Types.SET_SITE_INFORMATION:
      return {
        ...state,
        ...(action as ISetSiteInfo).payload.siteInfo,
      };
    case Types.CALCULATE_RATE_SUCCESS:
      return {
        ...state,
        Areas: (action as ICalculateRates).payload.Areas,
        GrandTotal: (action as ICalculateRates).payload.GrandTotal,
        FacilityRating: (action as ICalculateRates).payload.FacilityRating,
      };
    case Types.GET_EDIT_FORM_SUCCESS:
      return {
        ...state,
        ...(action as IGetEditFormSuccess).payload.inspectionForm,
        loading: false,
        FranchiseOwnerName: (action as IGetEditFormSuccess).payload
          .inspectionForm.Owner,
        isTemplate: (action as IGetEditFormSuccess).payload.inspectionForm
          .IsTemplate,
        isCityTemplate: (action as IGetEditFormSuccess).payload.inspectionForm
          .IsCityTemplate,
        isDraft: !(action as IGetEditFormSuccess).payload.inspectionForm
          .IsTemplate,
      };
    case Types.GET_EDIT_FORM_REQUEST:
      return {
        ...state,
        editing: true,
        showPopup: state.showPopup === undefined ? true : false,
        loading: true,
      };
    case Types.GET_PUBLIC_EDIT_FORM_REQUEST:
      return {
        ...state,
        editing: true,
        loading: true,
      };
    case Types.SAVE_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
        successOnSave: null,
        successResult: null,
      };
    case Types.SAVE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        isDraft: (action as ISuccessOnSave).payload.type === 'draft',
        isTemplate:
          (action as ISuccessOnSave).payload.type === 'template' ||
          (action as ISuccessOnSave).payload.type === 'citytemplate',
        isCityTemplate:
          (action as ISuccessOnSave).payload.type === 'citytemplate',
        isSent: (action as ISuccessOnSave).payload.type === 'sent',
        newForm: (action as ISuccessOnSave).payload.newForm,
        showPopup: false,
        successOnSave:
          (action as ISuccessOnSave).payload.type === 'sent' ? true : false,
        successResult: (action as ISuccessOnSave).payload.successResult,
      };
    case Types.SAVE_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        showPopup: false,
        error: (action as IError).payload.error,
      };
    case Types.GET_EDIT_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        error: (action as IError).payload.error,
      };
    case Types.OPEN_SENT_MODAL:
      return {
        ...state,
        loading: false,
        successOnSave: true,
      };
    case Types.VALIDATION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case Types.SET_USER_AND_FRANCHISE_ID:
      return {
        ...state,
        FranchiseId:
          (action as ISetUserAndFranchiseId).payload.franchiseId ||
          state.FranchiseId,
        CreatedBy:
          (action as ISetUserAndFranchiseId).payload.userId || state.CreatedBy,
        userId:
          (action as ISetUserAndFranchiseId).payload.userId || state.userId,
      };
    case Types.SET_RATING_MODAL_STATE:
      return {
        ...state,
        showRatingModal: (action as IControlModalState).payload.status,
      };
    case Types.SET_TEMPLATE_MODAL:
      return {
        ...state,
        showOpenTemplateModal: (action as IControlModalState).payload.status,
      };
    case Types.SET_NOTES_MODAL_STATE:
      return {
        ...state,
        showNotesModal: (action as IControlModalState).payload.status,
      };
    case Types.SET_PHOTOS_MODAL_STATE:
      return {
        ...state,
        showPhotosModal: (action as IControlModalState).payload.status,
      };
    case Types.SET_AREAS_MODAL_STATE:
      return {
        ...state,
        showAddAreasModal: (action as IControlModalState).payload.status,
      };
    case Types.SET_WARNING_MODAL_STATE:
      const { status } = (action as IControlWarningModalState).payload;
      const { message, title, onClose } = (action as IControlWarningModalState)
        .payload;
      return {
        ...state,
        showWarningModal: {
          status,
          message,
          title,
          onClose,
        },
      };
    case Types.SET_ADD_ITEM_MODAL_STATE:
      return {
        ...state,
        showAddItemModal: (action as IControlModalState).payload.status,
      };
    case Types.SET_PDF_MODAL_STATE:
      const pdfStatus = (action as IControlPdfModalState).payload.status;
      const { pdfUrl } = (action as IControlPdfModalState).payload;
      return {
        ...state,
        showPdfModal: {
          status: pdfStatus,
          pdfUrl,
        },
      };
    case Types.SORT_INSPECTIONS:
      const { sortByMostRecent, dateMostRecent, azAscending } = (
        action as ISortingOption
      ).payload;

      // making a shallow copy of the arrays
      const newDraftItems = state.draftItems.slice();
      const newTemplateItems = state.templateItems.slice();
      const newCityTemplateItems = state.cityTemplateItems.slice();
      const newSentItems = state.sentItems.slice();

      if (sortByMostRecent) {
        if (dateMostRecent) {
          // sort in descending order - most recent date at the top
          newDraftItems.sort((a, b) => {
            return +new Date(b.CreatedAt!) - +new Date(a.CreatedAt!);
          });
          newTemplateItems.sort((a, b) => {
            return +new Date(b.CreatedAt!) - +new Date(a.CreatedAt!);
          });
          newCityTemplateItems.sort((a, b) => {
            return +new Date(b.CreatedAt!) - +new Date(a.CreatedAt!);
          });
          newSentItems.sort((a, b) => {
            return +new Date(b.CreatedAt!) - +new Date(a.CreatedAt!);
          });
        } else {
          // sort in ascending order - older date at the top
          newDraftItems.sort((a, b) => {
            return +new Date(a.CreatedAt!) - +new Date(b.CreatedAt!);
          });
          newCityTemplateItems.sort((a, b) => {
            return +new Date(a.CreatedAt!) - +new Date(b.CreatedAt!);
          });
          newSentItems.sort((a, b) => {
            return +new Date(a.CreatedAt!) - +new Date(b.CreatedAt!);
          });
        }
        // const sortedDraft = arraySort(newDraftItems, 'CreatedAt', { reverse: true })
        // const sortedTemplates = arraySort(newTemplateItems, 'CreatedAt', { reverse: true })
        // const sortedSent = arraySort(newSentItems, 'CreatedAt', { reverse: true })
        return {
          ...state,
          draftItems: newDraftItems,
          templateItems: newTemplateItems,
          cityTemplateItems: newCityTemplateItems,
          sentItems: newSentItems,
        };
      } else {
        let sortedDraft, sortedTemplates, sortedSent, sortedCityTemplates;
        if (azAscending) {
          // asceding order A-Z
          sortedDraft = arraySort(newDraftItems, 'PID');
          sortedTemplates = arraySort(newTemplateItems, 'PID');
          sortedCityTemplates = arraySort(newCityTemplateItems, 'PID');
          sortedSent = arraySort(newSentItems, 'PID');
        } else {
          // descending order Z-A
          sortedDraft = arraySort(newDraftItems, 'PID', { reverse: true });
          sortedTemplates = arraySort(newTemplateItems, 'PID', {
            reverse: true,
          });
          sortedCityTemplates = arraySort(newCityTemplateItems, 'PID', {
            reverse: true,
          });
          sortedSent = arraySort(newSentItems, 'PID', { reverse: true });
        }

        return {
          ...state,
          draftItems: sortedDraft,
          templateItems: sortedTemplates,
          cityTemplateItems: sortedCityTemplates,
          sentItems: sortedSent,
        };
      }
    case Types.GET_INSPECTIONS_REQUEST:
      return {
        ...state,
        localLoading: true,
        error: null,
      };
    case Types.GET_INSPECTIONS_SUCCESS:
      return {
        ...state,
        localLoading: false,
        error: null,
        draftItems: (action as IGetInspectionsSuccess).payload.draftItems,
        templateItems: (action as IGetInspectionsSuccess).payload.templateItems,
        cityTemplateItems: (action as IGetInspectionsSuccess).payload
          .cityTemplateItems,
        sentItems: (action as IGetInspectionsSuccess).payload.sentItems,
        totalOfDraftItems: (action as IGetInspectionsSuccess).payload
          .totalDrafts,
        totalOfTemplateItems: (action as IGetInspectionsSuccess).payload
          .totalTemplates,
        totalOfCityTemplateItems: (action as IGetInspectionsSuccess).payload
          .totalCityTemplates,
        totalOfSentItems: (action as IGetInspectionsSuccess).payload.totalSents,
      };
    case Types.GET_INSPECTIONS_FAILURE:
      return {
        ...state,
        localLoading: false,
        error: (action as IError).payload.error,
      };
    case Types.GET_NEXT_INSPECTIONS_PAGE_REQUEST:
      return {
        ...state,
        localLoading: true,
        error: null,
      };
    case Types.GET_NEXT_INSPECTIONS_PAGE_SUCCESS:
      return {
        ...state,
        localLoading: false,
        error: null,
        draftItems: [
          ...state.draftItems,
          ...(action as IGetNextInspectionPageSuccess).payload.draftItems,
        ],
        templateItems: [
          ...state.templateItems,
          ...(action as IGetNextInspectionPageSuccess).payload.templateItems,
        ],
        cityTemplateItems: [
          ...state.cityTemplateItems,
          ...(action as IGetNextInspectionPageSuccess).payload
            .cityTemplateItems,
        ],
        sentItems: [
          ...state.sentItems,
          ...(action as IGetNextInspectionPageSuccess).payload.sentItems,
        ],
      };
    case Types.ARCHIVE_FORM_REQUEST:
      return {
        ...state,
        localLoading: true,
        error: null,
        successOnArchive: null,
      };
    case Types.ARCHIVE_FORM_SUCCESS:
      return {
        ...state,
        localLoading: false,
        error: null,
        successOnArchive: true,
      };
    case Types.ARCHIVE_FORM_FAILURE:
      return {
        ...state,
        localLoading: false,
        error: (action as IError).payload.error,
        successOnArchive: false,
      };
    case Types.DUPLICATE:
      return {
        ...state,
        ...(action as IOpenForm).payload.form,
        loading: false,
        error: null,
        editing: true,
        isDraft: false,
        isTemplate: false,
        isCityTemplate: false,
        isSent: false,
        MasterCity: (action as IOpenForm).payload.form.FranchiseId,
        FranchiseOwnerName: (action as IOpenForm).payload.form.Owner,
        transformedToDraft: false,
      };
    case Types.OPEN_FORM:
      return {
        ...state,
        loading: false,
        error: null,
        ...(action as IOpenForm).payload.form,
        editing: true,
        showPopup: false,
        newForm: false,
        MasterCity: (action as IOpenForm).payload.form.FranchiseId,
        FranchiseOwnerName: (action as IOpenForm).payload.form.Owner,
      };
    case Types.OPEN_DRAFT:
      return {
        ...state,
        loading: false,
        error: null,
        ...(action as IOpenForm).payload.form,
        editing: true,
        showPopup: false,
        isDraft: true,
        isTemplate: false,
        isCityTemplate: false,
        isSent: false,
        newForm: false,
        MasterCity: (action as IOpenForm).payload.form.FranchiseId,
        FranchiseOwnerName: (action as IOpenForm).payload.form.Owner,
        transformedToDraft: false,
      };
    case Types.TRANSFORM_INTO_DRAFT:
      return {
        ...state,
        loading: false,
        error: null,
        editing: true,
        isDraft: true,
        isTemplate: false,
        isCityTemplate: false,
        transformedToDraft: true,
      };
    case Types.REVERT_TRANSFORMED_TO_DRAFT_STATE:
      return {
        ...state,
        transformedToDraft: false,
      };
    case Types.SET_INSPECTION_REPORT_ID:
      // const { id } = action.payload

      return {
        ...state,
        InspectionReportId: action.payload.id,
      };
    case Types.OPEN_TEMPLATE:
      return {
        ...state,
        loading: false,
        error: null,
        editing: true,
        showPopup: true,
        isDraft: false,
        ...(action as IOpenForm).payload.form,
        isCityTemplate: false,
        isTemplate: true,
        isSent: false,
        newForm: false,
        MasterCity: (action as IOpenForm).payload.form.FranchiseId,
        FranchiseOwnerName: (action as IOpenForm).payload.form.Owner,
        transformedToDraft: false,
      };
    case Types.OPEN_CITY_TEMPLATE:
      return {
        ...state,
        loading: false,
        error: null,
        editing: true,
        showPopup: true,
        isDraft: false,
        ...(action as IOpenForm).payload.form,
        isTemplate: true,
        isCityTemplate: true,
        isSent: false,
        newForm: false,
        MasterCity: (action as IOpenForm).payload.form.FranchiseId,
        FranchiseOwnerName: (action as IOpenForm).payload.form.Owner,
        transformedToDraft: false,
      };
    case Types.OPEN_SENT:
      return {
        ...state,
        loading: false,
        error: null,
        editing: true,
        showPopup: false,
        isDraft: false,
        isTemplate: false,
        isSent: true,
        newForm: false,
        MasterCity: (action as IOpenForm).payload.form.FranchiseId,
        ...(action as IOpenForm).payload.form,
        FranchiseOwnerName: (action as IOpenForm).payload.form.Owner,
        transformedToDraft: false,
      };
    case Types.ADD_PRE_DEF_AREA:
      return {
        ...state,
        Areas: [...state.Areas, (action as IAddPreDefinedArea).payload.area],
      };
    case Types.SET_IS_SENT:
      return {
        ...state,
        isSent: true,
      };
    case Types.SET_IS_NOT_SENT:
      return {
        ...state,
        isSent: false,
      };
    case Types.SET_IS_TEMPLATE:
      return {
        ...state,
        isTemplate: action.payload.isTemplate,
        isDraft: action.payload.isTemplate ? false : state.isDraft,
      };
    case Types.SET_IS_CITY_TEMPLATE:
      return {
        ...state,
        isTemplate: action.payload.isCityTemplate ? true : state.isTemplate,
        isCityTemplate: action.payload.isCityTemplate,
        isDraft: action.payload.isCityTemplate ? false : state.isDraft,
      };
    case Types.OPEN_EXIT_MODAL:
      return {
        ...state,
        showExitModal: true,
      };
    case Types.CLOSE_EXIT_MODAL:
      return {
        ...state,
        showExitModal: false,
      };

    case Types.SET_FORM_ERRORS:
      return {
        ...state,
        errors: action.payload.errors,
      };
    case Types.SET_NEEDS_SAVING:
      return {
        ...state,
        needsSaving: action.payload.needsSaving,
      };
    case Types.SET_FORM_CLICKED:
      const { id, type } = (action as ISetFormClicked).payload;
      return {
        ...state,
        showPopup: false,
        formClickedOnMenu: {
          id,
          type,
        },
      };
    case Types.SET_INSPECTED_BY:
      return {
        ...state,
        InspectedByInStore: (action as IInspectedBy).payload.inspectedBy,
        CreatedBy: (action as IInspectedBy).payload.userIDFromApi,
      };
    case Types.SET_LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
