import React, { memo, useState } from 'react';
import { withFormik, FormikProps } from 'formik';
import { IBidSupplies } from 'modules/BidInfoForm/interfaces';
import {
  suppliesValues,
  suppliesOptions,
} from 'modules/shared/bidDefaultValues';

import {
  ContentInput,
  BoxInput,
  BoxInputWithValidation,
  useFormProps,
} from 'core/components';
import { Select } from 'core/components/SelectV2';
import { moneyMask, moneyMaskParams } from 'core/utils/masks';
import { convertStringToNumber, formatPrice } from 'core/utils';

import { useMediaQuery } from 'react-responsive';

import './styles.scss';

type IProps = FormikProps<IBidSupplies>;
const Supplies = memo(({ backgroundColor, isSpecialty, sectionId }: IProps) => {
  const { onChange, setFormValues, formValues } = useFormProps();
  const [disabledCost, setDisabledCost] = useState(true);
  const biggerThan768 = window.innerWidth >= 769;

  const onSelect = (name: string, value: any) => {
    const allValues = { ...formValues, [name]: value };
    const enableValues = ['Included in Price'];
    const enableCost =
      enableValues.includes(allValues.suppliesLiners) ||
      enableValues.includes(allValues.suppliesRestroom);
    setDisabledCost(!enableCost);

    let cost = 0;
    if (formValues.costSupplies !== '' && enableCost) {
      cost = formValues.costSupplies;
    }
    onChange(name, value);
    onChange('costSupplies', cost);
  };

  React.useEffect(() => {
    if (formValues.pid === '') {
      return;
    }
    if (formValues.suppliesRestroom) {
      onSelect('suppliesRestroom', formValues.suppliesRestroom);
    }

    if (formValues.suppliesLiners) {
      onSelect('suppliesLiners', formValues.suppliesLiners);
    }
  }, [formValues.pid]);

  return (
    <div className="supplies-root" style={{ background: backgroundColor }}>
      <div className="supplies-content">
        {isSpecialty && (
          <ContentInput width="100%" justify="flex-start">
            <h3 className="supplies-title">Supplies</h3>
          </ContentInput>
        )}
        <div
          style={{
            paddingLeft: '5px',
            paddingRight: '5px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '32%' }}>
            Liners:
            <Select
              name="suppliesLiners"
              value={formValues.suppliesLiners}
              items={suppliesOptions}
              onChange={onSelect}
              disabled={formValues.status === 2}
              canTypeCustomValue={false}
              blockCustomInput={true}
            />
          </div>

          <div style={{ width: '32%' }}>
            Restroom:
            <Select
              name="suppliesRestroom"
              value={formValues.suppliesRestroom}
              items={suppliesOptions}
              onChange={onSelect}
              disabled={formValues.status === 2}
              canTypeCustomValue={false}
              blockCustomInput={true}
            />
          </div>

          <div className="supplies-cost" style={{ width: '32%' }}>
            Cost of Supplies:
            <BoxInputWithValidation
              sectionId={sectionId}
              mask={moneyMask}
              maskParams={{
                ...moneyMaskParams,
                thousandsSeparatorSymbol: true,
              }}
              onBlur={(name, value) => {
                onChange(name, value);
              }}
              // inputStyle={boxInputWithValidationStyles}
              inputStyle={{ flex: 1 }}
              value={formValues.costSupplies}
              name="costSupplies"
              onChange={(name, value) => {
                onChange(name, value);
              }}
              fieldName="Cost of Supplies"
              width="80px"
              disabled={disabledCost || formValues.status === 2}
              // color={disabledCost ? '#888' : null}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

const mapForm = {
  mapPropsToValues: () => ({
    ...suppliesValues,
  }),
  handleSubmit: () => '',
};

const EnhancedSupplies = withFormik<any, IBidSupplies>(mapForm)(Supplies);

export { EnhancedSupplies as Supplies };
