import { default as Page1 } from './Page1';
import { default as Page2 } from './Page2';
import { default as Page3 } from './Page3';
import { default as Page4 } from './Page4';
import { default as Page5 } from './Page5';
import { Template } from '../../../components/templates';
import React, { useEffect, useState } from 'react';
import { ModalBody, LightStaticText } from '../../../styles';
import { ActionAlertModal, Button } from 'core/components';
import {
  SignNowLoadingPage,
  LoadingPage,
  BlockingOverlay,
} from '../../../components/organisms';
import {
  checkIsGeneratingPDF,
  getBlankPdfUrl,
  verifyServerUnavailable,
} from '../../../services/bidInfoServiceClient';
import { isWebView } from 'core/utils';
import * as Sentry from '@sentry/react';
import {
  postBidInfo,
  getBidInfoById,
  setInvalidDocument,
  getSignNowStatus,
  isAlive,
} from '../../../services/bidInfoServiceClient';
import { parseGetToPost } from '../../../parser';
import {
  validateEmail,
  validatePostalCode,
  validatePhone,
} from 'core/validators/utils';
import { IWarningModal } from '../../../../shared/bidInterfaces';
import BlockingPopupModal from '../../BlockingPopupModal';
import {
  getBase64ByUrl,
  getDirectPdfDownloadUrl,
} from '../../../services/bidInfoService';
import { HiddenAnchor } from '../../../components/atoms';

const validatePage1Fields = (fields: any) => {
  const ret = [];

  if (fields.selectedFrequency === null && !fields.hasProtectionPackages) {
    ret.push('MONTHLY SERVICE OPTION');
  }

  // if (fields.isTermsChecked === false) {
  //   ret.push('TERMS AND CONDITIONS');
  // }

  return { required: ret, incorrect: [] };
};

const validatePage2Fields = (fields: any, isSpecialty: boolean = false) => {
  const ret = [];

  if (fields.selectedFrequency === null && fields.shouldSelectOption) {
    ret.push('MONTHLY SERVICE OPTION');
  }

  if (
    fields.selectedFrequency !== null &&
    fields.hasProtectionPackages &&
    fields.selectedProtection === null
  ) {
    ret.push('PROTECTION PACKAGE OPTION');
  }

  return { required: ret, incorrect: [] };
};

const validatePage3Fields = (fields: any, isSpecialty: boolean) => {
  const ret = [];

  if (isSpecialty) {
    if (!fields.additionalQuotes || fields.additionalQuotes.length == 0) {
      ret.push('SPECIALTY SERVICE OPTION');
    }
  }
  return { required: ret, incorrect: [] };
};

const validatePage4Fields = (
  fields: any,
  selectedFrequency,
  isSpecialty: boolean
) => {
  const ret = [];
  const incomp = [];
  const fieldsPresents = Object.keys(fields);
  const fieldsNames = {
    billingAddress1: 'BILLING ADDRESS 1',
    billingCity: 'BILLING CITY',
    billingState: 'BILLING STATE',
    billingPostalCode: 'BILLING POSTAL CODE',
    billingEmail: 'BILLING EMAIL',
    accountsName: 'ACCOUNT NAME',
    accountsPhone: 'ACCOUNT PHONE',
    accountsEmail: 'ACCOUNT EMAIL',
    emergencyName: 'EMERGENCY NAME',
    emergencyPhone: 'EMERGENCY PHONE',
    taxFacilityExempt: 'TAX FACILITY EXEMPT',
    taxExemptionNumber: 'TAX EXEMPTION NUMBER',
  };
  if (!isSpecialty && selectedFrequency) {
    fieldsNames.serviceCommencementDate = 'SERVICE COMMENCEMENT DATE';
  }

  Object.keys(fieldsNames).map((key) => {
    if (
      !fieldsPresents.includes(key) ||
      (typeof fields[key] !== 'boolean' && !fields[key])
    ) {
      if (key == 'taxExemptionNumber') {
        if (fields['taxFacilityExempt']) {
          ret.push(fieldsNames[key]);
          var el = document.querySelector(`[data-cy=${key}]`);
          if (el) {
            el.parentNode.classList.add('is-invalid');
          }
        } else {
          var el = document.querySelector(`[data-cy=${key}]`);
          if (el) {
            el.parentNode.classList.remove('is-invalid');
          }
        }
      } else {
        ret.push(fieldsNames[key]);
        var el = document.querySelector(`[data-cy=${key}]`);
        if (el) {
          el.parentNode.classList.add('is-invalid');
        }
      }
    } else {
      var el = document.querySelector(`[data-cy=${key}]`);
      if (el) {
        el.parentNode.classList.remove('is-invalid');
      }
    }
  });

  var el = document.getElementsByClassName('validate-billing-email')[0];
  if (!validateEmail(fields['billingEmail'])) {
    incomp.push('BILLING EMAIL');
    if (el) {
      el.classList.add('is-invalid');
    }
  } else {
    if (el && el.classList.contains('is-invalid')) {
      el.classList.remove('is-invalid');
    }
  }

  var el = document.getElementsByClassName('validate-accounts-email')[0];
  if (!validateEmail(fields['accountsEmail'])) {
    incomp.push('ACCOUNTS EMAIL');
    if (el) {
      el.classList.add('is-invalid');
    }
  } else {
    if (el && el.classList.contains('is-invalid')) {
      el.classList.remove('is-invalid');
    }
  }

  var el = document.getElementsByClassName('validate-accounts-phone')[0];
  if (!validatePhone(fields['accountsPhone'])) {
    incomp.push('ACCOUNTS PHONE');
    if (el) {
      el.classList.add('is-invalid');
    }
  } else {
    if (el.classList.contains('is-invalid')) {
      el.classList.remove('is-invalid');
    }
  }

  var el = document.getElementsByClassName('validate-emergency-phone')[0];
  if (!validatePhone(fields['emergencyPhone'])) {
    incomp.push('EMERGENCY PHONE');
    if (el) {
      el.classList.add('is-invalid');
    }
  } else {
    if (el && el.classList.contains('is-invalid')) {
      el.classList.remove('is-invalid');
    }
  }

  var el = document.getElementsByClassName('validate-postal-code')[0];
  if (!validatePostalCode(fields['billingPostalCode'])) {
    incomp.push('POSTAL CODE');
    if (el) {
      el.classList.add('is-invalid');
    }
  } else {
    if (el && el.classList.contains('is-invalid')) {
      el.classList.remove('is-invalid');
    }
  }

  return { required: ret, incorrect: incomp };
};

const validatePage5Fields = (fields: any, isSpecialty: boolean) => {
  const ret = [];
  const fieldsPresents = Object.keys(fields);
  const fieldsNames = {
    name: 'NAME ON BANK ACCOUNT',
    bankName: 'BANK NAME',
    accountType: 'ACCOUNT TYPE',
    routing: 'ROUTING',
    account: 'ACCOUNT',
    confirmAccount: 'CONFIRM ACCOUNT',
    billingAddress: 'BILLING ADDRESS',
  };

  if (!isSpecialty) {
    fieldsNames.dateOption = 'DATE OPTION';
  }

  if (fields['discount']) {
    Object.keys(fieldsNames).map((key) => {
      if (
        !fieldsPresents.includes(key) ||
        (typeof fields[key] !== 'boolean' && !fields[key])
      ) {
        ret.push(fieldsNames[key]);
        var el = document.querySelector(`[data-cy=${key}]`);
        if (el) {
          el.parentNode.classList.add('is-invalid');
        }
      } else {
        var el = document.querySelector(`[data-cy=${key}]`);
        if (el) {
          el.parentNode.classList.remove('is-invalid');
        }
      }
    });
  }
  return { required: ret };
};

const scrollToTop = () => {
  window.scroll({
    top: 0,
    left: 0,
  });
  document.getElementById('root').scroll({
    top: 0,
    left: 0,
  });
};

// Create state handlers for page 4
export default (props) => {
  const [selectedItem, setSelectedItem] = React.useState(0);
  const [showWarning, setShowWarning] = React.useState(false);
  const [showConfirmAccountWarning, setShowConfirmAccountWarning] =
    React.useState(false);
  const [alertModal, setAlertModal] = React.useState({});
  const [signNowAlertModal, setSignNowAlertModal] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [clicksToSign, setClicksToSign] = React.useState(false);

  const [pdfUrl, setPdfUrl] = React.useState();

  const [values, setValues] = React.useState({
    page1: {},
    page2: {},
    page3: {},
    page4: {},
    page5: {},
  });

  const clickToSign = async () => {
    setClicksToSign(false);

    try {
      await isAlive();
    } catch (e) {
      setIsLoading(false);
      setAlertModal({
        message:
          "It looks like you don't have internet at the moment, please ensure you have internet access and try again.",
        proceed: () => {
          setClicksToSign(true);
          setAlertModal({});
        },
      });
      Sentry.captureException(e);
      return;
    }

    setIsLoading(true);
    const newValues = {
      page1: page1Ref.current ? page1Ref.current.getData() : {},
      page2: page2Ref.current ? page2Ref.current.getData() : {},
      page3: page3Ref.current ? page3Ref.current.getData() : {},
      page4: page4Ref.current ? page4Ref.current.getData() : {},
      page5: page5Ref.current ? page5Ref.current.getData() : {},
    };

    if (!validatePage5(newValues.page5)) {
      setIsLoading(false);
      return;
    }

    const values = props.data;

    values['GenerateSignDoc'] = true;

    values['SelectedFrequency'] =
      newValues.page1.selectedFrequency || newValues.page2.selectedFrequency;

    if (
      values['SelectedFrequency'] == null &&
      newValues.page1.selectedFrequency != null
    ) {
      values['SelectedFrequency'] = newValues.page1.selectedFrequency;
    }

    values['SelectedFrequency'] =
      values['SelectedFrequency'] == -1 ? null : values['SelectedFrequency'];

    values['SecondFrequencyPriceSelected'] = values['SelectedFrequency'];

    const page3Values = newValues.page3.additionalQuotes;
    values['AdditionalQuotes'].forEach((element) => {
      if (page3Values.includes(element['BidInformationAdditionalQuotesId'])) {
        element['IsSelected'] = true;
      } else {
        element['IsSelected'] = false;
      }
    });
    values['DayPorterSelected'] = newValues.page3.dayPorter;

    if (newValues.page2.hasProtectionPackages) {
      if (newValues.page2.selectedProtection >= 0) {
        values['BidProtectionQuotesPerFrequency'].filter(
          (i) => i['KeyNumber'] == newValues.page2.selectedProtection
        )[0]['Selected'] = true;
      } else {
        values['BidProtectionQuotesPerFrequency'].map(
          (i) => (i['Selected'] = null)
        );
      }
    } else {
      values['BidProtectionQuotesPerFrequency'].map(
        (i) => (i['Selected'] = null)
      );
    }

    const page4Values = newValues.page4;

    values['ReviewServiceCommencementDate'] =
      page4Values.serviceCommencementDate;
    values['ReviewEmergencyContactPhone'] = page4Values.emergencyPhone;
    values['ReviewEmergencyContactName'] = page4Values.emergencyName;

    values['ReviewBillingAddress'] = page4Values.billingAddress1;
    values['ReviewBillingAddress2'] = page4Values.billingSuite;
    values['ReviewBillingCity'] = page4Values.billingCity;
    values['ReviewBillingState'] = page4Values.billingState;
    values['ReviewBillingPostalCode'] = page4Values.billingPostalCode;
    values['ReviewPayableContactName'] = page4Values.accountsName;
    values['ReviewPayableContactPhone'] = page4Values.accountsPhone;
    values['ReviewPayableContactEmail'] = page4Values.accountsEmail;
    values['TaxExemptionNumber'] = page4Values.taxExemptionNumber;

    if (newValues.page5.discount) {
      values['BidSensitiveData'] = {
        ['DebitDay']: newValues.page5.dateOption,
        ['NameBankAccount']: newValues.page5.name,
        ['BankName']: newValues.page5.bankName,
        ['AccType']: newValues.page5.accountType,
        ['Routing']: newValues.page5.routing,
        ['Account']: newValues.page5.account,
        ['BillingAddress']: newValues.page5.billingAddress,
      };
    }

    values['ProposalHolidays'] = values['TermsAndConditions']['HolidaySection'];
    values['ProposalSection3'] =
      values['TermsAndConditions']['Section3Section'];
    values['ProposalSection7'] =
      values['TermsAndConditions']['Section7Section'];
    values['ProposalSection8'] =
      values['TermsAndConditions']['Section8Section'];

    delete values['TermsAndConditions'];

    if (page1Ref.current) {
      page1Ref.current.clearIsDirty();
    }
    if (page2Ref.current) {
      page2Ref.current.clearIsDirty();
    }
    if (page3Ref.current) {
      page3Ref.current.clearIsDirty();
    }

    setClicksToSign(true);
    postBidInfo(values).then((result) => {
      if (result.error) {
        setIsLoading(false);
        setAlertModal({
          message: result.error as string,
          proceed: () => {
            setAlertModal({});
            setClicksToSign(true);
          },
        });
        return false;
      }

      const url = result.data['URL'];
      window.location.href = url;
    });
  };

  const page1Ref = React.useRef();
  const page2Ref = React.useRef();
  const page3Ref = React.useRef();
  const page4Ref = React.useRef();
  const page5Ref = React.useRef();

  const { data, franchiseData, parsedData } = props;

  const bidHasBeenSigned = () =>
    page5Ref.current && page5Ref.current.getData().signed;

  useEffect(() => {
    if (data && franchiseData) {
      if (data['HasAlreadyGenerateDocForThisDocument']) {
        getSignNowStatus(data['BidUniqueID'])
          .then(({ data: result }) => {
            if (result['Status'] == 'true') {
              window.location.reload();
            } else {
              setSignNowAlertModal({
                htmlMessage:
                  'Looks like some selections have already been made on this Proposal.<br /><br />Would you like to Continue where you left off or Start Over?',
                dismissText: 'Continue',
                confirmText: 'Start Over',
                isDismissPrimary: true,
                onConfirm: () =>
                  setInvalidDocument(data['BidUniqueID']).then((result) => {
                    setShowWarning(false);
                    setSignNowAlertModal({});
                  }),
                onDismiss: () => {
                  window.location.href = data['AlreadyGeneratedLinkSignNow'];
                },
                isVisible: true,
              });
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }
    }
  }, [data, franchiseData]);

  window.onbeforeunload = function () {
    if (
      (page1Ref.current && page1Ref.current.getData().isDirty) ||
      (page2Ref.current && page2Ref.current.getData().isDirty) ||
      (page3Ref.current && page3Ref.current.getData().isDirty) ||
      (page4Ref.current && page4Ref.current.getData().isDirty) ||
      (page5Ref.current && page5Ref.current.getData().isDirty)
    ) {
      return '';
    }
  };

  const validatePage5 = (values) => {
    if (typeof values.discount !== 'boolean' && values.ownerHasEcoInvoice) {
      setAlertModal({
        message:
          // 'Please select a Monthly Service Pricing option before complete.',
          'Please select if you would like to save $5 on your monthly recurring service, with our Eco-Invoice™ option.',
        proceed: () => {
          setAlertModal({});
        },
      });
      return false;
    }

    const errors = validatePage5Fields(values, data['IsSS']);

    if (errors.required && errors.required.length > 0) {
      setAlertModal({
        message: errors,
        proceed: () => setAlertModal({}),
      });
      return false;
    }

    const { account, confirmAccount } = values;

    if (account !== confirmAccount) {
      setAlertModal({
        message: 'Account and Confirm Account fields must have the same value',
        proceed: () => {
          setAlertModal({});
          document.getElementById('confirmAccount').scrollIntoView({
            block: 'center',
            inline: 'center',
          });
          document.getElementById('confirmAccount').focus();
        },
      });

      if (data['IsSS']) {
        setSelectedItem(3);
      } else {
        setSelectedItem(4);
      }
      return false;
    }

    return true;
  };

  const handleShowWarning = (item) => {
    // Do the same for page4
    const page4HasData =
      page4Ref &&
      page4Ref.current &&
      page4Ref.current.getData().billingAddress1;

    if (
      (selectedItem === 3 || selectedItem === 4) &&
      (item === 1 || item === 2) &&
      page4HasData
    ) {
      setShowWarning(true);
    } else {
      setSelectedItem(item);
      scrollToTop();
    }
  };

  const handleExemption = () => {
    const isTaxFacilityExcemptChecked = page4Ref.current
      ? page4Ref.current.getData().taxFacilityExempt
      : false;
    const taxExemptionNumber = page4Ref.current
      ? page4Ref.current.getData().taxExemptionNumber
      : null;
    const shouldVerifyTaxFacilityExcempt = (() => {
      if (isTaxFacilityExcemptChecked) {
        if (selectedItem === 2 && !taxExemptionNumber) {
          return true;
        }
      }
      return false;
    })();

    if (shouldVerifyTaxFacilityExcempt) {
      setAlertModal({
        message: 'Please, provide an exemption number',
        proceed: () => {
          setAlertModal({});
          document.getElementById('taxExemptionNumber').scrollIntoView({
            block: 'center',
            inline: 'center',
          });
          document.getElementById('taxExemptionNumber').focus();
        },
      });

      setSelectedItem(2);
    }
  };

  const handleConfirmAccountField = () => {};

  const onSelectItem = (item: number, data: any) => {
    const newValues = {
      page1: page1Ref.current.getData(),
      page2: page2Ref.current ? page2Ref.current.getData() : {},
      page3: page3Ref.current ? page3Ref.current.getData() : {},
      page4: page4Ref.current ? page4Ref.current.getData() : {},
      page5: page5Ref.current.getData(),
    };

    setValues(newValues);

    if (
      (!data['IsSS'] && selectedItem == 4 && item != 3) ||
      (data['IsSS'] && selectedItem == 3 && item != 2)
    ) {
      if (!validatePage5(newValues.page5)) {
        return;
      }
    }

    if (
      (!data['IsSS'] && selectedItem == 3 && item == 4) ||
      (data['IsSS'] && selectedItem == 2 && item == 3)
    ) {
      const errors = validatePage4Fields(
        newValues.page4,
        newValues.page1.selectedFrequency != -1 &&
          newValues.page2.selectedFrequency != -1,
        data['IsSS']
      );
      const hasErrors =
        errors.required.length > 0 || errors.incorrect.length > 0;

      if (hasErrors) {
        setAlertModal({
          message: errors,
          proceed: () => setAlertModal({}),
        });
        return;
      }
    }

    if (selectedItem == 1 && item == 2 && data['IsSS']) {
      const errors = validatePage3Fields(newValues.page3, data['IsSS']);
      const hasErrors =
        errors.required.length > 0 || errors.incorrect.length > 0;

      if (hasErrors) {
        setAlertModal({
          message: errors,
          proceed: () => setAlertModal({}),
        });
        return;
      }
    }

    if (selectedItem == 1 && item == 2 && !data['IsSS']) {
      const errors = validatePage2Fields(newValues.page2);
      if (
        errors &&
        (errors.required.length > 0 || errors.incorrect.length > 0)
      ) {
        setAlertModal({
          message: errors,
          proceed: () => setAlertModal({}),
        });
        return;
      }
    }

    if (selectedItem == 0 && !data['IsSS']) {
      const proceed = () => {
        const errors = validatePage1Fields(newValues.page1);
        if (
          errors &&
          (errors.required.length > 0 || errors.incorrect.length > 0)
        ) {
          setAlertModal({
            message: errors,
            proceed: () => setAlertModal({}),
          });
          return;
        }

        if (bidHasBeenSigned() && selectedItem > 1) {
          setSelectedItem(0);
          return;
        }

        handleShowWarning(item);
        handleExemption();
        scrollToTop();
      };

      const getDaysPorterDays = () => {
        let ret = '';
        if (data['DaysPotterMonday']) {
          ret += 'Monday, ';
        }
        if (data['DaysPotterTuesday']) {
          ret += 'Tuesday, ';
        }
        if (data['DaysPotterWednesday']) {
          ret += 'Wednesday, ';
        }
        if (data['DaysPotterThursday']) {
          ret += 'Thursday, ';
        }
        if (data['DaysPotterFriday']) {
          ret += 'Friday, ';
        }
        if (data['DaysPotterSaturday']) {
          ret += 'Saturday, ';
        }
        if (data['DaysPotterSunday']) {
          ret += 'Sunday, ';
        }
        return ret.substr(0, ret.length - 2);
      };

      const hasDayPorter =
        data['IncludeDayPorterInPrice'] &&
        (data['CostOfDayPorter'] > 0 || data['DayPorterPriceOverride'] > 0);
      const hasSupplies = data['CostOfSupplies'] && data['CostOfSupplies'] > 0;

      if (
        newValues.page1.selectedFrequency == -1 &&
        (hasDayPorter || hasSupplies)
      ) {
        setAlertModal({
          message: (
            <>
              By declining the montly service you will also be declining the
              following Included services:
              <br />
              <br />
              <br />
              {hasDayPorter
                ? `Day Porter Services - ${getDaysPorterDays()} for ${
                    data['DayPorterHoursPerDay']
                  } hours per day`
                : ''}
              {hasDayPorter && <br />}
              {hasSupplies ? `Supplies Included` : ''}
            </>
          ),
          proceed: () => {
            setAlertModal({});
            proceed();
          },
          dismiss: () => {
            if (newValues.page1.clearSelection) {
              newValues.page1.clearSelection();
            }
            setAlertModal({});
          },
          confirmText: 'YES',
          dismissText: 'NO',
        });
        return;
      } else {
        proceed();
        return;
      }
    }

    if (bidHasBeenSigned() && selectedItem > 1) {
      setSelectedItem(0);
      return;
    }

    handleShowWarning(item);
    handleExemption();
    scrollToTop();
  };

  const onModalLeaveClick = () => {
    setSelectedItem(selectedItem - 1);
    setShowWarning(false);
    setAlertModal({});
    scrollToTop();

    page4Ref.current.reset();
    page5Ref.current.reset();
  };

  const onModalStayClick = () => {
    setShowWarning(false);
    setAlertModal({});
  };

  const onClickDownloadBlankPdf = async () => {
    try {
      setIsLoading(true);

      const blankPdfResult = await checkIsGeneratingPDF(data['BidUniqueID']);
      const generating = blankPdfResult.data['isGenerating'];
      if (generating) {
        setAlertModal({
          message:
            'Previous PDF Version still being generated. Please try again.',
          proceed: () => {
            setAlertModal({});
          },
        });
        setIsLoading(false);
        return;
      }

      const { data: pdfUrl } = await getBlankPdfUrl(data['BidUniqueID']);

      setPdfUrl(pdfUrl);

      setTimeout(() => {
        document.getElementById('hiddenAnchor').click();
        setTimeout(() => {
          setIsLoading(false);
        }, 700);
      }, 0);
    } catch (e) {
      const generating = e.data['isGenerating'];
      if (generating) {
        setAlertModal({
          message:
            'Previous PDF Version still being generated. Please try again.',
          proceed: () => {
            setAlertModal({});
          },
        });
        setIsLoading(false);
        return;
      }
    }
  };

  const getItems = (data, franchiseData, parsedData) => {
    const items = [
      <Page1
        key="page1"
        ref={page1Ref}
        data={data}
        parsedData={parsedData}
        franchiseData={franchiseData}
        selectedValues={values}
        selectedItem={selectedItem}
        downloadBlankPdf={onClickDownloadBlankPdf}
      />,
    ];

    if (parsedData && !parsedData.isSpecialty) {
      items.push(
        <Page2
          key="page2"
          ref={page2Ref}
          data={data}
          parsedData={parsedData}
          selectedItem={selectedItem}
          franchiseData={franchiseData}
          selectedValues={values}
          downloadBlankPdf={onClickDownloadBlankPdf}
        />
      );
    }

    items.push(
      <Page3
        key="page3"
        ref={page3Ref}
        data={data}
        parsedData={parsedData}
        selectedItem={selectedItem}
        franchiseData={franchiseData}
        selectedValues={values}
        setAlertModal={setAlertModal}
        downloadBlankPdf={onClickDownloadBlankPdf}
      />
    );

    if (!bidHasBeenSigned()) {
      if (data && !data['ExistentClient']) {
        items.push(
          <Page4
            key="page4"
            ref={page4Ref}
            data={data}
            parsedData={parsedData}
            selectedItem={selectedItem}
            franchiseData={franchiseData}
            selectedValues={values}
            clickToSign={clickToSign}
            setAlertModal={setAlertModal}
            downloadBlankPdf={onClickDownloadBlankPdf}
          />
        );
      }
      items.push(
        <Page5
          key="page5"
          ref={page5Ref}
          data={data}
          parsedData={parsedData}
          selectedItem={selectedItem}
          franchiseData={franchiseData}
          selectedValues={values}
          clickToSign={clickToSign}
          downloadBlankPdf={onClickDownloadBlankPdf}
        />
      );
    }

    return items;
  };

  const renderModal = () => {
    let props = {};

    if (showWarning) {
      props = {
        renderMessage: () => (
          <ModalBody>
            <strong>Warning:</strong> Leaving this page will cause all
            information entered to be deleted
          </ModalBody>
        ),
        renderDismissButton: () => (
          <Button
            reverseSkew
            padding="0 25px"
            style={{ width: '175px', fontSize: '18px' }}
            margin="0 15px 0 0"
            bgColor="#F7A11D"
            onClick={onModalLeaveClick}
            color="#fff"
          >
            LEAVE PAGE
          </Button>
        ),
        onDismiss: onModalLeaveClick,
        renderConfirmButton: () => (
          <Button
            reverseSkew
            padding="0 25px"
            style={{ width: '175px', fontSize: '18px' }}
            onClick={onModalStayClick}
            color="#fff"
            bgColor="#216eab"
          >
            STAY ON PAGE
          </Button>
        ),
        onConfirm: onModalStayClick,
        isVisible: showWarning,
      };

      return <ActionAlertModal {...props} />;
    }

    if (Object.keys(alertModal).length) {
      props = {
        message: alertModal.message,
        onConfirm: alertModal.proceed,
        onDismiss: alertModal.dismiss,
        confirmText: alertModal.confirmText,
        dismissText: alertModal.dismissText,
        isVisible: true,
      };
    }

    if (Object.keys(signNowAlertModal).length) {
      props = signNowAlertModal;
    }

    return <ActionAlertModal {...props} />;
  };

  const [blockingModal, setBlockingModal] = useState(null);

  React.useEffect(() => {
    if (data) {
      (async () => {
        try {
          const result = await verifyServerUnavailable(data['CreatedBy']);
          if (!result || result.error) {
            setBlockingModal({
              message:
                'Server under maintenance at this time, please try again later',
            });
          } else {
            setBlockingModal(null);
          }
        } catch (e) {
          if (e.response.status == '503') {
            setBlockingModal({
              message:
                'Server under maintenance at this time, please try again later',
            });
          }
        }
      })();
    }
  }, [data]);

  return (
    <>
      {isLoading && !clicksToSign && <LoadingPage />}
      {isLoading && clicksToSign && <SignNowLoadingPage />}
      {renderModal()}
      {blockingModal && (
        <BlockingOverlay>
          <BlockingPopupModal message={blockingModal.message} />
        </BlockingOverlay>
      )}
      <Template
        showHeaderAddress={selectedItem >= 2}
        selectedItem={selectedItem}
        onSelectItem={(item) => onSelectItem(item, data)}
        data={data}
        franchiseData={franchiseData}
        downloadBlankPdf={onClickDownloadBlankPdf}
      >
        {getItems(data, franchiseData, parsedData)}
      </Template>
      {parsedData && (
        <HiddenAnchor
          href={getDirectPdfDownloadUrl(
            pdfUrl,
            `${
              parsedData.isSpecialty ? 'SpSvcProposal' : 'AnagoProposal'
            }_${parsedData.pid.replace(/\&/, ' ')}`
          )}
          download={`${
            parsedData.isSpecialty ? 'SpSvcProposal' : 'AnagoProposal'
          }_${parsedData.pid.replace(/\&/, ' ')}`}
          id="hiddenAnchor"
          target="_blank"
          title="download"
        />
      )}
    </>
  );
};
