import React, { memo, useState, useEffect, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withFormik, FormikProps } from 'formik';

import { useFormProps } from 'core/components';
import { getQueryString, axiosInstance } from 'core/utils';
import { IBidFormHeader } from 'modules/BidInfoForm/interfaces';
import { headerValues } from 'modules/shared/bidDefaultValues';
import { Select } from './Select';
import { isWebView } from 'core/utils';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import {
  DatePicker,
  StyledButton,
  Content as ButtonContent,
  BackButton,
  Tooltip,
} from 'core/components';

import './styles.scss';
import { getBidURL } from '../../../BidInfoForm/utils';
import { checkMaintenenceMode } from '../../../BidInfoForm/services/bidInfoService';

interface HeaderProps {
  onExit: Function;
}

type IProps = RouteComponentProps & FormikProps<IBidFormHeader> & HeaderProps;

const calcWidth = (width) => (width + 16) * 5.8;
const setInitialSelectedWidth = (cities, cityId) => {
  const selected =
    cityId === undefined ? cities[0] : cities.find((x) => x.Id === cityId);
  let value = 80;

  if (selected && Object.keys(selected).length) {
    value = selected['Name'].length;
  }

  return calcWidth(value);
};

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

const Header = memo(
  ({
    location,
    values,
    setFieldValue,
    onExit,
    setIsLoading,
    initAutoSave,
    setNeedsSaving,
    showConnectionWarning,
    showEmptyPidWarning,
    setShowEmptyPidWarning,
    showAutoPidTooltip,
    ...props
  }: IProps) => {
    const { formValues, onChange, setFormValues, needsSaving } = useFormProps();
    const [cities, setCities] = useState([]);
    const [selectInputWidth, setSelectInputWidth] = useState(0);
    const { search } = location;
    const { pid, sid, control, bidDate } = formValues;

    const [pidTooltipVisible, setPidTooltipVisible] = useState(false);
    const [showMaintenanceMode, setShowMaintenanceMode] = useState(null);

    React.useEffect(() => {
      if (!bidDate || !isValidDate(bidDate)) {
        onChange('bidDate', new Date());
        setFieldValue('bidDate', new Date());
      }
    }, [bidDate]);

    React.useEffect(() => {
      if (showAutoPidTooltip) {
        setPidTooltipVisible(true);
        setTimeout(() => setPidTooltipVisible(false), 10000);
      }
    }, [showAutoPidTooltip]);

    React.useEffect(() => {
      if (values.bidDate != bidDate) {
        onChange('bidDate', values.bidDate);
      }
    }, [values.bidDate]);

    const onBlur = useCallback(
      (name: string) => () => {
        if (name == 'pid') {
          if (pidTooltipVisible) {
            setPidTooltipVisible(false);
          }
          initAutoSave();
        }
        onChange(name, formValues[name]);
      },
      [formValues, onChange]
    );

    const getStatus = (statusKey) => {
      switch (statusKey) {
        case 1:
          return 'Open';
        case 2:
          return 'Sold';
        case 3:
          return 'SoldLost';
        case 4:
          return 'KeptService';
        case 5:
          return 'LostPrice';
        case 6:
          return 'UnableToPlace';
        case 7:
          return 'ChoseOtherService';
        case 8:
          return 'Turnback';
        case 9:
          return 'InHouse';
        default:
          return 'N/A';
      }
    };

    useEffect(() => {
      const loadCities = async () => {
        const currentUrl = search;
        const userId = getQueryString(currentUrl, 'userId');

        const userData = await axiosInstance.get(
          `GetUserById?userid=${userId}`
        );
        const userHasBetaOptIn = userData.data['IsOptIn'];

        const getMasterCities = async (): Promise<any> => {
          // const params = new URLSearchParams(currentUrl);
          // const userId = params.get("userId");

          console.log('BidInfoForm userId', userId);

          const listCloud = await axiosInstance.get(
            `InspectionReport/getFranchises?userid=${userId}`
          );

          onChange(
            'userSignNowStatus',
            userData.data['SignatureProviderStatus'] != null
              ? userData.data['SignatureProviderStatus']
              : -1
          );

          // if (
          //   userData.data['IsOptIn'] != null &&
          //   userData.data['IsOptIn'] != undefined
          // ) {
          //   setTimeout(
          //     () => onChange('userHasBetaOptIn', userData.data['IsOptIn']),
          //     900
          //   );
          // }

          // onChange('userId', userId);

          const formId = getQueryString(currentUrl, 'formId');
          const uniqueId = getQueryString(currentUrl, 'formUniqueId');
          const easterEgg = window.location.href.indexOf('pre-filled') >= 0;

          if (!(formId || uniqueId || easterEgg)) {
            setTimeout(() => {
              setNeedsSaving(false);
              setIsLoading(false);
            }, 1000);
          }

          return listCloud.data;
        };
        const cities = await getMasterCities();
        setCities(cities);
        const maintenanceMode = await checkMaintenenceMode();
        if (maintenanceMode.status == '200' && maintenanceMode.data) {
          setShowMaintenanceMode(maintenanceMode.data);
        }

        const { state = {} } = location;

        if (state.formValues) {
          setFormValues({
            ...state.formValues,
            cityId: cities[0]['Id'],
            cities,
            userHasBetaOptIn,
            userId,
          });
          sessionStorage.setItem('CITIES', JSON.stringify(cities));
        } else {
          setFormValues({
            ...formValues,
            cityId: cities[0]['Id'],
            cities,
            userHasBetaOptIn,
            userId,
          });
          sessionStorage.setItem('CITIES', JSON.stringify(cities));
        }
      };

      loadCities();
      const datePickers = document.getElementsByClassName(
        'react-datepicker__input-container'
      );
      Array.from(datePickers).forEach((el) =>
        el.childNodes[0].setAttribute('readOnly', true)
      );
    }, [search]);

    useEffect(() => {
      const hidePopup = (e) => {
        let canHide = true;
        if (e && e.path) {
          for (var el of e.path) {
            if (el.id == 'pidTooltip' || el.id == 'pidInfoIcon') {
              canHide = false;
              break;
            }
          }
          if (canHide) setPidTooltipVisible(false);
        }
      };

      const hideOnTouch = (e) => {
        setPidTooltipVisible(false);
      };

      if (pidTooltipVisible) {
        document.body.addEventListener('click', hidePopup);
        document.body.addEventListener('touchstart', hideOnTouch, false);
        document
          .getElementById('root')
          .addEventListener('scroll', hideOnTouch, false);
      } else {
        document.body.removeEventListener('click', hidePopup);
        document.body.addEventListener('touchstart', hideOnTouch, false);
        document
          .getElementById('root')
          .addEventListener('scroll', hideOnTouch, false);
      }
    }, [pidTooltipVisible]);

    React.useEffect(() => {
      if (
        !!cities &&
        cities.length > 0 &&
        (!formValues.cityId ||
          cities.filter((t) => t.Id == formValues.cityId).length == 0)
      ) {
        setTimeout(() => {
          // onChange('cityId', cities[0]['Id']);
          setFormValues({ ...formValues, cityId: cities[0]['Id'], cities });
          sessionStorage.setItem('CITIES', JSON.stringify(cities));
        }, 0);
      }
    }, [formValues.cityId]);

    const onSelectChange = (
      name: string,
      value,
      element: HTMLSelectElement
    ) => {
      const selected = element.options[element.selectedIndex].label;
      setSelectInputWidth(calcWidth(selected.length));
      onChange(name, value);
    };

    if (selectInputWidth === 0 && cities.length) {
      setSelectInputWidth(setInitialSelectedWidth(cities, formValues.cityId));
    }

    return (
      <>
        {showConnectionWarning && (
          <div className="check-connection-message-wrapper">
            <div className="check-connection-message">
              <span className="title">Auto-Save Failed - Check Connection</span>
              <span className="message">
                Please do not close this browser or you will lose any changes
                since the last Auto-Save. You will not be able to complete this
                form until your connection is restored.
              </span>
            </div>
          </div>
        )}
        {showEmptyPidWarning && (
          <div className="check-connection-message-wrapper">
            <div
              className="check-connection-message"
              style={{
                padding: '15px 0',
                display: showEmptyPidWarning ? 'flex' : 'none',
              }}
            >
              <span className="title">Attention</span>
              <span className="message">
                The Auto-Save will only be enabled after the PID Field is
                populated.
              </span>
            </div>
          </div>
        )}

        {showMaintenanceMode && (
          <div className="maintenance-alert-wrapper" style={{ width: '100%' }}>
            <div
              className="maintenance-alert-message"
              style={{
                backgroundColor: 'white',
                padding: '15px',
                maxWidth: '1200px',
                textAlign: 'center',
              }}
            >
              <span className="message" style={{ color: 'black' }}>
                <span style={{ fontWeight: 'bolder', color: 'red' }}>
                  Attention:{' '}
                </span>
                {showMaintenanceMode}
              </span>
            </div>
          </div>
        )}

        <div className="header bid-header">
          <div className="header-content">
            <h1>{props.title}</h1>
            {/* {isWebView() && (
            <div className="back-button-container">
              <button
                className="button"
                onClick={() =>
                  needsSaving ? onExit() : props.history.push('/close-form')
                }
              >
                BACK
              </button>
            </div>
          )} */}

            {isWebView() && (
              <BackButton
                style={{
                  position: 'absolute',
                  right: '5px',
                  top: '10px',
                }}
                onClick={() =>
                  needsSaving
                    ? onExit()
                    : isWebView()
                    ? props.history.push('/close-form')
                    : (document.location.href = getBidURL())
                }
              />
            )}

            <div
              className="hamb-menu-icon"
              onClick={() => props.openSearch()}
            />
          </div>
          <div className="bid-space-between">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
              }}
            >
              <div className="bid-input-content" style={{ paddingTop: '15px' }}>
                <div className="bid-input-label">Master City</div>
                <Select
                  width={selectInputWidth}
                  value={formValues.cityId}
                  name="cityId"
                  items={cities}
                  onChange={onSelectChange}
                  optionLabel="Name"
                  optionValue="Id"
                  placeholder="Select a city"
                  disabled={formValues.status === 2}
                />
              </div>

              {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                disabled={formValues.status === 2}
                onClick={props.openSearch}
              >
                {props.searchButtonLabel}
              </Button>
            </div> */}
            </div>
            <div className="bid-status">
              <h3 className="bid-status-label">Status: </h3>
              <div className="bid-status-label">
                {getStatus(formValues.status)}
              </div>
            </div>
          </div>

          <div className="bid-form-container">
            <div className="bid-form-content">
              <div className="bid-input-content validate-pid">
                <div style={{ display: 'flex' }}>
                  <div className="bid-input-label">PID</div>
                  <Tooltip
                    id="pidTooltip"
                    overlay={
                      <span
                        style={{
                          fontSize: '13px',
                          display: 'block',
                          textAlign: 'justify',
                        }}
                      >
                        Enter a PID to enable Auto-Save
                      </span>
                    }
                    // trigger="click"
                    placement="topRight"
                    alignedItemId="pidInfoIcon"
                    visible={pidTooltipVisible}
                    positioningFunction={(node, align) => {
                      const targetEl = document.getElementById('pidInfoIcon');
                      const rect = targetEl.getBoundingClientRect();

                      const adjustWidth =
                        document.getElementsByClassName(
                          'rc-tooltip-content'
                        )[0];
                      const adjustHeight =
                        document.getElementsByClassName('rc-tooltip-inner')[0];

                      // const wrapper = document.getElementsByClassName(
                      //   'rc-tooltip'
                      // )[0];

                      adjustWidth.style.width = '215px';
                      adjustHeight.style.minHeight = 'auto';
                      // wrapper.style.left = '66px';

                      node.style.top = `${rect.top - 45}px`;
                      node.style.left = `${rect.left + 5}px`;
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginLeft: '10px',
                      }}
                    >
                      <div
                        className="pid-tooltip"
                        id="pidInfoIcon"
                        onClick={(e) => {
                          setPidTooltipVisible(!pidTooltipVisible);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faInfo}
                          size="xs"
                          color="white"
                        />
                      </div>
                    </div>
                  </Tooltip>
                </div>
                <input
                  value={pid}
                  onChange={(e) => {
                    if (!e.target.value) {
                      setShowEmptyPidWarning(true);
                    } else {
                      if (showEmptyPidWarning) {
                        setShowEmptyPidWarning(false);
                      }
                    }

                    onChange('pid', e.target.value);
                  }}
                  placeholder="PID"
                  maxLength={10}
                  className="input-header auto-capitalize input-bid "
                  onBlur={onBlur('pid')}
                  disabled={formValues.status === 2}
                />
              </div>

              <div className="bid-input-content">
                <div className="bid-input-label">SID</div>
                <input
                  value={sid}
                  onChange={(e) => onChange('sid', e.target.value)}
                  placeholder="SID"
                  maxLength={10}
                  className="input-header auto-capitalize input-bid"
                  onBlur={onBlur('sid')}
                  disabled={formValues.status === 2}
                />
              </div>

              <div className="bid-input-content">
                <div className="bid-input-label">Control #</div>
                <input
                  value={control}
                  onChange={(e) => onChange('control', e.target.value)}
                  placeholder="Control #"
                  maxLength={10}
                  className="input-header auto-capitalize input-bid"
                  onBlur={onBlur('control')}
                  disabled={formValues.status === 2}
                />
              </div>

              <div className="bid-input-content validate-bid-date">
                <div className="bid-input-label">Bid Date</div>
                <DatePicker
                  onChange={(date) => onChange('bidDate', date)}
                  onBlur={() => {}}
                  className={`input-header input-bid`}
                  selected={isValidDate(bidDate) ? bidDate : null}
                  disabled={formValues.status === 2}
                  popperPlacement="bottom-end"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

const mapForm = {
  mapPropsToValues: () => ({
    ...headerValues,
  }),
  handleSubmit: () => '',
};

const EnhancedHeader = withFormik<any, IBidFormHeader>(mapForm)(
  withRouter(Header)
);

export { EnhancedHeader as Header };
