import React from 'react';
import { Checkbox, RadioButton, Tooltip } from 'core/components';
import { getCookie } from 'core/utils/cookiesUtils';
import {
  getAttachmentsPdf,
  getInsurancePdf,
} from 'modules/BidInfoForm/services/bidInfoService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import {
  Holder,
  Text,
  Heading,
  Row,
  Cell,
  RadioItem,
  InputLabel,
  IconWrapper,
  InputLabelSpan,
} from '../styles';
import { AttachmentsHolder, ManageButton } from './styles';
import { ISystemAttachment, OneTimeAttachment } from './OneTimeAttachment';
import { ANAGO_CLEANSOURCE_DEV_LOGIN_URL } from 'core/utils/constants';

type TData = Array<string>;

interface IAttachmentsData {
  getData: () => TData;
}

const industrySpecificItems = {
  'Condos/Apartments': 'aptbldgs',
  'Auto Dealerships': 'cardealer',
  'Event Venues': 'event',
  'Banking/Financial Centers': 'financial',
  'Fitness Centers/Gyms': 'fitness',
  'Hotels/Hospitality': 'hotels',
  'Industrial/Manufacturing': 'industrial',
  'Medical/Hospitals': 'medical',
  'Commercial Offices': 'office',
  'Religious Facilities': 'religious',
  Restaurants: 'restaurants',
  'Retail Storefronts': 'retail',
  'Schools/Daycares': 'school',
  'Post-Construction': 'postconstruction',
};

const desinfactionSpecificItems = {
  'Protection+ Don’t Wait': 'dontwait',
  'Protection+ Clean Start': 'cleanstart',
  'High-Touch Checklist': 'hightouch',
  'Covid Priority Disinfection': 'covid',
};

const generaclCleaningItems = ['generalCleaning', 'greenCleaning', 'floorCare'];

const recommendedItems = [
  'cleanCom',
  'insurance',
  'trustedPartner',
  'proServices',
  'navy',
  'orange',
  'blue',
  'hightouch',
  'dontwait',
  'electrostatic',
  'reopening',
  'covid',
  'sign',
  'cleanstart',
];

function Attachments(props, ref: React.MutableRefObject<undefined>) {
  const [recommended, setRecommended] = React.useState<string[]>([
    ...(props.haveInsurance ? ['insurance'] : []),
  ]);
  const [generalCleaning, setGeneralCleaning] = React.useState<string[]>([]);
  const [industrySpecific, setIndustrySpecific] =
    React.useState<string>('industry-na');
  const [selectedSystemAttachments, setSelectedSystemAttachments] =
    React.useState<string[]>([]);
  const [selectedFranchiseAttachments, setSelectedFranchiseAttachments] =
    React.useState<string[]>([]);
  const [oneTimeAttachment, setOneTimeAttachment] = React.useState({});

  const showFranchiseAttachment =
    getCookie('ANAGO_USER_SHOW_LOCAL_ATTACHMENTS') === 'true';

  const [desinfactionSpecific, setDesinfactionSpecific] =
    React.useState<string>('');

  const radioOnChange = (name: string, value: string) => {
    setIndustrySpecific(value);
  };

  const radioDesinfactionOnChange = (name: string, value: string) => {
    setDesinfactionSpecific(value);
  };

  const handleGeneralCleaning = (
    name: string,
    value: string,
    isChecked: boolean
  ) => {
    let data = [...generalCleaning];
    const hasValue = data.includes(value);

    if (!isChecked) {
      if (hasValue) {
        data.splice(data.indexOf(value), 1);
      }
    } else {
      if (!hasValue) {
        data.push(value);
      }
    }

    setGeneralCleaning(data);
  };

  const handleSystemAttachments = (
    name: string,
    value: string,
    isChecked: boolean
  ) => {
    let data = [...selectedSystemAttachments];
    const hasValue = data.includes(value);

    if (!isChecked) {
      if (hasValue) {
        data.splice(data.indexOf(value), 1);
      }
    } else {
      if (!hasValue) {
        data.push(value);
      }
    }

    setSelectedSystemAttachments(data);
  };

  const handleFranchiseAttachments = (
    name: string,
    value: string,
    isChecked: boolean
  ) => {
    let data = [...selectedFranchiseAttachments];
    const hasValue = data.includes(value);

    if (!isChecked) {
      if (hasValue) {
        data.splice(data.indexOf(value), 1);
      }
    } else {
      if (!hasValue) {
        data.push(value);
      }
    }

    setSelectedFranchiseAttachments(data);
  };

  const handleRecommended = (
    name: string,
    value: string,
    isChecked: boolean
  ) => {
    let data = [...recommended];
    const hasValue = data.includes(value);

    if (!isChecked) {
      if (hasValue) {
        data.splice(data.indexOf(value), 1);
      }
    } else {
      if (!hasValue) {
        data.push(value);
      }
    }

    setRecommended(data);
  };

  const radioButtonRenderer = (item, renderButton) => {
    return (
      <Cell width="222px">
        <RadioItem>
          {renderButton(item)}
          {item.label == 'N/A' ? (
            <InputLabelSpan>{item.label}</InputLabelSpan>
          ) : (
            <InputLabel
              target="_blank"
              onClick={(e) => e.stopPropagation()}
              href={getAttachmentsPdf(item.value)}
            >
              {item.label}
            </InputLabel>
          )}
        </RadioItem>
      </Cell>
    );
  };

  React.useImperativeHandle<unknown, IAttachmentsData>(ref, () => ({
    getData: () => {
      const data = [...recommended, ...generalCleaning];
      if (industrySpecific != 'industry-na') {
        data.push(industrySpecific);
      }
      return {
        others: data,
        systemAttachments: selectedSystemAttachments,
        franchiseAttachments: selectedFranchiseAttachments,
        oneTimeAttachment: oneTimeAttachment,
      };
    },
  }));

  React.useEffect(() => {
    if (props.selectedAttachments && props.selectedAttachments.length >= 0) {
      const recommended = [];
      const generalCleaning = [];

      props.selectedAttachments.forEach((attachment) => {
        if (generaclCleaningItems.includes(attachment)) {
          generalCleaning.push(attachment);
        } else if (recommendedItems.includes(attachment)) {
          if (attachment === 'insurance') {
            if (props.haveInsurance) {
              recommended.push(attachment);
            }
          } else {
            recommended.push(attachment);
          }
        }
      });

      setGeneralCleaning(generalCleaning);
      setRecommended(recommended);

      Object.keys(industrySpecificItems).forEach((i) => {
        if (props.selectedAttachments.includes(industrySpecificItems[i])) {
          setIndustrySpecific(industrySpecificItems[i]);
        }
      });
    }

    if (
      props.selectedSystemAttachments &&
      props.selectedSystemAttachments.length >= 0 &&
      props.systemAttachments &&
      props.systemAttachments.length >= 0
    ) {
      const selectedSystemAttachments = [];

      props.selectedSystemAttachments.forEach((attachment) => {
        if (
          props.systemAttachments.filter((i) => i['Name'] == attachment)
            .length > 0
        ) {
          selectedSystemAttachments.push(attachment);
        }
      });

      setSelectedSystemAttachments(selectedSystemAttachments);
    }

    if (
      props.selectedFranchiseAttachments &&
      props.selectedFranchiseAttachments.length >= 0 &&
      props.franchiseAttachments &&
      props.franchiseAttachments.length >= 0
    ) {
      const selectedFranchiseAttachments = [];

      props.selectedFranchiseAttachments.forEach((attachment) => {
        if (
          props.franchiseAttachments.filter((i) => i['Name'] == attachment)
            .length > 0
        ) {
          selectedFranchiseAttachments.push(attachment);
        }
      });

      setSelectedFranchiseAttachments(selectedFranchiseAttachments);
    }
  }, [
    props.selectedAttachments,
    props.selectedSystemAttachments,
    props.selectedFranchiseAttachments,
    props.systemAttachments,
    props.franchiseAttachments,
  ]);

  const previewIcon = (id: string) => {
    return (
      <Tooltip
        overlay={
          <span
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Click on the option name to preview the content
          </span>
        }
        // trigger="click"
        placement="topRight"
        alignedItemId={`previewTooltip_${id}`}
        positioningFunction={(node, align) => {
          const targetEl = document.getElementById(`previewTooltip_${id}`);
          const rect = targetEl.getBoundingClientRect();
          node.style.left = `${rect.right}px`;
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <IconWrapper
            onClick={(e) => e.stopPropagation()}
            id={`previewTooltip_${id}`}
          >
            <FontAwesomeIcon
              icon={faInfo}
              style={{ fontSize: 12 }}
              size="xs"
              color="#216eab"
            />
          </IconWrapper>
        </div>
      </Tooltip>
    );
  };

  const removeOneTimeAttachment = () => {
    setOneTimeAttachment({});
    // props.showDeleteConfirmationModal(() =>  setOneTimeAttachment({}));
  };
  const chunckedSystemAttachments = Array(
    Math.ceil(props.systemAttachments.length / 2)
  )
    .fill()
    .map((_, index) => index * 2)
    .map((begin) => props.systemAttachments.slice(begin, begin + 2));
  const chunckedFranchiseAttachments = Array(
    Math.ceil(props.franchiseAttachments.length / 2)
  )
    .fill()
    .map((_, index) => index * 2)
    .map((begin) => props.franchiseAttachments.slice(begin, begin + 2));

  return (
    <>
      <Holder margin="0 0 20px 0">
        <Heading size="26px" italic style={{ display: 'inline-flex' }}>
          Attachments {previewIcon('attachments')}
        </Heading>
        <Text>
          Select the documents you would like to include with your proposal
        </Text>
      </Holder>

      <Holder margin="0 0 20px 10px">
        <Heading size="16px">Disinfection Services:</Heading>

        {chunckedSystemAttachments.length > 0 &&
          chunckedSystemAttachments.map((chunk) => {
            return (
              <Row>
                {chunk
                  .filter((c) => !!c)
                  .map((attachment) => {
                    return (
                      <Cell width="300px">
                        <Checkbox
                          label={
                            <InputLabel
                              style={{ paddingLeft: '10px', width: '275px' }}
                              target="_blank"
                              href={attachment['FileName']}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (
                                  !attachment['FileName'] ||
                                  attachment['IsBlocked']
                                ) {
                                  e.preventDefault();
                                  props.setWarningModal({
                                    message:
                                      'There is no file attached to this option. Please upload the file again.',
                                    proceed: () => props.setWarningModal(null),
                                  });
                                }
                              }}
                            >
                              {attachment['Name']}
                            </InputLabel>
                          }
                          borderColor="gray"
                          name={attachment['Name']}
                          value={attachment['Name']}
                          onChange={handleSystemAttachments}
                          checked={selectedSystemAttachments.includes(
                            attachment['Name']
                          )}
                          disabled={attachment['IsBlocked']}
                          pointerEvents="auto"
                        />
                      </Cell>
                    );
                  })}
              </Row>
            );
          })}

        {chunckedSystemAttachments.length == 0 && (
          <Heading
            size="14px"
            italic
            style={{
              display: 'inline-flex',
              marginLeft: '10px',
              opacity: 0.75,
            }}
          >
            Not Configured
          </Heading>
        )}

        {/* <Row>
          <Cell width="300px">
            <Checkbox
              label={
                <InputLabel
                  style={{ paddingLeft: '10px' }}
                  target="_blank"
                  href={getAttachmentsPdf('cleanstart')}
                  onClick={(e) => e.stopPropagation()}
                >
                  Protection+ Clean Start<sup>&reg;</sup>
                </InputLabel>
              }
              borderColor="gray"
              name="cleanstart"
              value="cleanstart"
              onChange={handleRecommended}
              checked={recommended.includes('cleanstart')}
            />
          </Cell>

          <Cell width="300px">
            <Checkbox
              label={
                <InputLabel
                  style={{ paddingLeft: '10px' }}
                  target="_blank"
                  href={getAttachmentsPdf('sign')}
                  onClick={(e) => e.stopPropagation()}
                >
                  Protection+ Signs
                </InputLabel>
              }
              borderColor="gray"
              name="sign"
              value="sign"
              onChange={handleRecommended}
              checked={recommended.includes('sign')}
            />
          </Cell>
        </Row>
        <Row>
          <Cell width="300px">
            <Checkbox
              label={
                <InputLabel
                  style={{ paddingLeft: '10px' }}
                  target="_blank"
                  href={getAttachmentsPdf('covid')}
                  onClick={(e) => e.stopPropagation()}
                >
                  Covid Priority Disinfection
                </InputLabel>
              }
              borderColor="gray"
              name="covid"
              value="covid"
              onChange={handleRecommended}
              checked={recommended.includes('covid')}
            />
          </Cell>
          <Cell width="300px">
            <Checkbox
              label={
                <InputLabel
                  style={{ paddingLeft: '10px' }}
                  target="_blank"
                  href={getAttachmentsPdf('reopening')}
                  onClick={(e) => e.stopPropagation()}
                >
                  Reopening Booklet
                </InputLabel>
              }
              borderColor="gray"
              name="reopening"
              value="reopening"
              onChange={handleRecommended}
              checked={recommended.includes('reopening')}
            />
          </Cell>
        </Row>
        <Row>
          <Cell width="300px">
            <Checkbox
              label={
                <InputLabel
                  style={{ paddingLeft: '10px' }}
                  target="_blank"
                  href={getAttachmentsPdf('electrostatic')}
                  onClick={(e) => e.stopPropagation()}
                >
                  Electrostatic Disinfection
                </InputLabel>
              }
              borderColor="gray"
              name="electrostatic"
              value="electrostatic"
              onChange={handleRecommended}
              checked={recommended.includes('electrostatic')}
            />
          </Cell>

          <Cell width="300px">
            <Checkbox
              label={
                <InputLabel
                  style={{ paddingLeft: '10px' }}
                  target="_blank"
                  href={getAttachmentsPdf('dontwait')}
                  onClick={(e) => e.stopPropagation()}
                >
                  Protection+ Don’t Wait
                </InputLabel>
              }
              borderColor="gray"
              name="dontwait"
              value="dontwait"
              onChange={handleRecommended}
              checked={recommended.includes('dontwait')}
            />
          </Cell>
        </Row>
        <Row>
          <Cell width="300px">
            <Checkbox
              label={
                <InputLabel
                  style={{ paddingLeft: '10px' }}
                  target="_blank"
                  href={getAttachmentsPdf('hightouch')}
                  onClick={(e) => e.stopPropagation()}
                >
                  High-Touch Checklist
                </InputLabel>
              }
              borderColor="gray"
              name="hightouch"
              value="hightouch"
              onChange={handleRecommended}
              checked={recommended.includes('hightouch')}
            />
          </Cell>
          <Cell width="300px">
            <Checkbox
              label={
                <InputLabel
                  style={{ paddingLeft: '10px' }}
                  target="_blank"
                  href={getAttachmentsPdf('blue')}
                  onClick={(e) => e.stopPropagation()}
                >
                  Pro+ Persona - Blue
                </InputLabel>
              }
              borderColor="gray"
              name="blue"
              value="blue"
              onChange={handleRecommended}
              checked={recommended.includes('blue')}
            />
          </Cell>
        </Row>
        <Row>
          <Cell width="300px">
            <Checkbox
              label={
                <InputLabel
                  style={{ paddingLeft: '10px' }}
                  target="_blank"
                  href={getAttachmentsPdf('orange')}
                  onClick={(e) => e.stopPropagation()}
                >
                  Pro+ Persona - Orange
                </InputLabel>
              }
              borderColor="gray"
              name="orange"
              value="orange"
              onChange={handleRecommended}
              checked={recommended.includes('orange')}
            />
          </Cell>
          <Cell width="300px">
            <Checkbox
              label={
                <InputLabel
                  style={{ paddingLeft: '10px' }}
                  target="_blank"
                  href={getAttachmentsPdf('navy')}
                  onClick={(e) => e.stopPropagation()}
                >
                  Pro+ Persona - Navy
                </InputLabel>
              }
              borderColor="gray"
              name="navy"
              value="navy"
              onChange={handleRecommended}
              checked={recommended.includes('navy')}
            />
          </Cell>
        </Row>
        <Row>
          <Cell width="300px">
            <Checkbox
              label={
                <InputLabel
                  style={{ paddingLeft: '10px' }}
                  target="_blank"
                  href={getAttachmentsPdf('proServices')}
                  onClick={(e) => e.stopPropagation()}
                >
                  Pro+ Services
                </InputLabel>
              }
              borderColor="gray"
              name="proServices"
              value="proServices"
              onChange={handleRecommended}
              checked={recommended.includes('proServices')}
            />
          </Cell>
          <Cell width="300px">
            <Checkbox
              label={
                <InputLabel
                  style={{ paddingLeft: '10px' }}
                  target="_blank"
                  href={getAttachmentsPdf('trustedPartner')}
                  onClick={(e) => e.stopPropagation()}
                >
                  Trusted Partner
                </InputLabel>
              }
              borderColor="gray"
              name="trustedPartner"
              value="trustedPartner"
              onChange={handleRecommended}
              checked={recommended.includes('trustedPartner')}
            />
          </Cell>
        </Row> */}
      </Holder>
      <Holder margin="0 0 20px 10px">
        <Heading size="16px" style={{ display: 'inline-flex' }}>
          Industry Specific:
        </Heading>

        <AttachmentsHolder>
          <Row wrap="wrap">
            <RadioButton
              value={industrySpecific}
              onChange={radioOnChange}
              name="industrySpecificRadio"
              options={(() => {
                const a = Object.keys(industrySpecificItems);
                a.sort();
                a.push('N/A');
                return a;
              })().map((key) => ({
                label: key,
                value:
                  key === 'N/A' ? 'industry-na' : industrySpecificItems[key],
              }))}
              optionLabel="label"
              optionValue="value"
              render={radioButtonRenderer}
            />
          </Row>
        </AttachmentsHolder>
      </Holder>

      <Holder margin="0 0 20px 10px">
        <Heading size="16px" style={{ display: 'inline-flex' }}>
          General:
        </Heading>

        <Row>
          <Cell width="220px">
            <Checkbox
              label={
                <InputLabel
                  style={{ paddingLeft: '10px' }}
                  target="_blank"
                  href={getAttachmentsPdf('generalCleaning')}
                  onClick={(e) => e.stopPropagation()}
                >
                  General Cleaning
                </InputLabel>
              }
              borderColor="gray"
              name="generalCleaning"
              value="generalCleaning"
              onChange={handleGeneralCleaning}
              checked={generalCleaning.includes('generalCleaning')}
            />
          </Cell>

          <Cell width="220px">
            <Checkbox
              label={
                <InputLabel
                  style={{ paddingLeft: '10px' }}
                  target="_blank"
                  href={getAttachmentsPdf('greenCleaning')}
                  onClick={(e) => e.stopPropagation()}
                >
                  Green Cleaning
                </InputLabel>
              }
              borderColor="gray"
              name="greenCleaning"
              value="greenCleaning"
              onChange={handleGeneralCleaning}
              checked={generalCleaning.includes('greenCleaning')}
            />
          </Cell>
          <Cell width="220px">
            <Checkbox
              label={
                <InputLabel
                  style={{ paddingLeft: '10px' }}
                  target="_blank"
                  href={getAttachmentsPdf('floorCare')}
                  onClick={(e) => e.stopPropagation()}
                >
                  Floor Care
                </InputLabel>
              }
              borderColor="gray"
              name="floorCare"
              value="floorCare"
              onChange={handleGeneralCleaning}
              checked={generalCleaning.includes('floorCare')}
            />
          </Cell>
        </Row>
      </Holder>

      <Holder margin="0 0 20px 10px">
        <Heading size="16px" style={{ display: 'inline-flex' }}>
          Recommended:
        </Heading>

        <Row>
          <Cell width="220px">
            <Checkbox
              label={
                <InputLabel
                  style={{ paddingLeft: '10px' }}
                  target="_blank"
                  href={getAttachmentsPdf('cleanCom')}
                  onClick={(e) => e.stopPropagation()}
                >
                  CleanCom<sup>&reg;</sup>
                </InputLabel>
              }
              borderColor="gray"
              name="cleanCom"
              value="cleanCom"
              onChange={handleRecommended}
              checked={recommended.includes('cleanCom')}
            />
          </Cell>
          <Cell width="220px">
            <Checkbox
              disabled={!props.haveInsurance}
              pointerEvents="auto"
              label={
                <InputLabel
                  style={{ paddingLeft: '10px' }}
                  target="_blank"
                  href={getInsurancePdf(
                    props.franchiseName
                      ? props.franchiseName.replace(/ /g, '_')
                      : ''
                  )}
                  onClick={(e) => {
                    e.stopPropagation();

                    if (!props.haveInsurance) {
                      e.preventDefault();
                      props.setWarningModal({
                        message:
                          'Insurance PDF is missing from Master Franchise Profile',
                        proceed: () => props.setWarningModal(null),
                      });
                    }
                  }}
                >
                  Insurance
                </InputLabel>
              }
              borderColor="gray"
              name="insurance"
              value="insurance"
              onChange={handleRecommended}
              checked={recommended.includes('insurance') && props.haveInsurance}
            />
          </Cell>
        </Row>
      </Holder>

      <Holder margin="0 0 20px 10px">
        <Heading size="16px" style={{ position: 'relative' }}>
          Regional Attachments:{' '}
          <span style={{ display: 'inline-flex', marginLeft: 0 }}>
            {' '}
            <Tooltip
              overlay={
                <span
                  style={{
                    fontSize: '13px',
                    display: 'block',
                    textAlign: 'justify',
                  }}
                >
                  Here you may add up to two (2) permanent Regional Attachments
                  as well as one (1) One Time Attachment. Please use the Manage
                  Attachments link to update your Regional Attachments and
                  Refresh to view recent changes.
                  <br />
                  <br />
                  <span
                    style={{
                      color: '#ff2d29',
                      fontWeight: 'bold',
                    }}
                  >
                    NOTE: One Time Attachment will only be available on this
                    proposal and will need to be added to other proposals.
                  </span>
                </span>
              }
              trigger="click"
              placement="top"
              alignedItemId="localAttachmentsInInfoIcon"
            >
              <div
                className="beta-optin-button-tooltip"
                id="localAttachmentsInInfoIcon"
                style={{ color: '#216eab', borderColor: '#216eab' }}
              >
                <FontAwesomeIcon icon={faInfo} size="xs" color="#216eab" />
              </div>
            </Tooltip>{' '}
          </span>
          {showFranchiseAttachment && (
            <Row style={{ position: 'absolute', right: 0, top: '-8px' }}>
              <ManageButton onClick={props.refreshAttachments}>
                Refresh
              </ManageButton>
              <span style={{ marginLeft: '10px' }} />
              <ManageButton
                onClick={() =>
                  window.open(
                    ANAGO_CLEANSOURCE_DEV_LOGIN_URL.replace(
                      'login',
                      `manage-franchise-attachments?franchiseId=${props.franchiseId}&form=true`
                    ),
                    '_blank'
                  )
                }
              >
                Manage Attachments
              </ManageButton>
            </Row>
          )}
        </Heading>
        <Row style={{ position: 'relative' }}>
          {chunckedFranchiseAttachments.length > 0 &&
            chunckedFranchiseAttachments.map((chunk) =>
              chunk
                .filter((c) => !!c)
                .map((attachment) => {
                  return (
                    <Cell width="300px">
                      <Checkbox
                        label={
                          <InputLabel
                            style={{ paddingLeft: '10px', width: '275px' }}
                            target="_blank"
                            href={attachment['FileName']}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                !attachment['FileName'] ||
                                attachment['IsBlocked']
                              ) {
                                e.preventDefault();
                                props.setWarningModal({
                                  message:
                                    'There is no file attached to this option. Please upload the file again.',
                                  proceed: () => props.setWarningModal(null),
                                });
                              }
                            }}
                          >
                            {attachment['Name']}
                          </InputLabel>
                        }
                        borderColor="gray"
                        name={attachment['Name']}
                        value={attachment['Name']}
                        onChange={handleFranchiseAttachments}
                        checked={selectedFranchiseAttachments.includes(
                          attachment['Name']
                        )}
                        pointerEvents="auto"
                        disabled={attachment['IsBlocked']}
                      />
                    </Cell>
                  );
                })
            )}

          {chunckedFranchiseAttachments.length == 1 &&
            chunckedFranchiseAttachments[0].length == 1 && (
              <Cell width="300px">
                <Checkbox
                  label={
                    <InputLabelSpan
                      style={{
                        paddingLeft: '10px',
                        width: '275px',
                        fontStyle: 'italic',
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      Not Configured
                    </InputLabelSpan>
                  }
                  borderColor="gray"
                  disabled={true}
                />
              </Cell>
            )}

          {chunckedFranchiseAttachments.length == 0 && (
            <>
              <Cell width="300px">
                <Checkbox
                  label={
                    <InputLabelSpan
                      style={{
                        paddingLeft: '10px',
                        width: '275px',
                        fontStyle: 'italic',
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      Not Configured
                    </InputLabelSpan>
                  }
                  borderColor="gray"
                  disabled={true}
                />
              </Cell>
              <Cell width="300px">
                <Checkbox
                  label={
                    <InputLabelSpan
                      style={{
                        paddingLeft: '10px',
                        width: '275px',
                        fontStyle: 'italic',
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      Not Configured
                    </InputLabelSpan>
                  }
                  borderColor="gray"
                  disabled={true}
                />
              </Cell>
            </>
          )}
        </Row>
      </Holder>
      <Holder margin="0 0 20px 10px">
        <Heading size="16px">
          One Time Attachment:
          <br />
          <span style={{ fontSize: '12px', marginLeft: 0 }}>
            One Time Attachment will only be available on this proposal and will
            need to be added to other proposals.
          </span>
        </Heading>
        <Row>
          <OneTimeAttachment
            setWarningModal={props.setWarningModal}
            onRemove={removeOneTimeAttachment}
            attachment={oneTimeAttachment}
            onChangeProp={(newAttachment: ISystemAttachment) =>
              setOneTimeAttachment((attachment) => ({
                ...attachment,
                ...newAttachment,
              }))
            }
          />
        </Row>
      </Holder>
    </>
  );
}
export default React.forwardRef(Attachments);
