import React from 'react';
import { Prompt } from 'react-router';
// components
import { LoadingModal, ActionAlertModal, Button } from 'core/components';
import { NoteModal } from 'modules/shared/sections/Footer/NoteModal';
import { getQueryString } from 'core/utils';
import { isWebView } from 'core/utils';
import { parseFromApi } from 'modules/BidInfoForm/parser';
import {
  getBidsByUserId,
  getNextPageBids,
  postSetNotes,
  postArchiveBid,
  getByUniqID,
  getBidInfoById,
  saveDraft,
  getPDFCall,
  getPdfBase64,
} from 'modules/BidInfoForm/services/bidInfoService';
import BidModal from './BidModal';
import PdfModal from './pdfmodal';
import { getBidURL } from '../../BidInfoForm/utils';
import { getCookie } from 'core/utils/cookiesUtils';

export const BidFormMenuScreen = (props) => {
  const { search } = props.location;
  const {
    renderTemplateSection = true,
    renderDraftSection = true,
    renderSentSection = true,
    renderSignedSection = true,
    renderCityTemplatesSection = true,
    showBackground = true,
    isSpecialty = false,
    title = 'BID INFO SHEET',
  } = props;
  const [showGreetModal, setShowGreetModal] = React.useState(false);
  const [showDraftModal, setShowDraftModal] = React.useState(false);
  const [formItem, setFormItem] = React.useState({});
  const [isPdfShowing, setIsPdfShowing] = React.useState(false);
  const [pdfBase64, setPdfBase64] = React.useState(false);
  const [pdfId, setPdfId] = React.useState(false);
  const [data, setData] = React.useState({
    ['Signed']: [],
    ['Drafts']: [],
    ['Sents']: [],
    ['Templates']: [],
    ['CityTemplates']: []
  });
  const [currentPage, setCurrentPage] = React.useState(0);
  const [itemsCurrentPage, setItemsCurrentPage] = React.useState({
    Signed: 0,
    Templates: 0,
    Drafts: 0,
    Sents: 0,
  });
  const [searchPid, setSearchPid] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);

  const [notesModalState, setNotesModalState] = React.useState({
    showModal: false,
    canEdit: false,
    notesText: undefined,
    formItem: undefined,
  });

  const showNotesModal = (
    showModal: boolean,
    canEdit: boolean,
    formItem: any
  ) => {
    setNotesModalState((prevState) => ({
      ...prevState,
      showModal,
      canEdit,
      formItem,
    }));
  };

  const saveNotes = (notes) => {
    if (notesModalState.canEdit) {
      setIsLoading(true);
      postSetNotes({
        BidUniqueID: notesModalState.formItem.BidUniqueID,
        Notes: notes,
        BidInformationSheetId: notesModalState.formItem.BidInformationSheetId,
      }).then((result) => {
        const newData = { ...data };
        Object.values(newData).forEach((v) => {
          if (Array.isArray(v)) {
            v.map((i) => {
              if (
                i['BidUniqueID'] == notesModalState.formItem.BidUniqueID ||
                i['BidInformationSheetId'] ==
                  notesModalState.formItem.BidInformationSheetId
              ) {
                i['Notes'] = notes;
              }
            });
          }
        });

        setData(newData);

        setNotesModalState((prevState) => ({
          ...prevState,
          showModal: false,
          canEdit: false,
          formItem: undefined,
        }));
        setIsLoading(false);
      });
    } else {
      setNotesModalState((prevState) => ({
        ...prevState,
        showModal: false,
        canEdit: false,
        formItem: undefined,
      }));
    }
  };

  const archiveForm = (formItem) => {
    setIsLoading(true);

    postArchiveBid({
      BidUniqueID: formItem.BidUniqueID,
      isArchived: true,
      BidInformationSheetId: formItem.BidInformationSheetId,
    }).then((result) => {
      const newData = { ...data };

      Object.keys(newData).forEach((key) => {
        if (Array.isArray(newData[key])) {
          newData[key] = newData[key].filter(
            (i) =>
              i['BidUniqueID'] != formItem.BidUniqueID &&
              i['BidInformationSheetId'] != formItem.BidInformationSheetId
          );
        }
      });

      setData(newData);
      setIsLoading(false);
    });
  };

  const getNextPage = () => {
    setIsLoading(true);
    const userId = getQueryString(search, 'userId');
    setCurrentPage(currentPage + 1);
  };
  //http://anago-forms.api.staging.test.conceptsol.com/api/BidSheet/GetBidsByUser?id=4&page=0&pid=

  React.useEffect(() => {
    const userId = getQueryString(search, 'userId');

    if (userId) {
      getBidsByUserId(userId, currentPage, searchPid, isSpecialty).then(
        (foundValues) => {
          setIsLoading(false);
          console.log({data, foundValues});
          // set types to drafts, sents and templates, so we can identify the items later
          setData({
            ...data,
            ...foundValues.data,
            ['Signed']: [...data['Signed'], ...foundValues.data['Signed']],
            ['Drafts']: [...data['Drafts'], ...foundValues.data['Drafts']],
            ['Sents']: [...data['Sents'], ...foundValues.data['Sents']],
            ['Templates']: [
              ...data['Templates'],
              ...foundValues.data['Templates'],
            ],
            ['CityTemplates']: [
              ...data['CityTemplates'],
              ...foundValues.data['CityTemplates'],
            ],
            
          });
        }
      );
    }
  }, [search, searchPid]);

  const getBidNextPage = (type: string) => {
    console.log(type);
    const userId = getQueryString(search, 'userId');
    const nextPage = itemsCurrentPage[type] + 1;
    if (userId) {
      getNextPageBids(type, userId, nextPage, searchPid, isSpecialty).then(
        (foundValues) => {
          setIsLoading(false);
          setItemsCurrentPage({
            ...itemsCurrentPage,
            [type]: nextPage,
          });
          // set types to drafts, sents and templates, so we can identify the items later
          setData({
            ...data,
            [type]: [...data[type], ...foundValues.data[type]],
          });
        }
      );
    }
  };

  const navigateToForm = (formItem, type) => {
    setFormItem(formItem);
    const isCityUser = getCookie('ANAGO_USER_IS_CITY_USER') === 'true';

    // if ((type === 'myTemplate' || type === 'citytemplate')) {
    //   if(isCityUser){
    //     setShowGreetModal(true);
    //   }else{
    //     setShowDraftModal(true);
    //   }
      
    // } else {
    //   openForm(formItem);
    // }

    openForm(formItem);
  };

  const openURL = (item, str) => {
    if (props.doneAction) {
      props.doneAction(true);
    }
    if (isSpecialty) {
      props.history.push({
        pathname: '/specialty-form',
        search: `?userId=${userId}&${str}&v=${Math.random()
          .toString(36)
          .substring(7)}`,
      });
    } else {
      props.history.push({
        pathname: '/bid-info-form',
        search: `?userId=${userId}&${str}&v=${Math.random()
          .toString(36)
          .substring(7)}`,
      });
    }
  };

  const openOldBidURL = (item, str) => {
    if (props.doneAction) {
      props.doneAction(true);
    }
    props.history.push({
      pathname: '/bid-info-form/old-pdf-view',
      search: `?userId=${userId}&${str}&v=${Math.random()
        .toString(36)
        .substring(7)}`,
    });
  };

  const navigate = (item, param = '') => {
    if (props.doneAction) {
      props.doneAction(true);
    }
    if (item['BidUniqueID']) {
      openURL(item, `formUniqueId=${item['BidUniqueID']}${param}`);
    } else {
      openURL(item, `formId=${item['BidInformationSheetId']}${param}`);
    }
  };

  const openPDF = async (item) => {
    if (props.doneAction) {
      props.doneAction(true);
    }
    setIsLoading(true);
    if (isWebView()) {
      openOldBidURL(
        item,
        `formId=${item['BidInformationSheetId']}&openingOldBidPDF=true`
      );
    } else {
      const pdfBase64 = await getPdfBase64(item['BidInformationSheetId']);
      let pdfWindow = window.open(
        window.location.href + '&code=print-pdf',
        '_blank'
      );
      pdfWindow.document.write(
        "<iframe width='99.6%' height='100%' src='" +
          encodeURI(pdfBase64) +
          "'></iframe>"
      );
      setIsLoading(false);

      // openOldBidURL(
      //   item,
      //   `formId=${item['BidInformationSheetId']}&openingOldBidPDF=true`
      // );
    }
  };

  const userId = getQueryString(search, 'userId');

  const openForm = (item, tabId = null) => {
    if (props.doneAction) {
      props.doneAction(true);
    }
    const isSentType = item.type === 'sents';

    if (isSentType) {
      const newProposal = item.IsReactVersion === true;

      if (newProposal) {
        navigate(item);
      } else {
        openPDF(item);
      }
    } else {
      if (item['BidUniqueID']) {
        openURL(
          item,
          `formUniqueId=${item['BidUniqueID']}${tabId ? '&tabId=' + tabId : ''}`
        );
      } else {
        openURL(
          item,
          `formId=${item['BidInformationSheetId']}${
            tabId ? '&tabId=' + tabId : ''
          }`
        );
      }
    }
  };

  const onEditTemplateClick = () => {
    if (props.doneAction) {
      props.doneAction(true);
    }
    openForm(formItem);
  };

  const onNewDraftClick = async () => {
    if (props.doneAction) {
      props.doneAction(true);
    }
    setIsLoading(true);

    const goGetIt = formItem['BidUniqueID']
      ? () => getByUniqID(formItem['BidUniqueID'])
      : () => getBidInfoById(formItem['BidInformationSheetId']);
    const response = await goGetIt();

    const parsed = parseFromApi({
      ...formItem,
      ...response.data.Data,
    });

    parsed.tabId = new Date().toISOString();

    const saveDraftResponse = await saveDraft(parsed, userId);
    const response2 = await getByUniqID(saveDraftResponse.data.id);

    openForm(response2.data.Data, parsed.tabId);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <LoadingModal localLoading />}

      <BidModal
        isSpecialty={isSpecialty}
        title={title}
        navigateToForm={navigateToForm}
        data={data}
        doneAction={
          props.doneAction
            ? () => props.doneAction(false)
            : () => {
                if (isWebView()) {
                  document.location.replace('/#/close-form');
                } else {
                  document.location.href = getBidURL();
                }
              }
        }
        background={showBackground}
        renderDraftSection={renderDraftSection}
        renderSignedSection={renderSignedSection}
        renderTemplateSection={renderTemplateSection}
        renderSentSection={renderSentSection}
        renderSignedSection={renderSignedSection}
        renderCityTemplatesSection={renderCityTemplatesSection}
        getNextPage={getBidNextPage}
        archiveForm={archiveForm}
        showNotesModal={(canEdit, formItem) =>
          showNotesModal(true, canEdit, formItem)
        }
      />

      <ActionAlertModal
        title="Template"
        message="Do you want to edit or start a new draft based on the current template?"
        onConfirm={onNewDraftClick}
        confirmText="NEW DRAFT"
        onDismiss={onEditTemplateClick}
        dismissText="EDIT TEMPLATE"
        isVisible={showGreetModal}
      ></ActionAlertModal>

      <ActionAlertModal
        title="Template"
        message="This will crate a new draft based on your City template"
        onConfirm={onNewDraftClick}
        onDismiss={()=> setShowDraftModal(false)}
        isVisible={showDraftModal}
      ></ActionAlertModal>


      {notesModalState.showModal && (
        <NoteModal
          onCancel={() =>
            setNotesModalState((prevState) => ({
              ...prevState,
              showModal: false,
              canEdit: false,
              formItem: undefined,
            }))
          }
          notesText={notesModalState.formItem.Notes || ' '}
          canEdit={notesModalState.canEdit}
          onClose={(notes) => saveNotes(notes)}
        />
      )}
    </>
  );
};
