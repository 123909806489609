import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// redux
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { InspectFormActions } from 'modules/InspectForm/redux/actions';
// interfaces
import { IReduxState } from 'core/interfaces';
import * as Sentry from '@sentry/react';
import {
  IStateToProps,
  IDispatchToProps,
} from 'modules/InspectForm/interfaces';
import { ANAGO_CLEANSOURCE_DEV_LOGIN_URL } from 'core/utils/constants';
import { InspectionMenuScreen } from 'modules/InspectForm/screens';
import './styles.scss';
import {
  FirstForm,
  SiteInformationForm,
  AreasForm,
  FooterBar,
  Header,
} from './sections';
import {
  SectionRatingModal,
  AddPhotosModal,
  NotesModal,
  AddAreaModal,
  AddItemModal,
  PdfModal,
  GenericModal,
} from 'core/components';
import { LoadingModal, SuccessModal } from 'core/components';

import { LoadingPage } from 'modules/BidInfoForm/components/organisms';

import ActionAlertModal from 'core/components/ActionAlertModal';

import { getQueryString, axiosInstance } from 'core/utils';
import { getInspectionURL } from '../../../BidInfoForm/utils';
import { isWebView } from '../../../../core/utils';

import { verifyServerUnavailable } from '../../../BidInfoForm/services/bidInfoService';
import { BlockingOverlay } from '../../../BidInfoForm/components/organisms';
import SignNowScreen from '../../../BidInfoForm/screens/SignNowScreen';
import BlockingPopupModal from '../../../BidInfoForm/screens/BlockingPopupModal';
import { useActivityTracking } from '../../../../core/hooks/useActivityTracking';
import { useAuthenticationHandler } from '../../../../core/hooks/useAuthenticationHandler';
import { useGenericErrorHandler } from 'core/hooks/useGenericErrorHandler';
import { getCookie } from '../../../../core/utils/cookiesUtils';

interface IState {
  needsSaving: boolean;
  isNewModalOpen: boolean;
  hideButtonDesktop: boolean;
  isNewModalAlreadyOpened: boolean;
  blockingMessage?: string;
  error: boolean;
}

type IProps = IDispatchToProps & IStateToProps & RouteComponentProps;

let logSentry = null;

const TrackActivityComponent = (props) => {
  logSentry = useActivityTracking(() => props.values);

  let { error, setError } = useAuthenticationHandler();
  const { error: genericError, setError: setGenericError } =
    useGenericErrorHandler();

  React.useEffect(() => {
    if (!!error && error.status == 401) {
      props.setError(true);
      props.setLoadingState(false);
      props.showWarningModal(
        true,
        'You do not have permissions to access this form. Please contact Anago Support if you believe you have reached this in error.',
        'Access Denied',
        () => {
          window.location.href = ANAGO_CLEANSOURCE_DEV_LOGIN_URL.replace(
            'login',
            'home'
          );
        }
      );
    } else if (!!error && error.status == 403) {
      try {
        if (error.data.includes('CLIENT_VERSION_MISMATCH')) {
          props.setLoadingState(false);
          props.showWarningModal(
            true,
            'Seems like your app is outdated. This may happen due to browser cache issues. Click OK to get the latest version from server.',
            'Attention',
            () => {
              window.location.reload(true);
            }
          );
        }
      } catch (e) {
        setGenericError(e);
      }
    }
  }, [error]);

  React.useEffect(() => {
    if (!!genericError) {
      logSentry(genericError);
      props.showWarningModal(
        true,
        'An unexpected error has occurred. Please contact AFISupport with Error ID: ' +
          sessionStorage.getItem('transaction_id'),
        'Attention'
      );
    }
  }, [genericError]);

  return <></>;
};

class NewInspectScreen extends Component<IProps, IState> {
  public state = {
    needsSaving: false,
    isNewModalOpen: false,
    isNewModalAlreadyOpened: false,
    hideButtonDesktop: false,
    showMenu: undefined,
    error: false,
    blockingModal: null,
    isCityUser: false,
  };

  onOpenSearch = () => {
    this.setState({
      ...this.state,
      showMenu: {
        renderTemplateSection: true,
        renderCityTemplateSection: true,
        renderDraftSection: true,
        renderSentSection: true,
        renderSignedSection: true,
      },
    });
  };

  public async loadForm() {
    const {
      location,
      getEditFormRequest,
      setUserAndFranchiseId,
      setIsSent,
      isSent,
      isTemplate,
      isCityTemplate,
      newForm,
      isDraft,
      showPopup,
      successResult,
      setLoadingState,
      // isTemplate,
      // isDraft
    } = this.props;

    // setLoadingState(true);

    const currentUrl = location.search;
    // const params = new URLSearchParams(currentUrl);
    // const id = params.get("id");
    const id = getQueryString(currentUrl, 'id');
    // const franchiseId = params.get("FranchiseId");
    const franchiseId = getQueryString(currentUrl, 'FranchiseId');
    // const userId = params.get("UserId");
    const userId = getQueryString(currentUrl, 'UserId');
    // const isSent = params.get("isSent");
    const isSentQueryString = getQueryString(currentUrl, 'isSent');
    const isTemplateQueryString = getQueryString(currentUrl, 'isTemplate');

    const isDuplicateQueryString = getQueryString(currentUrl, 'isDuplicate');

    const isCityTemplateQueryString = getQueryString(
      currentUrl,
      'isCityTemplate'
    );
    const isCityUser = getCookie('ANAGO_USER_IS_CITY_USER') === 'true';

    this.setState({ isCityUser });

    const hideButtonDesktop = getQueryString(currentUrl, 'hideButtonDesktop');
    if (id) {
      getEditFormRequest(id);
    }

    setUserAndFranchiseId(
      franchiseId ? Number(franchiseId) : null,
      userId ? Number(userId) : null
    );

    // else {
    //   setUserAndFranchiseId(4, 4);
    // }
    const isTrulySent = isSentQueryString === 'true';
    if (isTrulySent) {
      setIsSent();
    }
    // if (!isTrulySent && isTemplate && !isDraft) {
    if (
      (isTemplateQueryString === 'true' ||
        isTemplate ||
        ((isCityTemplateQueryString === 'true' || isCityTemplate) &&
          isCityUser)) &&
      showPopup
    ) {
      // this.props.setTemplateModal(true)
      this.setState({
        isNewModalOpen: true,
        isNewModalAlreadyOpened: true,
        isCityTemplate: isCityTemplateQueryString === 'true' || isCityTemplate,
      });
    } else if (
      (isCityTemplateQueryString === 'true' || isCityTemplate) &&
      (showPopup || (!isCityUser && newForm))
    ) {
      this.setState({
        isNewModalOpen: true,
        isNewModalAlreadyOpened: true,
        isCityTemplate: isCityTemplateQueryString === 'true' || isCityTemplate,
      });
    }

    if (hideButtonDesktop === 'true') {
      this.setState({ hideButtonDesktop: true });
    }

    if (isDuplicateQueryString === 'true') {
      try {
        const formValues = JSON.parse(
          localStorage.getItem('INSPECTION_DUPLICATE')
        );
        localStorage.removeItem('INSPECTION_DUPLICATE');
        this.props.duplicate(formValues);
      } catch (e) {}
    }

    try {
      const response = await axiosInstance.get(`getUserById?userid=${userId}`);

      const { setInspectedBy } = this.props;
      setInspectedBy(response.data.Name, response.data.UserId);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }

    if (window.history.pushState) {
      let searchParams = new URLSearchParams(this.props.location.search);
      if (successResult) {
        searchParams.set('id', successResult.originalId);
      } else if (this.props.InspectionReportId) {
        searchParams.set('id', this.props.InspectionReportId);
      }
      searchParams.set('isTemplate', '' + !!isTemplate);
      searchParams.set('isCityTemplate', '' + !!isCityTemplate);
      searchParams.set('isSent', '' + !!isSent);
      // let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
      // window.history.pushState({path: newurl}, '', newurl);
      window.history.pushState(
        {
          path:
            window.location.protocol +
            '//' +
            window.location.host +
            '/#/inspection-report-form',
        },
        '',
        `${window.location.protocol}//${window.location.host}/#/inspection-report-form?${searchParams}`
      );
    }

    // setLoadingState(false)
  }

  public componentDidMount() {
    this.loadForm();
    const currentUrl = this.props.location.search;
    const userId = getQueryString(currentUrl, 'UserId');
    (async () => {
      try {
        const result = await verifyServerUnavailable(userId);
        if (!result || result.error) {
          this.setState({
            blockingModal: {
              message:
                'Server under maintenance at this time, please try later',
            },
          });
        } else {
          this.setState({ blockingModal: null });
        }
      } catch (e) {
        if (e.response.status == '503') {
          this.setState({
            blockingModal: {
              message:
                'Server under maintenance at this time, please try later',
            },
          });
        }
      }
    })();
  }

  public componentWillReceiveProps(nextProps: IProps) {
    const { isNewModalAlreadyOpened } = this.state;
    const currentUrl = nextProps.location.search;

    const {
      isTemplate,
      isCityTemplate,
      isSent,
      newForm,
      showPopup,
      setLoadingState,
    } = nextProps;

    // setLoadingState(true);

    const isTemplateQueryString = getQueryString(currentUrl, 'isTemplate');

    const isCityTemplateQueryString = getQueryString(
      currentUrl,
      'isCityTemplate'
    );

    const showModal = () => {
      const isCityUser = getCookie('ANAGO_USER_IS_CITY_USER') === 'true';
      const hideButtonDesktop = getQueryString(currentUrl, 'hideButtonDesktop');

      // if (!isTrulySent && isTemplate && !isDraft) {
      if (
        (!isNewModalAlreadyOpened || showPopup) &&
        (isTemplate || isCityTemplate)
      ) {
        // this.props.setTemplateModal(true)
        this.setState({
          isNewModalOpen: true,
          isNewModalAlreadyOpened: true,
          isCityTemplate: isCityTemplate,
          newForm,
        });
      } else if (
        (!isNewModalAlreadyOpened || showPopup || (!isCityUser && newForm)) &&
        isCityTemplate
      ) {
        this.setState({
          isNewModalOpen: true,
          isNewModalAlreadyOpened: true,
          isCityTemplate: isCityTemplate,
          newForm,
        });
      } else {
        this.setState({
          isNewModalOpen: false,
          isCityTemplate: isCityTemplate,
          newForm,
        });
      }

      if (hideButtonDesktop === 'true') {
        this.setState({ hideButtonDesktop: true });
      }
    };
    // console.log({ isNewModalAlreadyOpened });
    const { error, setWarningModalState } = nextProps;
    if (error && error !== this.props.error) {
      // setWarningModalState(true, error.toString())
      // setWarningModalState(
      //   true,
      //   'There was an error while performing a request to the server'
      // );
      const currentUrl = nextProps.location.search;
      const id = getQueryString(currentUrl, 'id');

      if (logSentry) {
        logSentry(error, { uid: id });
      }
      if (error.status === 500) {
        this.props.history.push(`/forms/unable-to-open`);
      }
      // alert(error)
    } else if (!this.state.isNewModalAlreadyOpened) {
      showModal();
    } else if (this.props.location.search != nextProps.location.search) {
      showModal();
    }
    const { successResult } = nextProps;

    if (window.history.pushState) {
      let searchParams = new URLSearchParams(nextProps.location.search);
      if (successResult) {
        searchParams.set('id', successResult.originalId);
      } else if (this.props.InspectionReportId) {
        searchParams.set('id', this.props.InspectionReportId);
      }
      searchParams.set('isTemplate', '' + !!isTemplate);
      searchParams.set('isCityTemplate', '' + !!isCityTemplate);
      searchParams.set('isSent', '' + !!isSent);
      // let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
      // window.history.pushState({path: newurl}, '', newurl);
      window.history.pushState(
        {
          path:
            window.location.protocol +
            '//' +
            window.location.host +
            '/#/inspection-report-form',
        },
        '',
        `${window.location.protocol}//${window.location.host}/#/inspection-report-form?${searchParams}`
      );

      showModal();
    }
  }

  public dealClose = () => {
    const { history } = this.props;
    if (isWebView()) {
      history.push('/close-form');
    } else {
      document.location.href = getInspectionURL();
    }
  };

  public closeWarningModal = () => {
    const { setWarningModalState } = this.props;
    if (this.state.error) {
      window.location.href = ANAGO_CLEANSOURCE_DEV_LOGIN_URL.replace(
        'login',
        'home'
      );
    }
    setWarningModalState(false, '');
  };

  public onClickDraft = () => {
    const { transformIntoDraft } = this.props;
    transformIntoDraft();
    // setTemplateModal(false);
    setTimeout(() => {
      this.setState({ isNewModalOpen: false });
      this.props.setShowPopup(false);
    }, 0);
    // navigateToForm(`${form.InspectionReportId}`, false);
  };

  public onEnter = (id: string) => {
    const input = document.getElementById(id);
    input!.focus();
  };

  public render() {
    const { hideButtonDesktop } = this.state;
    const {
      showRatingModal,
      showNotesModal,
      showPhotosModal,
      showAddAreasModal,
      showAddItemModal,
      showExitModal,
      setWarningModalState,
      showWarningModal,
      showPdfModal,
      successOnSave,
      setNeedsSaving,
      needsSaving,
      setLoadingState,
    } = this.props;
    let overflowStyle: any = { overflow: 'scroll' };
    if (showRatingModal || showNotesModal || showPhotosModal) {
      overflowStyle = { overflow: 'hidden' };
    } else {
      overflowStyle = { overflow: 'scroll' };
    }

    return (
      <>
        <TrackActivityComponent
          values={this.props.values}
          showWarningModal={setWarningModalState}
          setLoadingState={setLoadingState}
          setError={(error: boolean) => this.setState({ error })}
        />
        <div className="inspection-root-div" style={overflowStyle}>
          <div className="inspection-content-screen">
            {this.props.loading && <LoadingPage />}
            {this.state.blockingModal && (
              <BlockingOverlay>
                <BlockingPopupModal
                  message={this.state.blockingModal.message}
                />
              </BlockingOverlay>
            )}
            <Header
              needsSaving={needsSaving}
              hideButtonDesktop={hideButtonDesktop}
              openSearch={this.onOpenSearch}
            />
            <FirstForm
              toggleNeedsSaving={() => setNeedsSaving(true)}
              onEnter={this.onEnter}
              openSearch={this.onOpenSearch}
            />
            <SiteInformationForm
              toggleNeedsSaving={() => setNeedsSaving(true)}
              onEnter={this.onEnter}
            />
            <AreasForm toggleNeedsSaving={() => setNeedsSaving(true)} />
            <FooterBar
              setNeedsSaving={setNeedsSaving}
              onSaveCallback={() => {
                this.setState({ isNewModalAlreadyOpened: false });
              }}
            />
          </div>
          <LoadingModal />
          {successOnSave && <SuccessModal {...this.props} />}
          {/* <SuccessModal /> */}
          {showPhotosModal && <AddPhotosModal />}
          {showNotesModal && !this.state.showMenu && <NotesModal />}
          {showRatingModal && <SectionRatingModal />}
          {showAddAreasModal && <AddAreaModal />}
          {showAddItemModal && <AddItemModal />}
          {showPdfModal.status && <PdfModal />}
          {showWarningModal.status && (
            <ActionAlertModal
              title={showWarningModal.title}
              message={showWarningModal.message}
              onConfirm={() => {
                this.closeWarningModal();
                if (showWarningModal.onClose) {
                  showWarningModal.onClose();
                }
              }}
              // onDismiss={this.props.closeExitModal}
              isVisible={showWarningModal.status}
            />
          )}
          <ActionAlertModal
            title="Exit without saving?"
            message="Remember, that if you exit without saving, you will lose all progress."
            onConfirm={this.dealClose}
            onDismiss={this.props.closeExitModal}
            isVisible={showExitModal}
          />

          {!this.state.error &&
            ((this.state.isCityUser && this.state.isCityTemplate) ||
              !this.state.isCityTemplate) && (
              <ActionAlertModal
                title="Template"
                message="Do you want to edit or start a new draft based on the current template?"
                onConfirm={this.onClickDraft}
                confirmText="NEW DRAFT"
                onDismiss={() => {
                  this.setState({ isNewModalOpen: false });
                  this.props.setShowPopup(false);
                }}
                // onDismiss={() => this.props.setTemplateModal(false)}
                dismissText="EDIT TEMPLATE"
                // isVisible={this.props.showOpenTemplateModal}
                isVisible={this.state.isNewModalOpen}
              ></ActionAlertModal>
            )}

          {!this.state.error &&
            !this.state.isCityUser &&
            this.state.isCityTemplate &&
            !this.state.newForm && (
              <ActionAlertModal
                title="Template"
                message="This will crate a new draft based on your City template"
                onConfirm={this.onClickDraft}
                confirmText="OK"
                // isVisible={this.props.showOpenTemplateModal}
                isVisible={this.state.isNewModalOpen}
              ></ActionAlertModal>
            )}

          {!this.state.error &&
            !this.state.isCityUser &&
            this.state.isCityTemplate &&
            this.state.newForm && (
              <ActionAlertModal
                title="Success"
                message="The Bid Form was successfully saved as a City Template. A Draft copy of the template will now be opened for further edits."
                onConfirm={this.onClickDraft}
                confirmText="OK"
                // isVisible={this.props.showOpenTemplateModal}
                isVisible={this.state.isNewModalOpen}
              ></ActionAlertModal>
            )}
        </div>
        {this.state.showMenu && (
          <GenericModal
            isOpen={this.state.showMenu}
            showHeader={false}
            clickOutsideToClose={false}
            fullscreen={false}
            height="710px"
            width="600px"
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <InspectionMenuScreen
              {...this.props}
              showBackground={false}
              {...this.state.showMenu}
              doneAction={(state) => {
                this.setState({ ...this.state, showMenu: null });
                // setIsLoading(state);
              }}
            />
          </GenericModal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: IReduxState) => ({
  ...state.inspectForm,
  values: state.inspectForm,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...InspectFormActions }, dispatch);

const WithRouter = withRouter(NewInspectScreen);

const Connected = connect<IStateToProps, IDispatchToProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(WithRouter);

export { Connected as NewInspectScreen };
