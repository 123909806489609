import React from 'react';
import { Layout, HiddenLayout } from 'modules/BidInfoForm/components/layout';
import { TermsParagraph, Anchor } from 'modules/BidInfoForm/components/atoms';
import { romanize } from '../../../NewCustomizeTerms/utils';
import { HtmlSanitizer } from 'core/utils/htmlUtils';

export const TermsAndConditions = ({
  values,
  franchiseValues,
  isTermsChecked,
  setIsTermsChecked,
  isSS,
  pageChanged,
}: any) => {
  const [opened, setOpened] = React.useState(false);

  React.useEffect(() => {
    setOpened(false);
  }, [pageChanged]);

  const termsAndConditionsValues = values['TermsAndConditions'] || [];

  const stripHtml = (html) => {
    var tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const termsValues = values['Terms'] || [];

  let termsContent = [];

  const introduction = termsValues ? termsValues.slice(0, 1) : [];

  if (introduction && introduction.length > 0) {
    termsContent.push(introduction[0]['Value']);
  }

  termsValues.map((item, idx) => {
    if (item['Key'] == `Term${idx}`) {
      termsContent.push(
        `${romanize(idx)} ${!!item['Value'].trim() ? item['Value'] : 'N/A'}`
      );
    }
  });

  return (
    <Layout margin="0" width="100%" maxWidth="900px">
      <Layout padding="0">
        <Layout flexDirection="row">
          {/* <ContentInput padding="9px" paddingRight="0">
            <Checkbox
              borderColor="gray"
              name="agreed"
              checked={isTermsChecked}
              onChange={(...args) => {
                setIsTermsChecked(!isTermsChecked);
              }}
            />
          </ContentInput> */}
          <TermsParagraph fontSize="16px" textAlign="center">
            <Anchor
              onClick={() => setOpened(!opened)}
              data-cy="terms-and-conditions-link"
            >
              Terms &amp; Conditions
            </Anchor>
          </TermsParagraph>
        </Layout>

        <HiddenLayout opened={opened} hasSubtitle maxWidth="900px">
          <TermsParagraph
            textAlign="center"
            paddingBottom="15px"
            data-cy="terms-and-conditions-title"
          >
            Service Procurement Agreement
          </TermsParagraph>
          {/* <Paragraph
            textAlign="justify"
            data-cy="terms-and-conditions-content"
            style={{
              whiteSpace: 'pre-line'
            }}
            dangerouslySetInnerHTML={{
              __html: HtmlSanitizer.SanitizeHtml(
                termsContent
                  ? termsContent.join('<br />')
                  : ''
              ),
            }}
          ></Paragraph> */}
          {termsContent &&
            termsContent.map((paragraph, idx) => {
              return (
                <TermsParagraph
                  textAlign="justify"
                  data-cy={
                    idx == 0
                      ? 'terms-and-conditions-content-introduction'
                      : `terms-and-conditions-content-${idx}`
                  }
                  style={{
                    paddingTop: 0,
                    marginTop: 0,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: HtmlSanitizer.SanitizeHtml(
                      paragraph.replace(/[\r\n]+/g, '<div></div>')
                    ),
                  }}
                ></TermsParagraph>
              );
            })}
        </HiddenLayout>
      </Layout>
    </Layout>
  );
};
