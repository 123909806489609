import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ContentInput, Checkbox } from 'core/components';
import {
  toTitleCase,
  formatNumberByMask,
  getAlphabetLetter,
  formatPrice,
} from 'core/utils';
import { moneyMaskParams } from 'core/utils/masks';
import { TermsAndConditions } from '../components/organisms';

import { optionsPlans } from 'modules/shared/bidDefaultValues';
import styled from 'styled-components';

import {
  Layout,
  ResponsiveLayout,
  ResponsiveBoxes,
  OrangeSection,
  WhiteSection,
  RoundSubSection,
  SubSectionHeader,
  SubSection,
  GraySection,
} from '../../../components/layout';

import { GenericAccordion, RadioButton } from 'core/components';

import { Paragraph, Input, SectionParagraph } from '../../../components/atoms';

import {
  H1BoldItalic,
  H3Bold,
  BannerServicesOfferingsPage2,
  HorizontalList,
  HorizontalListItem,
  HorizontalListItemSeparator,
  HexagonItem,
  ServicesText,
  SectionTitle,
  IntroductionTitle,
  H3SemiBold,
  HeaderBase,
  SpecialtyServiceDescription,
  CheckBoxHolder,
  DisclaimerParagraph,
  H3RegularItalic,
  ProtectionCard,
  ProtectionCardHeader,
  ProtectionCardBody,
  ProtectionCardBodyTitle,
  ProtectionCardBodyContrast,
  ProtectionCardsHolder,
  ProtectionCardsMessage,
  ProtectionCardsDescription,
  ProtectionCardsContactRepresentative,
  ProtectionDisinfetionServiceOptionsWrapper,
  ProtectionDisinfetionServiceOptionsHeaderRow,
  ProtectionDisinfetionServiceOptionsHeaderItem,
  ProtectionDisinfetionServiceOptionsRow,
  ProtectionDisinfetionServiceOptionsItem,
  AccordionHeader,
  List,
  ListItem,
} from '../../../styles';
import {
  MonthlyServiceQuoteOptions,
  MonthlyServiceQuoteOptionsMobile,
  DeclineMonthlyServiceOption,
} from '../../../../../core/components/ProtectionMonthlyServiceQuote';

const Page2 = (props, ref) => {
  const {
    data,
    franchiseData,
    parsedData,
    updateData,
    selectedValues,
    selectedItem,
  } = props;
  const values = data || {};
  const franchiseValues = franchiseData || [];

  const [equalsOrBiggerThan768, setEqualsOrBiggerThan768] = React.useState(
    false
  );
  const [calculatedWidth, setCalculatedWidth] = React.useState(500);
  const hasProtectionPackages =
    parsedData &&
    (parsedData.protectionPackages.length > 0 ||
      parsedData.secondProtectionPackages.length > 0) &&
    !parsedData.smartClean;

  const hasMonthlyFrequency =
    parsedData && parsedData.frequencyService === 'monthly';

  const [equals768, setEqual768] = React.useState(false);
  const [biggerThan796, setBiggerThan796] = React.useState(false);
  const [biggerThan768, setBiggerThan768] = React.useState(false);
  const [biggerThan600, setBiggerThan600] = React.useState(false);
  const [minorThan768, setMinorThan768] = React.useState(false);
  const [minorThan450, setMinorThan450] = React.useState(false);
  const [minorThan496, setMinorThan496] = React.useState(false);

  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [matrixScaleRatio, setMatrixScaleRatio] = useState(1);
  const [plansScaleRatio, setPlansScaleRatio] = useState(1);
  const [headerScaleRatio, setHeaderScaleRatio] = useState(1);

  const setBreakpoints = () => {
    setEqualsOrBiggerThan768(window.innerWidth >= 768);
    setBiggerThan796(window.innerWidth >= 796);
    setBiggerThan768(window.innerWidth >= 769);
    setBiggerThan600(window.innerWidth >= 600);
    setMinorThan768(window.innerWidth < 768);
    setMinorThan450(window.innerWidth < 450);
    setMinorThan496(window.innerWidth < 496);
    setEqual768(window.innerWidth == 768);
    // setCalculatedWidth(window.innerWidth - 20);

    if (window.innerWidth >= 768) {
      setMatrixScaleRatio(1);
      setPlansScaleRatio(1);
    } else {
      let perpx = 3.1 / 1920;
      let matrixRatio = perpx * window.screen.width;
      setMatrixScaleRatio(matrixRatio > 1 ? 1 : matrixRatio);

      let perpxPlans = 2.801 / 1920;
      let plansRatio = perpxPlans * window.screen.width;
      setPlansScaleRatio(plansRatio > 0.7 ? 0.7 : plansRatio);

      let perpxHeader = 2.801 / 1920;
      let headerRatio = perpxHeader * window.screen.width;
      setHeaderScaleRatio(headerRatio > 1 ? 1 : headerRatio);
    }
  };

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  const biggerThan830 = useMediaQuery({
    query: '(min-device-width: 830px)',
  });

  const [additionalQuotes, setAdditionalQuotes] = useState(
    values['AdditionalQuotes']
      ? values['AdditionalQuotes']
          .filter((i) => i['IsSelected'] == true)
          .map((item) => item['BidInformationAdditionalQuotesId'])
      : []
  );

  const [dayPorter, setDayPorter] = React.useState(
    values['IncludeDayPorterInPrice'] &&
      (values['CostOfDayPorter'] > 0 || values['DayPorterPriceOverride'] > 0)
  );

  useEffect(() => {
    if (
      values['IncludeDayPorterInPrice'] &&
      (values['CostOfDayPorter'] > 0 || values['DayPorterPriceOverride'] > 0)
    ) {
      setDayPorter(true);
    }
  }, [values]);

  useEffect(() => {
    // if (!additionalQuotes || additionalQuotes.length === 0) {
    //   setAdditionalQuotes(
    //     values['AdditionalQuotes']
    //       ? values['AdditionalQuotes']
    //           .filter(i => i['IsSelected'] == true || i['IsSelected'] == 'true')
    //           .map(item => item['BidInformationAdditionalQuotesId'])
    //       : []
    //   );
    // }
  }, [values, data, additionalQuotes]);

  const [isDirty, setIsDirty] = useState(false);

  const onCheckboxClick = (item, name, value, isChecked: boolean) => {
    let arr = [];
    if (
      isChecked &&
      additionalQuotes.includes(item['BidInformationAdditionalQuotesId']) !=
        true
    ) {
      arr = [...additionalQuotes, value];
      setAdditionalQuotes(arr);
      setIsDirty(true);
    } else {
      arr = [...additionalQuotes];
      arr.splice(arr.indexOf(value), 1);
      setAdditionalQuotes(arr);
      setIsDirty(true);
    }
    // updateData("AdditionalQuotes", arr);
  };

  const [selectedProtection, setSelectedProtection] = React.useState();

  React.useImperativeHandle(ref, () => ({
    getData: () => ({
      additionalQuotes,
      isDirty,
      data,
      dayPorter,
      selectedFrequency:
        selectedFrequency == 1
          ? false
          : selectedFrequency == 2
          ? true
          : selectedFrequency == 3
          ? -1
          : null,
      shouldSelectOption:
        parsedData &&
        (parsedData.protectionPackages.length > 0 ||
          parsedData.secondProtectionPackages.length > 0) &&
        !parsedData.smartClean,
      hasProtectionPackages:
        parsedData && selectedFrequency == 1
          ? parsedData.protectionPackages.length > 0 &&
            !parsedData.protectionIncludedInPrice
          : selectedFrequency == 2
          ? parsedData.secondProtectionPackages.length > 0 &&
            !parsedData.secondProtectionIncludedInPrice
          : null,
      selectedProtection: selectedProtection,
    }),
    clearIsDirty: () => {
      setIsDirty(false);
    },
  }));

  const getDaysPorterDays = () => {
    let ret = '';
    if (values['DaysPotterMonday']) {
      ret += 'Monday, ';
    }
    if (values['DaysPotterTuesday']) {
      ret += 'Tuesday, ';
    }
    if (values['DaysPotterWednesday']) {
      ret += 'Wednesday, ';
    }
    if (values['DaysPotterThursday']) {
      ret += 'Thursday, ';
    }
    if (values['DaysPotterFriday']) {
      ret += 'Friday, ';
    }
    if (values['DaysPotterSaturday']) {
      ret += 'Saturday, ';
    }
    if (values['DaysPotterSunday']) {
      ret += 'Sunday, ';
    }
    return ret.substr(0, ret.length - 2);
  };

  const hasAdditionalQuotes =
    values['AdditionalQuotes'] &&
    values['AdditionalQuotes'].length > 0 &&
    values['AdditionalQuotes'].filter(
      (i) =>
        i['Total'] > 0 ||
        i['TotalOverride'] > 0 ||
        i['UponRequest'] ||
        i['UnitPrice'] > 0
    ).length > 0;
  const plansColors = new Map();
  const plansDictionary = new Map();
  let packageCounter = 0;
  if (parsedData) {
    for (var item of optionsPlans) {
      const map = new Map();
      parsedData.protectionPackages.map((pkg) => {
        pkg.plans.map((p) => {
          if (
            p.plan &&
            item.plan &&
            p.plan.toLowerCase() == item.plan.toLowerCase()
          ) {
            const existent = map.get(pkg.index) || [];
            existent.push(p.frequency);
            map.set(pkg.index, existent);
          }
        });
      });
      parsedData.secondProtectionPackages.map((pkg) => {
        pkg.plans.map((p) => {
          if (
            p.plan &&
            item.plan &&
            p.plan.toLowerCase() == item.plan.toLowerCase()
          ) {
            const existent = map.get(pkg.index) || [];
            existent.push(p.frequency);
            map.set(pkg.index, existent);
          }
        });
      });
      plansDictionary.set(item.plan, map);
      plansColors.set(item.plan, item.backgroundColor);
    }
    packageCounter =
      parsedData.protectionPackages.length +
      parsedData.secondProtectionPackages.length;
  }
  const hasSecondFrequency =
    parsedData &&
    ((parsedData.addFrequency && parsedData.secondFrequency &&
      parsedData.secondFrequency.selecteds.length > 0) ||
      parsedData.monthlyAddFrequency && 
      parsedData.secondCleaning > 0);

  const [selectedFrequency, setSelectedFrequency] = useState(
    selectedValues.selectedFrequency == false
      ? 1
      : selectedValues.selectedFrequency == true
      ? 2
      : null
  );

  const frequenciesOptions = () => {
    const ret = [];
    ret.push({
      value: 1,
      render: (
        <MonthlyServiceQuoteOptions
          biggerThan768={equalsOrBiggerThan768}
          hasDesinfection={
            hasMonthlyFrequency
              ? parsedData.monthlyDisinfection
              : parsedData.disinfection
          }
          style={{
            backgroundColor: selectedFrequency == 1 ? '#216EAB' : '#e6e6e6',
            borderRadius: '10px',
            width: '100%',
          }}
          color={selectedFrequency == 1 ? 'white' : '#515151'}
          hasBeta={values['OwnerHasEcoInvoice']}
          plusApplicableTax={franchiseValues.SalesTax}
          calculatedTotal={parsedData.calculatedPrice}
          packageIncluded={parsedData.protectionIncludedInPrice}
          selectedProtection={selectedFrequency == 1 ? selectedProtection : null}
          setSelectedProtection={setSelectedProtection}
          unselected={
            !!selectedFrequency &&
            selectedFrequency > 0 &&
            selectedFrequency != 1
          }
          isHtml
          selected={selectedFrequency == 1}
          selectFrequency={()=>setSelectedFrequency(1)}
          items={[
            {
              title: `${parsedData.smartClean ? 'SmartClean' : 'Quote 1'}: ${
                parsedData.quoteTitle ||
                `${
                  hasMonthlyFrequency
                    ? parsedData.cleaning
                    : parsedData.smartClean
                    ? parsedData.frequency.selecteds.length +
                      ((parsedData.secondFrequency &&
                        parsedData.secondFrequency.selecteds.length) ||
                        0)
                    : parsedData.frequency.selecteds.length
                }x ${toTitleCase(parsedData.frequencyService)}`
              }`,
              frequency: `${
                hasMonthlyFrequency
                  ? parsedData.cleaning
                  : parsedData.smartClean
                  ? parsedData.frequency.selecteds.length +
                    ((parsedData.secondFrequency &&
                      parsedData.secondFrequency.selecteds.length) ||
                      0)
                  : parsedData.frequency.selecteds.length
              }x ${toTitleCase(parsedData.frequencyService)}`,
              hasDisinfection: parsedData.protectionIncludedInPrice,
              daysServiced: hasMonthlyFrequency
                ? 'MONTHLY'
                : data['DaysCleanedListTextCompactText'],
              timeWindow: hasMonthlyFrequency
                ? parsedData.timeWindow
                : parsedData.frequency.timeWindow,
              services: [
                {
                  service: 'Customized Cleaning Service',
                  value: parsedData.protectionIncludedInPrice
                    ? 'Included'
                    : formatPrice(
                        parsedData.bidOverrideBasePrice ||
                          parsedData.bidCalculatedBasePrice,
                        values['OwnerHasEcoInvoice']
                      ),
                },
                parsedData.includeInPrice &&
                  (parsedData.porterOverride > 0 ||
                    parsedData.monthlyPorterPrice > 0) && {
                    service: 'Day Porter Included',
                    value: formatPrice(
                      parsedData.porterOverride || parsedData.monthlyPorterPrice
                    ),
                  },
                parsedData.costSupplies && {
                  service: 'Supplies Included',
                  value: formatPrice(parsedData.costSupplies),
                },
              ],
              protectionOptions:
                parsedData.protectionPackages.length > 0
                  ? [
                      ...parsedData.protectionPackages.map((item) => ({
                        index: item.index,
                        value: parsedData.protectionIncludedInPrice
                          ? 'Included'
                          : formatPrice(
                              item.totalOverride
                                ? item.totalOverride
                                : item.total
                            ),
                        plans: item.plans.map((p) => ({
                          plan: p.plan,
                          frequency: p.frequency,
                          color:
                            optionsPlans.filter((i) => i.plan == p.plan)
                              .length > 0
                              ? optionsPlans.filter((i) => i.plan == p.plan)[0]
                                  .backgroundColor
                              : null,
                        })),
                      })),
                      ...(!parsedData.protectionIncludedInPrice
                        ? [
                            {
                              index: -1,
                              value: 'Declined',
                            },
                          ]
                        : []),
                    ]
                  : [],
            },
          ]}
        />
      ),
    });
    if (hasSecondFrequency) {
      ret.push({
        value: 2,
        render: (
          <MonthlyServiceQuoteOptions
            hasDesinfection={
              hasMonthlyFrequency
                ? parsedData.secondMonthlyDisinfection
                : parsedData.secondDisinfection
            }
            biggerThan768={equalsOrBiggerThan768}
            style={{
              backgroundColor: selectedFrequency == 2 ? '#216EAB' : '#e6e6e6',
              borderRadius: '10px',
              width: '100%',
            }}
            isHtml
            selected={selectedFrequency == 2}
            selectedProtection={selectedFrequency == 2 ? selectedProtection : null}
            setSelectedProtection={setSelectedProtection}
            color={selectedFrequency == 2 ? 'white' : '#515151'}
            hasBeta={values['OwnerHasEcoInvoice']}
            unselected={
              !!selectedFrequency &&
              selectedFrequency > 0 &&
              selectedFrequency != 2
            }
            selectFrequency={()=>setSelectedFrequency(2)}
            plusApplicableTax={franchiseValues.SalesTax}
            calculatedTotal={parsedData.secondCalculatedPrice}
            packageIncluded={parsedData.secondProtectionIncludedInPrice}
            items={[
              {
                title: `Quote 2: ${
                  parsedData.secondQuoteTitle ||
                  `${
                    hasMonthlyFrequency
                      ? parsedData.secondCleaning
                      : parsedData.secondFrequency.selecteds.length
                  }x ${toTitleCase(parsedData.frequencyService)}`
                }`,
                frequency: `${
                  hasMonthlyFrequency
                    ? parsedData.secondCleaning
                    : parsedData.secondFrequency.selecteds.length
                }x ${toTitleCase(parsedData.frequencyService)}`,
                hasDisinfection: parsedData.secondProtectionIncludedInPrice,
                daysServiced: hasMonthlyFrequency
                  ? 'MONTHLY'
                  : data['SecondDaysCleanedListTextCompactText'],
                timeWindow: hasMonthlyFrequency
                  ? parsedData.secondTimeWindow
                  : parsedData.secondFrequency.timeWindow,
                services: [
                  {
                    service: 'Customized Cleaning Service',
                    value: parsedData.secondProtectionIncludedInPrice
                      ? 'Included'
                      : formatPrice(
                          parsedData.secondBidOverrideBasePrice ||
                            parsedData.secondBidCalculatedBasePrice,
                          values['OwnerHasEcoInvoice']
                        ),
                  },
                  parsedData.includeInPrice &&
                    parsedData.monthlyPorterPrice > 0 && {
                      service: 'Day Porter Included',
                      value: formatPrice(
                        parsedData.porterOverride ||
                          parsedData.monthlyPorterPrice
                      ),
                    },
                  parsedData.costSupplies && {
                    service: 'Supplies Included',
                    value: formatPrice(parsedData.costSupplies),
                  },
                ],
                protectionOptions:
                  parsedData.secondProtectionPackages.length > 0
                    ? [
                        ...parsedData.secondProtectionPackages.map((item) => ({
                          index: item.index,
                          value: parsedData.secondProtectionIncludedInPrice
                            ? 'Included'
                            : formatPrice(
                                item.totalOverride
                                  ? item.totalOverride
                                  : item.total
                              ),
                          plans: item.plans.map((p) => ({
                            plan: p.plan,
                            frequency: p.frequency,
                            color:
                              optionsPlans.filter((i) => i.plan == p.plan)
                                .length > 0
                                ? optionsPlans.filter(
                                    (i) => i.plan == p.plan
                                  )[0].backgroundColor
                                : null,
                          })),
                        })),
                        ...(!parsedData.secondProtectionIncludedInPrice
                          ? [
                              {
                                index: -1,
                                value: 'Declined',
                              },
                            ]
                          : []),
                      ]
                    : [],
              },
            ]}
          />
        ),
      });
    }

    ret.push({
      value: 3,
      render: (
        <div style={{ position: 'relative', width: '100%' }}>
          {!!selectedFrequency &&
            selectedFrequency > 0 &&
            selectedFrequency != 3 && (
              <div
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  backgroundColor: 'white',
                  opacity: 0.4,
                  width: '100%',
                  height: '100%',
                }}
              ></div>
            )}
          <DeclineMonthlyServiceOption
            style={{
              backgroundColor: selectedFrequency == 3 ? '#216EAB' : '#e6e6e6',
              color: selectedFrequency == 3 ? 'white' : '#515151',
            }}
            data-cy="decline-option"
          >
            I decline a Monthly Service option.
          </DeclineMonthlyServiceOption>
        </div>
      ),
    });

    return ret;
  };

  return (
    <Layout margin="0">
      {!values['IsSS'] && (
        <WhiteSection padding="30px 0 20px 0">
          <IntroductionTitle maxFontSize="30px" textAlign="center">
            ANAGO PROTECTION+ DISINFECTION&trade; PLANS
          </IntroductionTitle>
        </WhiteSection>
      )}
      <div>
        <BannerServicesOfferingsPage2>
          <H1BoldItalic
            fontSize="36px"
            textAlign="left"
            color="#fff"
            lineHeight={biggerThan768 ? '40px' : 'inherit'}
            style={{
              fontSize: '36px',
              paddingLeft: 0,
              transform: equalsOrBiggerThan768
                ? null
                : `scale(${headerScaleRatio})`,
            }}
          >
            <HorizontalList>
              <HorizontalListItem>DISINFECT</HorizontalListItem>
              <HorizontalListItemSeparator>&#8226;</HorizontalListItemSeparator>
              <HorizontalListItem>PROTECT</HorizontalListItem>
              <HorizontalListItemSeparator>&#8226;</HorizontalListItemSeparator>
              <HorizontalListItem>MAINTAIN</HorizontalListItem>
            </HorizontalList>
            <span
              style={{
                fontSize: minorThan450 ? '21px' : '22px',
                fontFamily: 'OpenSans-Regular',
                textShadow: 'none',
                letterSpacing: '0px',
              }}
            >
              with Anago Protection+ Disinfection&trade;
            </span>
          </H1BoldItalic>
        </BannerServicesOfferingsPage2>
      </div>
      <Layout
        width="100%"
        backgroundColor="white"
        paddingBottom="0"
        justifyContent="start"
      >
        <WhiteSection
          maxWidth={equalsOrBiggerThan768 ? '1440px' : 'auto'}
          margin="auto !important"
          width="100%"
        >
          <SubSection maxWidth={equalsOrBiggerThan768 ? '900px' : 'auto'}>
            <ResponsiveLayout
              style={{
                display: 'flex',
                flexDirection: equalsOrBiggerThan768 ? 'row' : 'column',
                flexWrap: equalsOrBiggerThan768 ? 'wrap' : 'no-wrap',
              }}
              paddingBottom="20px"
            >
              <ContentInput flex justify="center" paddingBottom="0">
                <span
                  style={{
                    textAlign: 'center',
                    fontSize: '14px',
                    marginBottom: '-20px',
                    maxWidth: '700px',
                    padding: '0px 20px',
                  }}
                >
                  Keep your employees and guests safe from viruses and bacteria
                  with our signature 3-tiered disinfection program, designed to
                  meet your needs and backed by our Anago Total Satisfaction
                  Guarantee.
                </span>
              </ContentInput>
            </ResponsiveLayout>
          </SubSection>
        </WhiteSection>
      </Layout>
      <WhiteSection width="auto" margin="0" padding="0px 20px 20px 20px">
        <ProtectionCardsHolder
          style={
            equalsOrBiggerThan768
              ? {}
              : {
                  transform: `scale(${plansScaleRatio})`,
                  marginTop: minorThan450 ? '-50px' : null,
                  marginBottom: minorThan450 ? '-30px' : null,
                }
          }
        >
          <ProtectionCard>
            <ProtectionCardHeader style={{ backgroundColor: '#216EAB' }}>
              <span
                style={{
                  opacity: 0.4,
                  textShadow: '0px 0px .5px #216EAB',
                }}
              >
                BLUE
              </span>
            </ProtectionCardHeader>
            <ProtectionCardBody style={{ backgroundColor: 'white' }}>
              <ProtectionCardBodyTitle>
                A Beyond Basic Clean To Ensure Safety
              </ProtectionCardBodyTitle>
              <div style={{ fontSize: '15px', paddingRight: '10px' }}>
                Defends against the spread of illness, killing 99.9% of germs on
                most high-frequency touch surfaces such as doorknobs, light
                switches, and handles.
              </div>
            </ProtectionCardBody>
          </ProtectionCard>

          <ProtectionCard
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
              zIndex: 1,
              transform: 'scale(1.07)',
            }}
          >
            <ProtectionCardHeader style={{ backgroundColor: '#F7A11D' }}>
              <span
                style={{
                  color: '#C36F04',
                  opacity: 1,
                  textShadow: '0px 0px .8px #C36F04',
                }}
              >
                ORANGE
              </span>
            </ProtectionCardHeader>
            <ProtectionCardBody style={{ backgroundColor: 'white' }}>
              <ProtectionCardBodyContrast
                style={{ backgroundColor: '#FDC57B', color: '#000000' }}
              >
                <span>OUR MOST POPULAR PLAN!</span>
              </ProtectionCardBodyContrast>
              <ProtectionCardBodyTitle>
                Includes Everything in the BLUE plan{' '}
                <span style={{ color: '#F7A11D' }}>AND</span>…
              </ProtectionCardBodyTitle>
              <div style={{ fontSize: '15px', paddingRight: '10px' }}>
                Ensures common areas plus all personal spaces are disinfected to
                keep illness at bay – and those sick days away.
              </div>
            </ProtectionCardBody>
          </ProtectionCard>

          <ProtectionCard>
            <ProtectionCardHeader
              style={{
                backgroundColor: '#959595',
              }}
            >
              <span
                style={{
                  color: '#454D57',
                  opacity: 1,
                  textShadow: '0px 0px 1px #454D57',
                }}
              >
                PLATINUM
              </span>
            </ProtectionCardHeader>
            <ProtectionCardBody
              style={{
                backgroundColor: 'white',
                paddingRight: biggerThan600 ? '28px' : '15px',
              }}
            >
              <ProtectionCardBodyContrast
                style={{ backgroundColor: '#000', color: '#fff' }}
              >
                <span>THE ULTIMATE CLEAN</span>
              </ProtectionCardBodyContrast>
              <ProtectionCardBodyTitle>
                Includes Everything in the ORANGE plan AND…
              </ProtectionCardBodyTitle>
              <div style={{ fontSize: '15px', paddingRight: '10px' }}>
                Provides an advanced clinical clean utilizing Electrostatic
                technology. It’s your lifeline to staying open when you
                absolutely must be germ free.
              </div>
            </ProtectionCardBody>
          </ProtectionCard>
        </ProtectionCardsHolder>
        <ProtectionCardsMessage>
          All three tiers of Protection+ utilize EPA-Grade disinfectants and
          Microfiber Technology and can be performed as a single or recurring
          service.
        </ProtectionCardsMessage>

        <ProtectionCardsDescription>
          <strong>What is Protection+ Disinfection™?</strong>
          <br />
          Anago Protection+ Disinfection™ services ensure your facility remains
          bacteria- and virus-free. Protection+ offers three levels of
          disinfection utilizing Anago’s signature 10-step disinfection process
          to keep your facility clean and safe for employees and guests.{' '}
          <i>It’s peace of mind.</i> Protection+ badges are provided to display
          at your facility’s doors, entryways, bathroom mirrors, and reception
          desks help guests know they are safe and protected.
          <br />
          <br />•{' '}
          <span
            style={{ color: '#216EAB', fontWeight: 'bold', fontSize: '15px' }}
          >
            Protection+ BLUE
          </span>{' '}
          pays extra attention to surfaces like door handles, coffee pots, and
          railings to{' '}
          <strong style={{ color: '#216EAB' }}>
            kill 99.9% of germs on the highest-touch surfaces
          </strong>
          . Certified experts give you a detailed, beyond-basic cleaning where
          it matters most.
          <br />
          <br />•{' '}
          <span
            style={{ color: '#F7A11D', fontWeight: 'bold', fontSize: '15px' }}
          >
            Protection+ ORANGE
          </span>{' '}
          is where disinfection gets personal. You get all the benefits of
          Protection+ BLUE, plus professional disinfecting that{' '}
          <strong style={{ color: '#F7A11D' }}>
            eliminates 99.9% of germs at individual workstations
          </strong>
          . Every phone, every computer, every mouse, and every keyboard in the
          facility is detailed.
          <br />
          <br />•{' '}
          <span
            style={{
              color: '#454D57',
              fontWeight: 'bolder',
              textShadow: '0px 0px 1px #454D57',
              fontSize: '15px',
            }}
          >
            Protection+ PLATINUM
          </span>{' '}
          is the ultimate clean! First, certified professionals clean your
          office at the Protection+ ORANGE level. Then they introduce{' '}
          <strong
            style={{
              color: '#454D57',
              fontWeight: 'bolder',
              textShadow: '0px 0px 1px #454D57',
            }}
          >
            Electrostatic technology for an advanced clinical clean
          </strong>{' '}
          to kill 99.9% bacteria and viruses like COVID-19, disinfecting even
          the hardest-to-reach areas.
        </ProtectionCardsDescription>

        {!hasProtectionPackages && (
          <ProtectionCardsContactRepresentative>
            Contact your Anago Representative for more information
            <br />
            about adding Protection+ services.
          </ProtectionCardsContactRepresentative>
        )}
      </WhiteSection>
      {hasProtectionPackages && (
        <GraySection width="auto" margin="0" padding="0px 20px 150px 20px">
          <RoundSubSection
            marginTop="30px"
            width="100%"
            backgroundColor="#fff"
            maxWidth={equalsOrBiggerThan768 ? '900px' : 'auto'}
          >
            <SubSectionHeader
              alignItems={biggerThan768 ? 'start' : 'center'}
              backgroundColor="#216eab"
              color="#fff"
              padding={'20px 0 20px 0'}
            >
              <SectionTitle maxFontSize="20px" color="#fff">
                Protection+ Disinfection Service Options
              </SectionTitle>
            </SubSectionHeader>
            <Layout width="100%" margin="0">
              <SectionParagraph
                style={{
                  fontFamily: 'OpenSans-Bold !important',
                  fontSize: '14px !important',
                }}
                fontSize="14px"
              >
                Monthly Service Package Options(s)
              </SectionParagraph>

              <SectionParagraph
                style={{
                  fontFamily: 'OpenSans-Regular !important',
                  fontSize: '14px !important',
                }}
                fontSize="14px"
              >
                The chart below details Package offerings that meet your
                disinfection and frequency needs. Use this chart to identify
                your preferred package, and mark your preference in the 'Monthly
                Service Pricing' section on the following page.
              </SectionParagraph>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <ProtectionDisinfetionServiceOptionsWrapper
                  style={{
                    margin: equalsOrBiggerThan768 ? '40px' : '15px',
                    width: '100%',
                    transform: equalsOrBiggerThan768
                      ? 'scale(1)'
                      : `scale(${matrixScaleRatio})`,
                    minWidth:
                      matrixScaleRatio < 0.8
                        ? packageCounter == 1
                          ? '100%'
                          : calculatedWidth
                        : 'auto',
                    maxWidth: 100 + 200 * packageCounter,
                    marginTop:
                      matrixScaleRatio > 0.65
                        ? null
                        : `-${40 * matrixScaleRatio}px`,
                    marginBottom:
                      matrixScaleRatio > 0.65
                        ? null
                        : `-${20 * matrixScaleRatio}px`,
                  }}
                >
                  <ProtectionDisinfetionServiceOptionsHeaderRow
                    style={{
                      gridTemplateColumns: `100px repeat(auto-fill, minmax(${
                        75 / packageCounter
                      }%, 2fr))`,
                      maxWidth:
                        packageCounter > 1
                          ? '100%'
                          : minorThan450
                          ? '90%'
                          : minorThan496
                          ? '100%'
                          : '90%',
                    }}
                  >
                    {parsedData &&
                      parsedData.protectionPackages.map((item) => (
                        <ProtectionDisinfetionServiceOptionsHeaderItem>
                          <span>Package {getAlphabetLetter(item.index)}</span>
                        </ProtectionDisinfetionServiceOptionsHeaderItem>
                      ))}
                    {parsedData &&
                      parsedData.secondProtectionPackages.map((item) => (
                        <ProtectionDisinfetionServiceOptionsHeaderItem>
                          <span>Package {getAlphabetLetter(item.index)}</span>
                        </ProtectionDisinfetionServiceOptionsHeaderItem>
                      ))}
                  </ProtectionDisinfetionServiceOptionsHeaderRow>

                  {Array.from(plansDictionary.entries()).map(([key, value]) => (
                    <ProtectionDisinfetionServiceOptionsRow
                      style={{
                        gridTemplateColumns: `100px repeat(auto-fill, minmax(${
                          75 / packageCounter
                        }%, 2fr))`,
                        maxWidth:
                          packageCounter > 1
                            ? '100%'
                            : minorThan450
                            ? '90%'
                            : minorThan496
                            ? '100%'
                            : '90%',
                        // minWidth: `${200 + packageCounter * 150}px`,
                      }}
                    >
                      <ProtectionDisinfetionServiceOptionsItem
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          backgroundColor: plansColors.get(key),
                          justifyContent: 'flex-start',
                          padding: 0,
                          paddingLeft: '10px',
                          margin: 'auto 0',
                        }}
                      >
                        {key}
                      </ProtectionDisinfetionServiceOptionsItem>

                      {[...Array(packageCounter)].map((_, i) => {
                        if (value.get(i)) {
                          return (
                            <ProtectionDisinfetionServiceOptionsItem
                              style={{ paddingLeft: 10, paddingRight: 10 }}
                            >
                              {value.get(i)}
                            </ProtectionDisinfetionServiceOptionsItem>
                          );
                        }
                        return (
                          <ProtectionDisinfetionServiceOptionsItem
                            style={{ fontWeight: 'bolder', fontSize: '120%' }}
                          >
                            -
                          </ProtectionDisinfetionServiceOptionsItem>
                        );
                      })}
                    </ProtectionDisinfetionServiceOptionsRow>
                  ))}
                </ProtectionDisinfetionServiceOptionsWrapper>
              </div>
            </Layout>
          </RoundSubSection>

          <RoundSubSection
            marginTop="30px"
            width="100%"
            backgroundColor="#fff"
            maxWidth={equalsOrBiggerThan768 ? '900px' : 'auto'}
          >
            <SubSectionHeader
              alignItems={biggerThan768 ? 'start' : 'center'}
              backgroundColor="#F7A11D"
              color="#fff"
              padding={'20px 0 20px 0'}
            >
              <SectionTitle maxFontSize="20px" color="#fff">
                Protection+ Specifications
              </SectionTitle>
            </SubSectionHeader>
            <Layout width="100%" margin="0">
              <Layout margin="15px" marginRight="17px">
                <SectionParagraph
                  style={{
                    fontFamily: 'OpenSans-Regular',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#515151',
                    fontStyle: 'italic',
                    margin: 0,
                  }}
                >
                  {parsedData && parsedData.protectionSpecificationSubtitle}
                </SectionParagraph>

                {values['FrequenciesProtection'] &&
                  values['FrequenciesProtection'].map((item) => {
                    return (
                      <GenericAccordion
                        headerStyle={{
                          backgroundColor: 'transparent',
                          borderRadius: '10px',
                          padding: '10px',
                        }}
                        containerStyle={{
                          marginTop: '15px',
                          borderRadius: '10px',
                          border: 'solid 2px gray',
                        }}
                        contentStyle={{
                          paddingRight: '30px',
                        }}
                        renderHeader={() => (
                          <AccordionHeader
                            maxFontSize="22px"
                            width="100%"
                            textAlign={'left'}
                            textTransform="capitalize"
                            color={
                              optionsPlans.filter(
                                (i) =>
                                  i.plan.toLowerCase() ===
                                  (
                                    item['Title'] || item['KeyString']
                                  ).toLowerCase()
                              )[0].backgroundColor
                            }
                            style={{
                              minHeight: !optionsPlans.filter(
                                (i) =>
                                  i.plan.toLowerCase() ===
                                  (
                                    item['Title'] || item['KeyString']
                                  ).toLowerCase()
                              )[0].subtitle
                                ? '47px'
                                : 'auto',
                              paddingRight: '30px',
                            }}
                          >
                            Protection+ {item['Title'] || item['KeyString']}
                            <span>
                              {/* &#8203; */}
                              {
                                optionsPlans.filter(
                                  (i) =>
                                    i.plan.toLowerCase() ===
                                    (
                                      item['Title'] || item['KeyString']
                                    ).toLowerCase()
                                )[0].subtitle
                              }
                            </span>
                          </AccordionHeader>
                        )}
                      >
                        <Layout>
                          <List
                            fontSize={biggerThan768 ? '14px' : '12px'}
                            listSpacing="10px"
                          >
                            {item['CleaningAreas'].map((ca, index) => (
                              <ListItem prefix={`${index + 1}.`} width="100%">
                                {ca['Title']}
                              </ListItem>
                            ))}
                          </List>
                        </Layout>
                      </GenericAccordion>
                    );
                  })}
              </Layout>
            </Layout>
          </RoundSubSection>

          <RoundSubSection
            marginTop="30px"
            width="100%"
            backgroundColor="#fff"
            maxWidth={equalsOrBiggerThan768 ? '900px' : 'auto'}
          >
            <SubSectionHeader
              alignItems={biggerThan768 ? 'start' : 'center'}
              backgroundColor="#F7A11D"
              color="#fff"
              padding={'20px 0 20px 0'}
            >
              <SectionTitle maxFontSize="20px" color="#fff">
                MONTHLY SERVICE QUOTE(S)
              </SectionTitle>
            </SubSectionHeader>
            <Layout width="100%" margin="0">
              <SectionParagraph
                style={{
                  fontFamily: 'OpenSans-Bold !important',
                  fontSize: '14px !important',
                }}
                fontSize="14px"
              >
                Please select your monthly service plan. You will have a chance
                to review and confirm your selection before submitting your
                agreement.
              </SectionParagraph>
              <Layout margin="-30px 15px 15px 15px">
                {parsedData && (
                  <RadioButton
                    value={selectedFrequency}
                    onChange={(name, value) => {
                      // updateData("SelectedFrequency", value);
                      if(value !== selectedFrequency) {
                        setSelectedFrequency(value);
                        setSelectedProtection(null);
                        setIsDirty(true);
                      }
                    }}
                    containerStyle={{
                      width: '100%',
                    }}
                    contentStyle={{
                      flexWrap: 'wrap',
                      paddingTop: equalsOrBiggerThan768 ? '25px' : '10px',
                      width: '100%',
                    }}
                    itemStyle={{
                      width: '100%',
                      marginTop: '15px',
                      paddingRight: '0',
                      alignItems: equalsOrBiggerThan768
                        ? 'center'
                        : 'flex-start',
                    }}
                    radioStyle={{
                      alignSelf: 'flex-start',
                      marginTop: '0',
                    }}
                    name="frequency"
                    options={frequenciesOptions()}
                    optionLabel="render"
                    optionValue="value"
                  />
                )}
              </Layout>
            </Layout>
          </RoundSubSection>
        </GraySection>
      )}
    </Layout>
  );
};

export default React.forwardRef(Page2);
