import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';


import { bindActionCreators, Dispatch } from 'redux';
import { InspectFormActions } from 'modules/InspectForm/redux/actions';
// interfaces
import { IReduxState } from 'core/interfaces';
import {
  IStateToProps,
  IDispatchToProps,
  IInspectForm,
} from 'modules/InspectForm/interfaces';

// ui
import './styles.scss';

//magnifying glass for search
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { getQueryString, isWebView } from 'core/utils';
import { getInspectionURL } from 'modules/BidInfoForm/utils';
import SearchModal from '../SearchModal';
import ActionAlertModal from 'core/components/ActionAlertModal';
import { getCookie } from '../../utils/cookiesUtils';

interface IOwnProps {
  navigateToForm(id: string, isSent: boolean, isTemplateURL: boolean,  isCityTemplateURL: boolean): void;
}

type IProps = IStateToProps &
  IDispatchToProps &
  IOwnProps &
  RouteComponentProps;

class InspectionsModal extends React.Component<IProps> {
  public state = {
    page: 0,
    currentPages: {
      Signed: 0,
      Templates: 0,
      CityTemplates: 0,
      Drafts: 0,
      Sents: 0,
    },
    sectionSelected: '',
    showModal: false,
    sortByMostRecent: true,
    azAscending: true,
    dateMostRecent: true,
    totalOfDraftItems: 0,
    totalOfSentItems: 0,
    totalOfTemplateItems: 0,
    totalOfCityTemplateItems: 0,
    showSearchModal: false,
    showAlertModal: false,
    formSelected: null,
  };

  public componentDidMount() {
    const {
      totalOfDraftItems,
      totalOfSentItems,
      totalOfTemplateItems,
      totalOfCityTemplateItems,
    } = this.props;

    this.setState({
      showModal: true,
      totalOfDraftItems,
      totalOfSentItems,
      totalOfTemplateItems,
      totalOfCityTemplateItems,
    });
    window.addFreeze();
  }

  public componentWillUnmount() {
    window.removeFreeze();
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      totalOfDraftItems,
      totalOfSentItems,
      totalOfTemplateItems,
      totalOfCityTemplateItems
    } = this.props;

    if (
      prevProps.totalOfDraftItems !== totalOfDraftItems ||
      prevProps.totalOfSentItems !== totalOfSentItems ||
      prevProps.totalOfTemplateItems !== totalOfTemplateItems ||
      prevProps.totalOfCityTemplateItems !== totalOfCityTemplateItems
    ) {
      this.setState({
        totalOfDraftItems,
        totalOfSentItems,
        totalOfTemplateItems,
        totalOfCityTemplateItems
      });
    }
  }

  public setSectionSelected = (
    section: string,
    target: Element = undefined
  ) => {
    this.setState({ sectionSelected: section });
    setTimeout(() => {
      if (target) {
        document
          .querySelector('.inspection-report-menu-container')
          .scrollTo(0, target.offsetTop - 80);
      }
    }, 0);
  };

  public onClickNote = (form: IInspectForm, isSent: boolean) => {
    const { setNotesModalState, setNote, setIsSent, setIsNotSent } = this.props;

    if (isSent) {
      setIsSent();
    } else {
      setIsNotSent();
    }
    setNote(form.Notes);
    setNotesModalState(true);
  };

  public onClickDismiss = (form: IInspectForm) => {
    this.setState({
      showAlertModal: true,
      formSelected: form,
    });
    // const deleteConfirmation = window.confirm(
    //   "Are you sure you want to delete this item?"
    // );
    // if (deleteConfirmation) {
    //   const { archiveFormRequest } = this.props;
    //   archiveFormRequest(form);
    // }
  };

  public onConfirmDeletion = () => {
    const { formSelected } = this.state;
    const { archiveFormRequest } = this.props;
    archiveFormRequest(formSelected);
    this.setState({
      showAlertModal: false,
      formSelected: null,
    });
  };

  public onClickDraft = (form: IInspectForm) => {
    const { openDraft, navigateToForm } = this.props;
    openDraft(form);
    navigateToForm(`${form.InspectionReportId}`, false, false, false);
  };

  public onClickTemplate = (form: IInspectForm) => {
    const { openTemplate, navigateToForm } = this.props;
    openTemplate(form);
    navigateToForm(`${form.InspectionReportId}`, false, true, false);
  };

  public onClickCityTemplate = (form: IInspectForm) => {
    const { openCityTemplate, navigateToForm } = this.props;
    openCityTemplate(form);
    navigateToForm(`${form.InspectionReportId}`, false, true, true);
  };

  public onClickSent = (form: IInspectForm) => {
    const { openSent, navigateToForm } = this.props;
    openSent(form);
    navigateToForm(`${form.InspectionReportId}`, true, false, false);
  };

  public formChosen = (form: IInspectForm, type: string) => {
    const { setFormClicked } = this.props;
    setFormClicked(form.InspectionReportId, type);
  };

  public onClickLoadMore = (type) => {
    const currentUrl = this.props.location.search;
    const userId = getQueryString(currentUrl, 'userId');

    const { sortByMostRecent, dateMostRecent, azAscending } = this.state;

    if (userId) {
      this.setState(
        {
          ...this.state,
          currentPages: {
            ...this.state.currentPages,
            [type]: this.state.currentPages[type] + 1,
          },
        },
        () => {
          this.props.getNextInspectionPageRequest(
            type,
            userId,
            this.state.currentPages[type],
            sortByMostRecent,
            dateMostRecent,
            azAscending
          );
        }
      );
    }
  };

  public onClickSearchIcon = (type) => {
    this.setState({
      type,
      showSearchModal: true,
    });
  };

  public truncateName = (name: string) => {
    if (name && name.length > 10) {
      return name.slice(0, 10) + '...';
    } else {
      return name;
    }
  };

  public renderTableItems = (
    item: IInspectForm,
    index: number,
    onClickAction: (form: IInspectForm) => void,
    type: string
  ) => {
    const isCityUser = getCookie('ANAGO_USER_IS_CITY_USER') === 'true';

    return (
      <tr key={index} onClick={() => onClickAction(item)}>
        {/* <td className="td-auto-save">AA</td> */}
        <td className="td-title">{this.truncateName(item.PID)}</td>
        <td className="td-rating">
          {(type === 'template' || type === 'citytemplate') ? null : (
            <p>
              Overall Rating: <span>{item.FacilityRating!.toFixed(1)}</span>
            </p>
          )}
        </td>
        <td className="td-last-update">Last Updated: {item.CreatedAt}</td>
        <td
          className="td-actions"
          style={{
            opacity:
              !item.Notes && (type === 'sent' || type === 'signed' ||  (!isCityUser && type =='citytemplate')) ? 0.5 : 1,
          }}
          onClick={(e: any) => {
            e.stopPropagation(); // adding this so when pressing the EditNote button, the modal stays on the /menu route
            e.preventDefault();
            if (!(!item.Notes && (type === 'sent' || type === 'signed' || (!isCityUser && type =='citytemplate')))) {
              this.onClickNote(item, type === 'sent' || type === 'signed' || (!isCityUser && type =='citytemplate'));
              this.formChosen(item, type);
            }
          }}
        >
          <i className="note-icon" />
          {type === 'sent' || type === 'signed' ||  (!isCityUser && type =='citytemplate') ? (
            <span>View Note</span>
          ) : (
            <span>Edit Note</span>
          )}
        </td>
        {!(!isCityUser && type =='citytemplate') && <td
          className="td-actions"
          onClick={(e) => {
            e.stopPropagation();
            this.onClickDismiss(item);
          }}
        >
          <i className="trash-icon" />
          <span>Archive</span>
        </td>}
      </tr>
    );
  };

  public renderDrafts = () => {
    const { sectionSelected, totalOfDraftItems } = this.state;
    const { draftItems } = this.props;
    return (
      <div className="section-container">
        <div className="title-container">
          <div className="content-container">
            <p>DRAFTS</p>
            {sectionSelected === 'Drafts' ? (
              <i
                className="arrow-up-icon"
                onClick={() => this.setSectionSelected('')}
              />
            ) : (
              <i
                className="arrow-down-icon"
                onClick={(e) =>
                  this.setSectionSelected('Drafts', e.currentTarget)
                }
              />
            )}
          </div>
        </div>
        {sectionSelected === 'Drafts' && (
          <table className="table-container">
            <tbody>
              {draftItems.map((item, index) =>
                this.renderTableItems(item, index, this.onClickDraft, 'draft')
              )}
              {draftItems.length < totalOfDraftItems && (
                <tr className="load-more-wrapper">
                  <button
                    className="button-secondary"
                    onClick={() => this.onClickLoadMore('Drafts')}
                  >
                    LOAD MORE
                  </button>
                  <div onClick={() => this.onClickSearchIcon('Drafts')}>
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="2x"
                      color="#216eab"
                    />
                  </div>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    );
  };

  public renderTemplates = () => {
    const { sectionSelected, totalOfTemplateItems } = this.state;
    const { templateItems } = this.props;
    return (
      <div className="section-container">
        <div className="title-container">
          <div className="content-container">
            <p>MY TEMPLATES</p>
            {sectionSelected === 'Templates' ? (
              <i
                className="arrow-up-icon"
                onClick={() => this.setSectionSelected('')}
              />
            ) : (
              <i
                className="arrow-down-icon"
                onClick={(e) =>
                  this.setSectionSelected('Templates', e.currentTarget)
                }
              />
            )}
          </div>
        </div>
        {sectionSelected === 'Templates' && (
          <table className="table-container">
            <tbody>
              {templateItems.map((item, index) =>
                this.renderTableItems(
                  item,
                  index,
                  this.onClickTemplate,
                  'template'
                )
              )}
              {templateItems.length < totalOfTemplateItems && (
                <tr className="load-more-wrapper">
                  <button
                    className="button-secondary"
                    onClick={() => this.onClickLoadMore('Templates')}
                  >
                    LOAD MORE
                  </button>
                  <div onClick={() => this.onClickSearchIcon('Templates')}>
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="2x"
                      color="#216eab"
                    />
                  </div>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    );
  };

  public renderCityTemplates = () => {
    const { sectionSelected, totalOfCityTemplateItems } = this.state;
    const { cityTemplateItems } = this.props;
    return (
      <div className="section-container">
        <div className="title-container">
          <div className="content-container">
            <p>CITY TEMPLATES</p>
            {sectionSelected === 'CityTemplates' ? (
              <i
                className="arrow-up-icon"
                onClick={() => this.setSectionSelected('')}
              />
            ) : (
              <i
                className="arrow-down-icon"
                onClick={(e) =>
                  this.setSectionSelected('CityTemplates', e.currentTarget)
                }
              />
            )}
          </div>
        </div>
        {sectionSelected === 'CityTemplates' && (
          <table className="table-container">
            <tbody>
              {cityTemplateItems.map((item, index) =>
                this.renderTableItems(
                  item,
                  index,
                  this.onClickCityTemplate,
                  'citytemplate'
                )
              )}
              {cityTemplateItems.length < totalOfCityTemplateItems && (
                <tr className="load-more-wrapper">
                  <button
                    className="button-secondary"
                    onClick={() => this.onClickLoadMore('CityTemplates')}
                  >
                    LOAD MORE
                  </button>
                  <div onClick={() => this.onClickSearchIcon('CityTemplates')}>
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="2x"
                      color="#216eab"
                    />
                  </div>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    );
  };

  public renderSent = () => {
    const { sectionSelected, totalOfSentItems } = this.state;
    const { sentItems } = this.props;
    return (
      <div className="section-container">
        <div className="title-container">
          <div className="content-container">
            <p>SENT</p>
            {sectionSelected === 'Sent' ? (
              <i
                className="arrow-up-icon"
                onClick={() => this.setSectionSelected('')}
              />
            ) : (
              <i
                className="arrow-down-icon"
                onClick={(e) =>
                  this.setSectionSelected('Sent', e.currentTarget)
                }
              />
            )}
          </div>
        </div>
        {sectionSelected === 'Sent' && (
          <table className="table-container">
            <tbody>
              {sentItems.map((item, index) =>
                this.renderTableItems(item, index, this.onClickSent, 'sent')
              )}
              {
                // when there is more than 20 items in the backend and we have less items loaded in the frontend than the number found in the backend, show both buttons
                totalOfSentItems > 20 && sentItems.length < totalOfSentItems && (
                  <tr className="load-more-wrapper">
                    <button
                      className="button-secondary"
                      onClick={() => this.onClickLoadMore('Sents')}
                    >
                      LOAD MORE
                    </button>
                    <div onClick={() => this.onClickSearchIcon('Sents')}>
                      <FontAwesomeIcon
                        icon={faSearch}
                        size="2x"
                        color="#216eab"
                      />
                    </div>
                  </tr>
                )
              }
              {/* {
                // show only search icon if there is no more items to load from backend
                sentItems.length >= totalOfSentItems && (
                  <tr className="load-more-wrapper">
                    <div onClick={this.onClickSearchIcon}>
                      <FontAwesomeIcon
                        icon={faSearch}
                        size="2x"
                        color="#216eab"
                      />
                    </div>
                  </tr>
                )
              } */}
            </tbody>
          </table>
        )}
      </div>
    );
  };

  public closeModal = () => {
    if (this.props.doneAction) {
      this.props.doneAction();
      this.setState({
        showModal: false,
      });
      return;
    }
    const { history } = this.props;
    this.setState({
      showModal: false,
    });

    if (isWebView()) {
      history.push('/close-form');
    } else {
      document.location.href = getInspectionURL();
    }
  };

  public closeSearchModal = () => {
    this.setState({
      showSearchModal: false,
    });
  };

  public sortByDate = () => {
    const { sortInspections } = this.props;
    this.setState(
      (prevState) => {
        return {
          sortByMostRecent: true,
          dateMostRecent: prevState.sortByMostRecent
            ? !prevState.dateMostRecent
            : true, // if condition is false, it means it came from AZ to date button, so we set the initial state, otherwise, we just toggle
          azAscending: true, // settting it true so when clicking the date button again, we see the labels changing back to its default value
        };
      },
      () => {
        sortInspections(
          this.state.sortByMostRecent,
          this.state.dateMostRecent,
          this.state.azAscending
        );
      }
    );
  };

  public sortByAZ = () => {
    const { sortInspections } = this.props;
    this.setState(
      (prevState) => {
        return {
          sortByMostRecent: false,
          azAscending: prevState.sortByMostRecent
            ? true
            : !prevState.azAscending, // if condition is true, it means it came from the date button, so we set the initial state, otherwise, we just toggle
          // dateMostRecent: false, // settting it false so when clicking to sort by date it returns to its default value
        };
      },
      () => {
        sortInspections(
          this.state.sortByMostRecent,
          this.state.dateMostRecent,
          this.state.azAscending
        );
      }
    );
  };

  public render() {
    const { showModal, sortByMostRecent, showSearchModal } = this.state;
    // const { showModal, showSearchModal } = this.state;

    if (!showModal) {
      return null;
    }

    const getSortButtonClass = (type: 'date' | 'alpha') => {
      if (type === 'date') {
        return sortByMostRecent ? 'button' : 'button-secondary';
      } else {
        return sortByMostRecent ? 'button-secondary' : 'button';
      }
    };

    return (
      <>
        <div className="modal-background-inspection">
          <div className="modal-content-container">
            <div className="modal-title-container">
              <h3>INSPECTIONS REPORT</h3>
              <div className="buttons-row">
                <p>Sort by:</p>
                <button
                  className={`button-filter-date ${getSortButtonClass(
                    'date'
                  )} ${this.state.dateMostRecent ? 'button-invert' : ''}`}
                  onClick={() => this.sortByDate()}
                >
                  Date
                </button>
                <button
                  className={`button-filter-sort ${getSortButtonClass(
                    'alpha'
                  )}`}
                  onClick={() => this.sortByAZ()}
                >
                  {this.state.azAscending ? 'A-Z' : 'Z-A'}
                </button>
                {/* {sortByMostRecent ? "Sorting by Most Recent" : "Sorting by A-Z"} */}
              </div>
            </div>
            <div className="inspection-report-menu-container">
              {this.renderDrafts()}
              {this.renderTemplates()}
              {this.renderCityTemplates()}
              {this.renderSent()}
            </div>
          </div>
          <div className="modal-done-button" onClick={() => this.closeModal()}>
            <button className="button">DONE</button>
          </div>
        </div>
        {showSearchModal && (
          <SearchModal
            type={this.state.type}
            onCloseModal={this.closeSearchModal}
            renderTableItems={this.renderTableItems}
            onClickSent={this.onClickSent}
          />
        )}

        <ActionAlertModal
          title=""
          message="Are you sure you want to delete this item?"
          onConfirm={() => this.onConfirmDeletion()}
          // onDismiss={this.props.closeExitModal}
          onDismiss={() => {
            this.setState({
              showAlertModal: false,
              formSelected: null,
            });
          }}
          isVisible={this.state.showAlertModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state: IReduxState) => ({
  ...state.inspectForm,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...InspectFormActions }, dispatch);

const Connected = connect<
  IStateToProps,
  IDispatchToProps,
  IOwnProps,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InspectionsModal));

export { Connected as InspectionsModal };
