import React from 'react';
import {
  Checkbox,
  Tooltip,
  useFormProps,
  MonthlyServiceQuoteOptions,
} from 'core/components';
import {
  formatNumberByMask,
  formatPrice,
  convertStringToNumber,
  toTitleCase,
} from 'core/utils';

import { optionsPlans } from 'modules/shared/bidDefaultValues';

import { moneyMaskParams } from 'core/utils/masks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faReply,
  faEdit,
  faEnvelope,
  faInfoCircle,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import Attachments from './Attachments';
import {
  Wrapper,
  Header,
  Heading,
  Text,
  HeaderTop,
  HeaderBottom,
  Content,
  Row,
  Cell,
  CellHeader,
  Info,
  Holder,
  RowHolder,
  Footer,
  Button,
  ButtonContent,
  ButtonLabel,
  IconHolder,
  TooltipContent,
  InputText,
  InputLabel,
  CheckboxHolder,
} from './styles';
import { LoadingPage } from 'modules/BidInfoForm/components/organisms';
import { DisclaimerParagraph } from '../../../BidInfoForm/styles';
import {
  getSystemAttachments,
  getFranchiseAttachments,
} from 'modules/BidInfoForm/services/bidInfoServiceClient';

const parseNumber = (str, locale) => {
  let radix = ',';
  if (locale.match(/(en|th)([-_].+)?/)) {
    radix = '.';
  }
  return Number(
    str
      .replace(new RegExp('[^\\d\\' + radix + ']', 'g'), '')
      .replace(radix, '.')
  );
};

const fn = () => undefined;

const getDaysPorterDays = (days) => {
  return days
    .replace('Mon', 'Monday')
    .replace('Tue', 'Tuesday')
    .replace('Wed', 'Wednesday')
    .replace('Thu', 'Thursday')
    .replace('Fri', 'Friday')
    .replace('Sat', 'Saturday')
    .replace('Sun', 'Sunday');
};

interface ISummary {
  onSaveButtonClick: () => void;
  onBackButtonClick: () => void;
  onCustomizeTermsButtonClick: () => void;
  bidFullData: any;
  isSpecialty?: boolean;
  franchiseData: any;
}

export const Summary = React.forwardRef(
  (
    {
      onSaveButtonClick = fn,
      onBackButtonClick = fn,
      onCustomizeTermsButtonClick = fn,
      bidData,
      terms,
      bidFullData,
      franchiseData,
      franchiseId,
      isSpecialty,
      setWarningModal,
      showDeleteConfirmationModal,
    }: ISummary,
    ref: React.MutableRefObject<any>
  ) => {
    const { formValues } = useFormProps();
    const date = new Date(formValues.bidDate);
    date.setDate(date.getDate() + 2);
    const [initialFollowUpDate, setInitialFollowUpDate] = React.useState(date);
    const [emailProposal, setEmailProposal] = React.useState([]);
    const [emailSelected, setEmailSelected] = React.useState([]);
    const [otherEmailProposal, setOtherEmailProposal] = React.useState('');
    const [otherEmailProposalChecked, setOtherEmailProposalChecked] =
      React.useState(false);
    const attachmentsRef = React.useRef();
    const hasMonthlyFrequency = formValues.frequencyService === 'monthly';
    const [haveModifiedTerms, setHaveModifiedTerms] = React.useState(false);

    React.useEffect(() => {
      if (terms) {
        if (terms.find((i) => i.modified)) {
          setHaveModifiedTerms(true);
        } else {
          setHaveModifiedTerms(false);
        }
      }
    }, [terms]);

    const hasFrequency =
      (formValues.frequency &&
        formValues.frequency.selecteds &&
        formValues.frequency.selecteds.length > 0) ||
      hasMonthlyFrequency;
    const hasSecondFrequency =
      formValues.secondFrequency &&
      formValues.secondFrequency.selecteds &&
      formValues.secondFrequency.selecteds.length > 0;

    const hasSecondMonthlyFrequency =
      hasMonthlyFrequency &&
      formValues.secondCleaning &&
      formValues.monthlyAddFrequency;

    const hasWalkThruEmail = formValues.walkThruEmail;
    const hasDecisionMakerEmail = formValues.decisionMakerEmail;
    const hasQuotes =
      formValues.quotes &&
      formValues.quotes.length > 0 &&
      formValues.quotes[0].quoteSelect &&
      formValues.quotes.filter(
        (quote) =>
          convertStringToNumber(quote.quotePrice) > 0 ||
          convertStringToNumber(quote.quoteSqft) > 0 ||
          convertStringToNumber(quote.quoteOverride) > 0 ||
          convertStringToNumber(quote.unitPrice) > 0 ||
          quote.uponRequest
      ).length > 0;
    const [isLoading, setIsLoading] = React.useState(true);
    const isSmartCleanSet = formValues.smartClean;
    const hasProtectionPackages =
      formValues.protectionPackages.length > 0 ||
      formValues.secondProtectionPackages.length > 0;
    const [minorThan768, setMinorThan768] = React.useState(false);
    const [systemAttachments, setSystemAttachments] = React.useState([]);
    const [franchiseAttachments, setFranchiseAttachments] = React.useState([]);

    const getAttachmentsFromServer = async () => {
      setIsLoading(true);

      const { data } = await getSystemAttachments();
      data.sort((prev, next) => prev['ScreenOrder'] - next['ScreenOrder']);

      setSystemAttachments(data);

      const { data: fData } = await getFranchiseAttachments(franchiseId);
      fData.sort((prev, next) => prev['ScreenOrder'] - next['ScreenOrder']);

      setFranchiseAttachments(fData);

      setIsLoading(false);
    };
    React.useEffect(() => {
      getAttachmentsFromServer();
    }, []);

    const setBreakpoints = () => {
      setMinorThan768(window.innerWidth < 768);
    };

    React.useEffect(() => {
      setBreakpoints();
    }, [window.innerWidth]);

    React.useLayoutEffect(() => {
      window.addEventListener('resize', setBreakpoints);
      setBreakpoints();
      return () => window.removeEventListener('resize', setBreakpoints);
    }, []);

    const getPreparedFor = (key: string) => {
      switch (key) {
        case 'name':
          if (formValues.proposalAddressedTo == 'walkThru') {
            return formValues.walkThruName;
          } else {
            return formValues.decisionMakerName;
          }

        case 'title':
          if (formValues.proposalAddressedTo == 'walkThru') {
            return `${formValues.walkThruTitle ? ' - ' : ''}${
              formValues.walkThruTitle
            }`;
          } else {
            return `${formValues.decisionMakerName ? ' - ' : ''}${
              formValues.decisionMakerTitle
            }`;
          }

        default:
          return '';
      }
    };

    const getData = () => {
      let attachments;

      if (attachmentsRef.current) {
        // @ts-ignore
        attachments = attachmentsRef.current.getData();
      }

      return {
        initialFollowUpDate,
        emailProposal: otherEmailProposalChecked
          ? [...new Set(emailProposal), otherEmailProposal]
          : [...new Set(emailProposal)],
        attachments,
      };
    };

    const onContactsChange = (name, value: any, checked: boolean) => {
      let arr = [];
      let selectedArray = [];

      if (!checked) {
        arr = emailProposal.filter((x) => x !== value);
        selectedArray = emailSelected.filter((x) => x !== name);
      } else {
        if (value) {
          arr = [...emailProposal, value];
        }
        if (name) {
          selectedArray = [...emailSelected, name];
        }
      }
      setEmailProposal(arr);
      setEmailSelected(selectedArray);
    };

    let firstFrequencyPrice = (() => {
      let p = formValues.overridePrice || formValues.calculatedPrice;

      if (typeof p === 'string') {
        p = parseNumber(p, 'en');
      }

      if (isNaN(p)) {
        p = 0;
      }

      p = formatNumberByMask(
        (+p + (formValues.userHasBetaOptIn ? 5 : 0)).toString(),
        {
          decimalLimit: 2,
          thousandsSeparatorSymbol: ',',
        }
      );

      console.debug('firstFrequencyPrice', p);

      return p;
    })();

    let secondFrequencyPrice = (() => {
      let p = formValues.secondOverride || formValues.secondCalculatedPrice;

      if (typeof p === 'string') {
        p = parseNumber(p, 'en');
      }

      if (isNaN(p)) {
        p = 0;
      }

      p = formatNumberByMask(
        (+p + (formValues.userHasBetaOptIn ? 5 : 0)).toString(),
        {
          decimalLimit: 2,
          thousandsSeparatorSymbol: ',',
        }
      );
      console.debug('secondFrequencyPrice', p);
      return p;
    })();

    let smartCleanPrice = (() => {
      const p = bidFullData['SmartCleanOverrideBasePrice']
        ? bidFullData['SmartCleanOverrideBasePrice']
        : bidFullData['SmartCleanCalculatedBasePrice']
        ? bidFullData['SmartCleanCalculatedBasePrice']
        : 0;

      return `$${formatNumberByMask(
        bidFullData['OwnerHasEcoInvoice'] ? (p + 5).toString() : p.toString(),
        {
          ...moneyMaskParams,
          thousandsSeparatorSymbol: ',',
        }
      )}`;
    })();

    React.useImperativeHandle(ref, () => {
      return {
        getData,
      };
    });

    const getValue = (key: string) => {
      return formValues[key] || '';
    };

    const joinFieldValue = (joinChar = ',', ...values) =>
      values.reduce((acc, i) => {
        if (acc != '' && i != '') {
          acc += `${joinChar} `;
        }
        acc += i.trim();
        return acc;
      }, '');

    const getCompanyAddress = () => {
      return formValues
        ? joinFieldValue(
            ',',
            getValue('address'),
            getValue('suite'),
            getValue('city'),
            `${getValue('state')} ${getValue('postalCode')}`
          )
        : '';
    };

    return (
      <>
        {isLoading && <LoadingPage />}
        <Wrapper>
          <Header>
            <HeaderTop>
              <Heading italic>Summary</Heading>
              <Heading size="30px" italic>
                Client Information
              </Heading>
              <Heading size="22px">
                <strong>{formValues.companyName}</strong>
              </Heading>
            </HeaderTop>

            <HeaderBottom>
              <Text>{getCompanyAddress()}</Text>

              <Text>
                <small>
                  <i>
                    Prepared for:{' '}
                    <strong>
                      {`${getPreparedFor('name')}${getPreparedFor('title')}`}
                    </strong>
                  </i>
                </small>
              </Text>
            </HeaderBottom>
          </Header>

          <Content>
            <Text> Please review the following details for accuracy.</Text>

            {!isSpecialty && (
              <Holder margin="0 0 40px">
                <Heading size="24px" italic>
                  Monthly Service Quote(s){' '}
                </Heading>
                {isSmartCleanSet && (
                  <>
                    <Row>
                      <CellHeader>Frequency</CellHeader>
                      <CellHeader width="210px">Days Serviced</CellHeader>
                      <CellHeader width="178px">Time Window</CellHeader>
                      <CellHeader>
                        <span
                          style={{
                            position: 'absolute',
                            left: '5px',
                            top: '-35px',
                          }}
                        >
                          SmartClean<sup className="sm">&#8480;</sup>
                          <br /> Price
                          {franchiseData && franchiseData.SalesTax && (
                            <small>
                              <sup>†</sup>
                            </small>
                          )}
                          {formValues.userHasBetaOptIn && (
                            <IconHolder>
                              <Tooltip
                                overlay={
                                  <TooltipContent>
                                    $5 will automatically be added to any
                                    Calculated or Override Bid Price. The client
                                    can have this discounted back by selecting
                                    Eco-Invoice (ACH Debit) as their payment
                                    method.
                                  </TooltipContent>
                                }
                                placement="top"
                              >
                                <FontAwesomeIcon
                                  icon={faInfoCircle}
                                  size="1x"
                                />
                              </Tooltip>
                            </IconHolder>
                          )}
                        </span>
                      </CellHeader>
                    </Row>
                    <RowHolder>
                      <Row
                        style={{
                          height: formValues.disinfection ? '52px' : 'auto',
                        }}
                      >
                        <Cell label="Frequency">
                          {/* <Info>3x Weekly</Info> */}
                          <Info
                            style={{
                              alignItems: 'center',
                              flexDirection: 'column',
                              justifyContent: formValues.disinfection
                                ? 'flex-start'
                                : 'center',
                              padding: '5px',
                              paddingTop: '4px',
                              width: minorThan768 ? '100%' : '132px',
                            }}
                          >
                            <>
                              {`${
                                bidFullData['SmartCleanDaysCleaned'] || '0'
                              }x Weekly`}
                              {formValues.disinfection && (
                                <span
                                  style={{
                                    color: '#F7A11D',
                                    fontSize: '10px',
                                    marginTop: '-6px',
                                  }}
                                >
                                  & Protection+ Disinfect
                                </span>
                              )}
                            </>
                          </Info>
                        </Cell>
                        <Cell
                          label="Days Serviced"
                          width="210px"
                          style={{ paddingLeft: '12px' }}
                        >
                          {/* <Info>Mon, Wed, Fri</Info> */}
                          <Info>
                            {bidFullData['SelectedDaysCleanedListCompactText']}
                          </Info>
                        </Cell>
                        <Cell label="Time Window" style={{ minWidth: '170px' }}>
                          {/* <Info>After 6pm</Info> */}
                          <Info style={{ padding: '5px' }}>
                            {bidFullData['TimeOfDay']}
                          </Info>
                        </Cell>
                        <Cell
                          label={
                            <span
                              style={{
                                position: 'absolute',
                                left: '5px',
                                top: '-35px',
                              }}
                            >
                              SmartClean<sup className="sm">&#8480;</sup> Price
                              {franchiseData && franchiseData.SalesTax && (
                                <small>
                                  <sup>†</sup>
                                </small>
                              )}
                            </span>
                          }
                        >
                          <Info>
                            <strong>{smartCleanPrice}</strong>
                          </Info>
                        </Cell>
                      </Row>
                    </RowHolder>
                  </>
                )}

                {!isSmartCleanSet && (
                  <>
                    {!formValues.disinfection && hasProtectionPackages && (
                      <MonthlyServiceQuoteOptions
                        readyOnly
                        calculatedTotal={formValues.calculatedPrice}
                        hasBeta={formValues.userHasBetaOptIn}
                        plusApplicableTax={
                          franchiseData && franchiseData.SalesTax
                        }
                        hasContainer={false}
                        packageIncluded={formValues.protectionIncludedInPrice}
                        items={[
                          {
                            title: `${
                              formValues.smartClean ? 'SmartClean' : 'Quote 1'
                            }: ${
                              formValues.quoteTitle ||
                              `${
                                hasMonthlyFrequency
                                  ? formValues.cleaning
                                  : formValues.smartClean
                                  ? formValues.frequency.selecteds.length +
                                    ((formValues.secondFrequency &&
                                      formValues.secondFrequency.selecteds
                                        .length) ||
                                      0)
                                  : formValues.frequency.selecteds.length
                              }x ${toTitleCase(formValues.frequencyService)}`
                            }`,
                            frequency: `${
                              hasMonthlyFrequency
                                ? formValues.cleaning
                                : formValues.frequency.selecteds.length
                            }x ${toTitleCase(formValues.frequencyService)}`,
                            hasDisinfection: formValues.disinfection,
                            daysServiced: hasMonthlyFrequency
                              ? 'MONTHLY'
                              : bidFullData['DaysCleanedListTextCompactText'],
                            timeWindow: hasMonthlyFrequency
                              ? formValues.timeWindow
                              : formValues.frequency.timeWindow,
                            services: [
                              {
                                service: 'Customized Cleaning Service',
                                value: formValues.protectionIncludedInPrice
                                  ? 'Included'
                                  : formatPrice(
                                      formValues.bidOverrideBasePrice ||
                                        formValues.bidCalculatedBasePrice,
                                      bidFullData['OwnerHasEcoInvoice']
                                    ),
                              },
                              formValues.includeInPrice &&
                                formValues.monthlyPorterPrice > 0 && {
                                  service: 'Day Porter Included',
                                  value: formatPrice(
                                    formValues.porterOverride ||
                                      formValues.monthlyPorterPrice
                                  ),
                                },
                              formValues.costSupplies && {
                                service: 'Supplies Included',
                                value: formatPrice(formValues.costSupplies),
                              },
                            ],
                            protectionOptions:
                              formValues.protectionPackages.map((item) => ({
                                index: item.index,
                                value: formValues.protectionIncludedInPrice
                                  ? 'Included'
                                  : formatPrice(
                                      item.totalOverride
                                        ? item.totalOverride
                                        : item.total
                                    ),
                                plans: item.plans.map((p) => ({
                                  plan: p.plan,
                                  frequency: p.frequency,
                                  color:
                                    optionsPlans.filter((i) => i.plan == p.plan)
                                      .length > 0
                                      ? optionsPlans.filter(
                                          (i) => i.plan == p.plan
                                        )[0].backgroundColor
                                      : null,
                                })),
                              })),
                          },
                        ]}
                      />
                    )}
                    {hasFrequency && !hasProtectionPackages && (
                      <Row>
                        <CellHeader>Frequency</CellHeader>
                        <CellHeader width="210px">Days Serviced</CellHeader>
                        <CellHeader width="178px">Time Window</CellHeader>
                        <CellHeader>
                          Monthly Price
                          {!isSmartCleanSet &&
                            franchiseData &&
                            franchiseData.SalesTax && (
                              <small>
                                <sup> †</sup>
                              </small>
                            )}
                          {formValues.userHasBetaOptIn && (
                            <IconHolder>
                              <Tooltip
                                overlay={
                                  <TooltipContent>
                                    $5 will automatically be added to any
                                    Calculated or Override Bid Price. The client
                                    can have this discounted back by selecting
                                    Eco-Invoice (ACH Debit) as their payment
                                    method.
                                  </TooltipContent>
                                }
                                placement="top"
                              >
                                <FontAwesomeIcon
                                  icon={faInfoCircle}
                                  size="1x"
                                />
                              </Tooltip>
                            </IconHolder>
                          )}
                        </CellHeader>
                      </Row>
                    )}

                    {!hasFrequency && (
                      <Row>
                        <Cell width="430px" textAlign="left">
                          No service quote selected.
                        </Cell>
                      </Row>
                    )}

                    {hasFrequency && !hasProtectionPackages && (
                      <RowHolder>
                        {!hasMonthlyFrequency ? (
                          <Row
                            style={{
                              height: formValues.disinfection ? '52px' : 'auto',
                            }}
                          >
                            <Cell label="Frequency">
                              <Info
                                style={{
                                  alignItems: 'center',
                                  flexDirection: 'column',
                                  justifyContent: formValues.disinfection
                                    ? 'flex-start'
                                    : 'center',
                                  padding: '5px',
                                  paddingTop: '4px',
                                  width: minorThan768 ? '100%' : '132px',
                                }}
                              >
                                <>
                                  {`${
                                    formValues.frequency.selecteds.length
                                  }x ${toTitleCase(
                                    formValues.frequencyService
                                  )}`}
                                  {formValues.disinfection && (
                                    <span
                                      style={{
                                        color: '#F7A11D',
                                        fontSize: '10px',
                                        marginTop: '-6px',
                                      }}
                                    >
                                      & Protection+ Disinfect
                                    </span>
                                  )}
                                </>
                              </Info>
                            </Cell>
                            <Cell
                              label="Days Serviced"
                              width="210px"
                              style={{ paddingLeft: '12px' }}
                            >
                              <Info>
                                {bidFullData['DaysCleanedListTextCompactText']}
                              </Info>
                            </Cell>
                            <Cell
                              label="Time Window"
                              style={{ minWidth: '170px' }}
                            >
                              <Info style={{ padding: '5px' }}>
                                {formValues.frequency.timeWindow}
                              </Info>
                            </Cell>
                            <Cell label="Monthly Price">
                              <Info>
                                <strong>${firstFrequencyPrice}</strong>
                              </Info>
                            </Cell>
                          </Row>
                        ) : (
                          <Row
                            style={{
                              height: formValues.monthlyDisinfection
                                ? '52px'
                                : 'auto',
                            }}
                          >
                            <Cell label="Frequency">
                              <Info
                                style={{
                                  alignItems: 'center',
                                  flexDirection: 'column',
                                  justifyContent: formValues.monthlyDisinfection
                                    ? 'flex-start'
                                    : 'center',
                                  padding: '5px',
                                  paddingTop: '4px',
                                  width: minorThan768 ? '100%' : '132px',
                                }}
                              >
                                <>
                                  {`${formValues.cleaning || 0}x Monthly`}
                                  {formValues.monthlyDisinfection && (
                                    <span
                                      style={{
                                        color: '#F7A11D',
                                        fontSize: '10px',
                                        marginTop: '-6px',
                                      }}
                                    >
                                      & Protection+ Disinfect
                                    </span>
                                  )}
                                </>
                              </Info>
                            </Cell>
                            <Cell
                              label="Days Serviced"
                              width="210px"
                              style={{ paddingLeft: '12px' }}
                            >
                              <Info>MONTHLY</Info>
                            </Cell>
                            <Cell
                              label="Time Window"
                              style={{ minWidth: '170px' }}
                            >
                              <Info style={{ padding: '5px' }}>
                                {formValues.timeWindow}
                              </Info>
                            </Cell>
                            <Cell label="Monthly Price">
                              <Info>
                                <strong>${firstFrequencyPrice}</strong>
                              </Info>
                            </Cell>
                          </Row>
                        )}
                      </RowHolder>
                    )}

                    {hasSecondFrequency && (
                      <>
                        {!formValues.secondDisinfection &&
                        hasProtectionPackages ? (
                          <MonthlyServiceQuoteOptions
                            readyOnly
                            plusApplicableTax={
                              franchiseData && franchiseData.SalesTax
                            }
                            hasContainer={false}
                            calculatedTotal={formValues.secondCalculatedPrice}
                            hasBeta={formValues.userHasBetaOptIn}
                            packageIncluded={
                              formValues.secondProtectionIncludedInPrice
                            }
                            items={[
                              {
                                title: `Quote 2: ${
                                  formValues.secondQuoteTitle ||
                                  `${
                                    formValues.secondFrequency.selecteds.length
                                  }x ${toTitleCase(
                                    formValues.frequencyService
                                  )}`
                                }`,
                                frequency: `${
                                  formValues.secondFrequency.selecteds.length
                                }x ${toTitleCase(formValues.frequencyService)}`,
                                hasDisinfection: formValues.secondDisinfection,
                                daysServiced: hasMonthlyFrequency
                                  ? 'MONTHLY'
                                  : bidFullData[
                                      'SecondDaysCleanedListTextCompactText'
                                    ],
                                timeWindow: hasMonthlyFrequency
                                  ? formValues.secondTimeWindow
                                  : formValues.secondFrequency.timeWindow,
                                services: [
                                  {
                                    service: 'Customized Cleaning Service',
                                    value:
                                      formValues.secondProtectionIncludedInPrice
                                        ? 'Included'
                                        : formatPrice(
                                            formValues.secondBidOverrideBasePrice ||
                                              formValues.secondBidCalculatedBasePrice,
                                            bidFullData['OwnerHasEcoInvoice']
                                          ),
                                  },
                                  formValues.includeInPrice &&
                                    formValues.monthlyPorterPrice > 0 && {
                                      service: 'Day Porter Included',
                                      value: formatPrice(
                                        formValues.porterOverride ||
                                          formValues.monthlyPorterPrice
                                      ),
                                    },
                                  formValues.costSupplies && {
                                    service: 'Supplies Included',
                                    value: formatPrice(formValues.costSupplies),
                                  },
                                ],
                                protectionOptions:
                                  formValues.secondProtectionPackages.map(
                                    (item) => ({
                                      index: item.index,
                                      value:
                                        formValues.secondProtectionIncludedInPrice
                                          ? 'Included'
                                          : formatPrice(
                                              item.totalOverride
                                                ? item.totalOverride
                                                : item.total
                                            ),
                                      plans: item.plans.map((p) => ({
                                        plan: p.plan,
                                        frequency: p.frequency,
                                        color:
                                          optionsPlans.filter(
                                            (i) => i.plan == p.plan
                                          ).length > 0
                                            ? optionsPlans.filter(
                                                (i) => i.plan == p.plan
                                              )[0].backgroundColor
                                            : null,
                                      })),
                                    })
                                  ),
                              },
                            ]}
                          />
                        ) : (
                          <RowHolder>
                            <Row
                              style={{
                                height: formValues.secondDisinfection
                                  ? '52px'
                                  : 'auto',
                              }}
                            >
                              <Cell label="Frequency">
                                {/* <Info>5x Weekly</Info> */}
                                <Info
                                  style={{
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent:
                                      formValues.secondDisinfection
                                        ? 'flex-start'
                                        : 'center',
                                    padding: '5px',
                                    paddingTop: '4px',
                                    width: minorThan768 ? '100%' : '132px',
                                  }}
                                >
                                  <>
                                    {`${
                                      formValues.secondFrequency.selecteds
                                        .length
                                    }x ${toTitleCase(
                                      formValues.frequencyService
                                    )}`}
                                    {formValues.secondDisinfection && (
                                      <span
                                        style={{
                                          color: '#F7A11D',
                                          fontSize: '10px',
                                          marginTop: '-6px',
                                        }}
                                      >
                                        & Protection+ Disinfect
                                      </span>
                                    )}
                                  </>
                                </Info>
                              </Cell>
                              <Cell
                                label="Days Serviced"
                                width="210px"
                                style={{ paddingLeft: '12px' }}
                              >
                                {/* <Info>Mon, Tue, Wed, Thu, Fri</Info> */}
                                <Info>
                                  {
                                    bidFullData[
                                      'SecondDaysCleanedListTextCompactText'
                                    ]
                                  }
                                </Info>
                              </Cell>
                              <Cell
                                label="Time Window"
                                style={{ minWidth: '170px' }}
                              >
                                {/* <Info>After 6pm</Info> */}
                                <Info style={{ padding: '5px' }}>
                                  {formValues.secondFrequency.timeWindow}
                                </Info>
                              </Cell>
                              <Cell label="Monthly Price">
                                <Info>
                                  <strong>${secondFrequencyPrice}</strong>
                                </Info>
                              </Cell>
                            </Row>
                          </RowHolder>
                        )}
                      </>
                    )}

                    {hasSecondMonthlyFrequency && (
                      <>
                        {!formValues.secondMonthlyDisinfection &&
                        hasProtectionPackages ? (
                          <MonthlyServiceQuoteOptions
                            readyOnly
                            plusApplicableTax={
                              franchiseData && franchiseData.SalesTax
                            }
                            hasContainer={false}
                            calculatedTotal={formValues.secondCalculatedPrice}
                            hasBeta={formValues.userHasBetaOptIn}
                            packageIncluded={
                              formValues.secondProtectionIncludedInPrice
                            }
                            items={[
                              {
                                title: `Quote 2: ${
                                  formValues.secondQuoteTitle ||
                                  `${formValues.secondCleaning}x ${toTitleCase(
                                    formValues.frequencyService
                                  )}`
                                }`,
                                frequency: `${
                                  formValues.secondCleaning
                                }x ${toTitleCase(formValues.frequencyService)}`,
                                hasDisinfection:
                                  formValues.secondMonthlyDisinfection,
                                daysServiced:
                                  formValues.frequencyService == 'monthly'
                                    ? 'MONTHLY'
                                    : bidFullData[
                                        'SecondDaysCleanedListTextCompactText'
                                      ],
                                timeWindow: formValues.secondTimeWindow,
                                services: [
                                  {
                                    service: 'Customized Cleaning Service',
                                    value:
                                      formValues.secondProtectionIncludedInPrice
                                        ? 'Included'
                                        : formatPrice(
                                            formValues.secondBidOverrideBasePrice ||
                                              formValues.secondBidCalculatedBasePrice,
                                            bidFullData['OwnerHasEcoInvoice']
                                          ),
                                  },
                                  formValues.includeInPrice &&
                                    formValues.monthlyPorterPrice > 0 && {
                                      service: 'Day Porter Included',
                                      value: formatPrice(
                                        formValues.porterOverride ||
                                          formValues.monthlyPorterPrice
                                      ),
                                    },
                                  formValues.costSupplies && {
                                    service: 'Supplies Included',
                                    value: formatPrice(formValues.costSupplies),
                                  },
                                ],
                                protectionOptions:
                                  formValues.secondProtectionPackages.map(
                                    (item) => ({
                                      index: item.index,
                                      value:
                                        formValues.secondProtectionIncludedInPrice
                                          ? 'Included'
                                          : formatPrice(
                                              item.totalOverride
                                                ? item.totalOverride
                                                : item.total
                                            ),
                                      plans: item.plans.map((p) => ({
                                        plan: p.plan,
                                        frequency: p.frequency,
                                        color:
                                          optionsPlans.filter(
                                            (i) => i.plan == p.plan
                                          ).length > 0
                                            ? optionsPlans.filter(
                                                (i) => i.plan == p.plan
                                              )[0].backgroundColor
                                            : null,
                                      })),
                                    })
                                  ),
                              },
                            ]}
                          />
                        ) : (
                          <RowHolder>
                            <Row
                              style={{
                                height: formValues.secondMonthlyDisinfection
                                  ? '52px'
                                  : 'auto',
                              }}
                            >
                              <Cell label="Frequency">
                                {/* <Info>5x Weekly</Info> */}
                                <Info
                                  style={{
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent:
                                      formValues.secondMonthlyDisinfection
                                        ? 'flex-start'
                                        : 'center',
                                    padding: '5px',
                                    paddingTop: '4px',
                                    width: minorThan768 ? '100%' : '132px',
                                  }}
                                >
                                  <>
                                    {`${
                                      formValues.secondCleaning || 0
                                    }x Monthly`}
                                    {formValues.secondMonthlyDisinfection && (
                                      <span
                                        style={{
                                          color: '#F7A11D',
                                          fontSize: '10px',
                                          marginTop: '-6px',
                                        }}
                                      >
                                        & Protection+ Disinfect
                                      </span>
                                    )}
                                  </>
                                </Info>
                              </Cell>
                              <Cell
                                label="Days Serviced"
                                width="210px"
                                style={{ paddingLeft: '12px' }}
                              >
                                {/* <Info>Mon, Tue, Wed, Thu, Fri</Info> */}
                                <Info>MONTHLY</Info>
                              </Cell>
                              <Cell
                                label="Time Window"
                                style={{ minWidth: '170px' }}
                              >
                                {/* <Info>After 6pm</Info> */}
                                <Info style={{ padding: '5px' }}>
                                  {formValues.secondTimeWindow}
                                </Info>
                              </Cell>
                              <Cell label="Monthly Price">
                                <Info>
                                  <strong>${secondFrequencyPrice}</strong>
                                </Info>
                              </Cell>
                            </Row>
                          </RowHolder>
                        )}
                      </>
                    )}
                  </>
                )}
                {franchiseData &&
                  franchiseData.SalesTax &&
                  !hasProtectionPackages && (
                    <DisclaimerParagraph
                      italic
                      paddingTop="15px"
                      paddingBottom="0px"
                      marginTop="0px"
                      marginBottom="0px"
                      width={'100%'}
                      style={{
                        fontSize: '14px',
                        paddingTop: '10px',
                        textAlign: 'right',
                      }}
                    >
                      <i>
                        <small className="tm">†</small> Plus Applicable Sales
                        Tax.
                      </i>
                    </DisclaimerParagraph>
                  )}
              </Holder>
            )}
            <Holder margin="0 0 40px">
              <Heading size="24px" italic>
                Specialty Service(s)
              </Heading>

              {!hasQuotes && !formValues.monthlyPorterPrice && (
                <Row>
                  <Cell width="430px" textAlign="left">
                    No specialty services selected.
                  </Cell>
                </Row>
              )}

              {(hasQuotes ||
                formValues.monthlyPorterPrice > 0 ||
                !!formValues.porterOverride) && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <Row>
                    <CellHeader width="430px">Service Type</CellHeader>
                    <CellHeader style={{ width: '200px' }}>
                      Price
                      <small>
                        {franchiseData && franchiseData.SalesTax && (
                          <sup>†</sup>
                        )}
                        <sup>‡</sup>
                      </small>
                    </CellHeader>
                  </Row>

                  {hasQuotes &&
                    formValues.quotes &&
                    formValues.quotes
                      .filter(
                        (quote) =>
                          convertStringToNumber(quote.quotePrice) > 0 ||
                          convertStringToNumber(quote.quoteSqft) > 0 ||
                          convertStringToNumber(quote.quoteTotal) > 0 ||
                          convertStringToNumber(quote.quoteOverride) > 0 ||
                          convertStringToNumber(quote.unitPrice) > 0 ||
                          quote.uponRequest
                      )
                      .map((quote) => (
                        <Row>
                          <Cell label="Service types" width="430px">
                            <Info>{quote.quoteSelect}</Info>
                          </Cell>
                          <Cell label="Price" style={{ width: '200px' }}>
                            <Info>
                              {!quote.uponRequest && !quote.unit && (
                                <>
                                  {formatPrice(
                                    quote.perHour || quote.perMonth
                                      ? quote.quotePrice
                                      : convertStringToNumber(
                                          quote.quoteOverride
                                        ) > 0
                                      ? quote.quoteOverride
                                      : quote.quoteTotal
                                  )}
                                </>
                              )}
                              {quote.perHour ? ' /hr.' : ''}
                              {quote.perMonth && ' /mo.'}
                              {quote.uponRequest && 'Upon Request'}
                              {quote.unit &&
                                `${formatPrice(quote.unitPrice)} per ${
                                  quote.unitLabel
                                }`}
                              {!quote.perHour &&
                                !quote.perMonth &&
                                !quote.uponRequest &&
                                !quote.unit &&
                                ' /srvc.'}
                            </Info>
                          </Cell>
                        </Row>
                      ))}

                  {(formValues.monthlyPorterPrice > 0 ||
                    !!formValues.porterOverride) && (
                    <Row>
                      <Cell
                        label="Service types"
                        width="430px"
                        style={{ width: '430px' }}
                      >
                        <Info>
                          {`Day Porter Services ${getDaysPorterDays(
                            formValues.porterDays.join(', ')
                          )} for ${formValues.porterHoursDay} hours per day.`}
                        </Info>
                      </Cell>
                      <Cell label="Price" style={{ width: '200px' }}>
                        <Info style={{ flexDirection: 'column', padding: 5 }}>
                          <Info>
                            {formatPrice(
                              !!formValues.porterOverride
                                ? formValues.porterOverride
                                : formValues.monthlyPorterPrice
                            )}{' '}
                            /mo.
                          </Info>
                          {formValues.includeInPrice && <span>(Included)</span>}
                        </Info>
                      </Cell>
                    </Row>
                  )}
                </div>
              )}

              {(hasQuotes ||
                formValues.monthlyPorterPrice > 0 ||
                !!formValues.porterOverride) && (
                <>
                  {franchiseData && franchiseData.SalesTax && (
                    <DisclaimerParagraph
                      italic
                      paddingTop="15px"
                      paddingBottom="0px"
                      marginTop="0px"
                      marginBottom="0px"
                      width={'100%'}
                      style={{
                        fontSize: '14px',
                        paddingTop: '2px',
                        textAlign: 'right',
                      }}
                    >
                      <i style={{}}>
                        <small className="tm">†</small> Plus Applicable Sales
                        Tax.
                      </i>
                    </DisclaimerParagraph>
                  )}
                  <DisclaimerParagraph
                    italic
                    textAlign="justify !important"
                    style={{ fontSize: '14px', lineHeight: '15px' }}
                  >
                    <>
                      {formValues.serviceFooter && (
                        <small className="tm">‡</small>
                      )}
                      {formValues.serviceFooter}
                    </>
                  </DisclaimerParagraph>
                </>
              )}
            </Holder>
          </Content>

          <Content bgColor="#f0f0f0">
            <Attachments
              ref={attachmentsRef}
              selectedAttachments={bidFullData['SelectedAttachments']}
              selectedSystemAttachments={
                bidFullData['SelectedSystemAttachments']
              }
              selectedFranchiseAttachments={
                bidFullData['SelectedFranchiseAttachments']
              }
              franchiseName={bidFullData['FranchiseName']}
              franchiseId={franchiseId}
              haveInsurance={franchiseData['HaveInsurance']}
              systemAttachments={systemAttachments}
              franchiseAttachments={franchiseAttachments}
              setWarningModal={setWarningModal}
              refreshAttachments={getAttachmentsFromServer}
              showDeleteConfirmationModal={showDeleteConfirmationModal}
            />
          </Content>

          {/* <Content>
            <Holder margin="0 0 40px">
              <Heading size="24px">
                <i>Select Your Initial Follow-Up Date</i>
              </Heading>
            </Holder>

            <Holder>
              <Row>
                <Cell width="80px">
                  <div>
                    <img src={calendar} width="40" height="45" />
                    <Chevron
                      width={15}
                      fill={'#777'}
                      style={{ padding: '10px', transform: 'rotate(90deg)' }}
                    />
                  </div>
                </Cell>
                <Cell>
                  <DatePicker
                    onChange={(date) => setInitialFollowUpDate(date)}
                    onBlur={() => {}}
                    className={`input-proposal-datepicker`}
                    selected={initialFollowUpDate}
                    style={{ border: 'none' }}
                    popperModifiers={{
                      offset: {
                        enabled: true,
                        offset: '5px, 10px',
                      },
                    }}
                  />
                </Cell>
              </Row>
            </Holder>
          </Content> */}

          <Content bgColor="white">
            <Holder margin="0 0 20px">
              <Heading size="24px" italic>
                Email Recipient(s)
              </Heading>
              <Text>Please select at least one contact</Text>
            </Holder>

            <Holder margin="0 0 20px 10px">
              <Row>
                <Cell width="50%" textAlign="left">
                  {hasDecisionMakerEmail && (
                    <Heading size="18px">
                      Decision Maker:
                      <span>{formValues.decisionMakerName}</span>
                    </Heading>
                  )}
                  {!hasDecisionMakerEmail &&
                    'No decision maker email supplied.'}{' '}
                </Cell>

                <Cell textAlign="left">
                  {hasWalkThruEmail && (
                    <Heading size="18px">
                      Walk-Thru:
                      <span>{formValues.walkThruName}</span>
                    </Heading>
                  )}
                  {!hasWalkThruEmail && 'No walk-thru email supplied'}
                </Cell>
              </Row>

              <Row>
                <Cell width="50%">
                  {hasDecisionMakerEmail && (
                    <Checkbox
                      label={
                        <InputLabel style={{ marginLeft: '10px' }}>
                          {formValues.decisionMakerEmail}
                        </InputLabel>
                      }
                      borderColor="gray"
                      name="decisionMakerEmail"
                      value={formValues.decisionMakerEmail}
                      onChange={onContactsChange}
                      checked={emailSelected.includes('decisionMakerEmail')}
                    />
                  )}
                </Cell>

                <Cell>
                  {hasWalkThruEmail && (
                    <Checkbox
                      label={
                        <InputLabel style={{ marginLeft: '10px' }}>
                          {formValues.walkThruEmail}
                        </InputLabel>
                      }
                      borderColor="gray"
                      name="walkThruEmail"
                      value={formValues.walkThruEmail}
                      onChange={onContactsChange}
                      checked={emailSelected.includes('walkThruEmail')}
                    />
                  )}
                </Cell>
              </Row>

              <Row>
                <Cell width="50%">
                  <CheckboxHolder>
                    <Checkbox
                      borderColor="gray"
                      name="otherEmail"
                      value={otherEmailProposal}
                      onChange={(...args) => {
                        setOtherEmailProposalChecked(args[2]);
                      }}
                      checked={otherEmailProposalChecked}
                    />
                    <InputText
                      style={
                        otherEmailProposalChecked
                          ? {
                              borderRadius: '5px',
                              padding: '2.5px',
                              border: '1px solid #515151',
                              outline: 'none',
                            }
                          : { marginLeft: '10px' }
                      }
                      disabled={!otherEmailProposalChecked}
                      type="email"
                      placeholder="Other"
                      value={otherEmailProposal}
                      name="otherContact"
                      onChange={(e) => {
                        setOtherEmailProposal(e.currentTarget.value);
                      }}
                    />
                  </CheckboxHolder>
                </Cell>
              </Row>
            </Holder>
          </Content>

          <Footer>
            <div>
              <Button
                color="#fff"
                bgColor="#216eab"
                width="auto"
                padding="0 25px"
                onClick={onBackButtonClick}
              >
                <ButtonContent>
                  <FontAwesomeIcon icon={faReply} />
                  <ButtonLabel>BACK</ButtonLabel>
                </ButtonContent>
              </Button>
            </div>

            <div>
              {haveModifiedTerms ? (
                <Button
                  color="#FFFFFF"
                  bgColor="#EB5757"
                  width="auto"
                  padding="0 15px"
                  reverseSkew={true}
                  onClick={onCustomizeTermsButtonClick}
                >
                  <ButtonContent>
                    <FontAwesomeIcon icon={faPencilAlt} />
                    <ButtonLabel>REVIEW CUSTOM TERMS</ButtonLabel>
                  </ButtonContent>
                </Button>
              ) : (
                <Button
                  color="#F7A11D"
                  width="auto"
                  padding="0 15px"
                  kind="outline"
                  reverseSkew={true}
                  onClick={onCustomizeTermsButtonClick}
                >
                  <ButtonContent>
                    <FontAwesomeIcon icon={faEdit} />
                    <ButtonLabel>CUSTOMIZE TERMS</ButtonLabel>
                  </ButtonContent>
                </Button>
              )}

              <Button
                color="#fff"
                bgColor="#F7A11D"
                width="auto"
                padding="0 15px"
                reverseSkew={true}
                onClick={() => {
                  setIsLoading(true);
                  onSaveButtonClick();
                }}
              >
                <ButtonContent>
                  <FontAwesomeIcon inverse icon={faEnvelope} />
                  <ButtonLabel>SAVE AND CONTINUE</ButtonLabel>
                </ButtonContent>
              </Button>
            </div>
          </Footer>

          {/* {haveModifiedTerms && (
            <DisclaimerParagraph
              style={{
                color: 'gray',
                margin: 'auto',
                marginBottom: '30px',
                width: '60%',
              }}
            >
              Warning, this Proposal contains one or more paragraphs that have
              been previously edited and may contain modifications from the
              default template.
            </DisclaimerParagraph>
          )} */}
        </Wrapper>
      </>
    );
  }
);
