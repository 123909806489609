import React from 'react';
import { Prompt } from 'react-router';
// components
import { LoadingModal, ActionAlertModal, Button } from 'core/components';
import { getQueryString } from 'core/utils';
import { isWebView } from 'core/utils';
import { parseFromApi } from '../../parser';
import Modal from './Modal';
import PdfModal from './pdfmodal';

import {
  getPerformancesByUserId,
  getNextPagePerformance,
} from '../../services/performanceEvaluationService';
import { getInspectionURL } from '../../../BidInfoForm/utils';

export const PerformanceEvaluationMenuScreen = (props) => {
  const { search } = props.location;
  const {
    renderTemplateSection = false,
    renderDraftSection = false,
    renderSentSection = true,
    renderSignedSection = true,
    showBackground = true,
  } = props;
  const [showGreetModal, setShowGreetModal] = React.useState(false);
  const [formItem, setFormItem] = React.useState({});
  const [isPdfShowing, setIsPdfShowing] = React.useState(false);
  const [pdfBase64, setPdfBase64] = React.useState(false);
  const [pdfId, setPdfId] = React.useState(false);
  const [data, setData] = React.useState({
    ['Signed']: [],
    ['Drafts']: [],
    ['Sents']: [],
    ['Templates']: [],
  });
  const [currentPage, setCurrentPage] = React.useState(0);
  const [searchPid, setSearchPid] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [itemsCurrentPage, setItemsCurrentPage] = React.useState({
    Signed: 0,
    Templates: 0,
    Drafts: 0,
    Sents: 0,
  });

  const archiveForm = (formItem) => {
    // setIsLoading(true);
    // postArchiveBid({
    //   PEFUniqID: formItem.PEFUniqID,
    //   isArchived: true,
    //   BidInformationSheetId: formItem.BidInformationSheetId,
    // }).then((result) => {
    //   const newData = { ...data };
    //   Object.keys(newData).forEach((key) => {
    //     if (Array.isArray(newData[key])) {
    //       newData[key] = newData[key].filter(
    //         (i) =>
    //           i['PEFUniqID'] != formItem.PEFUniqID &&
    //           i['BidInformationSheetId'] != formItem.BidInformationSheetId
    //       );
    //     }
    //   });
    //   setData(newData);
    //   setIsLoading(false);
    // });
  };

  const getNextPage = () => {
    setIsLoading(true);
    const userId = getQueryString(search, 'userId');
    setCurrentPage(currentPage + 1);
  };
  //http://anago-forms.api.staging.test.conceptsol.com/api/BidSheet/GetBidsByUser?id=4&page=0&pid=

  React.useEffect(() => {
    const userId = getQueryString(search, 'userId');
    if (userId) {
      getPerformancesByUserId(userId, currentPage, searchPid, {}).then(
        (foundValues) => {
          setIsLoading(false);
          // set types to drafts, sents and templates, so we can identify the items later
          setData({
            ...data,
            ...foundValues.data,
            ['Signed']: [...data['Signed'], ...foundValues.data['Signed']],
            ['Drafts']: [...data['Drafts'], ...foundValues.data['Drafts']],
            ['Sents']: [...data['Sents'], ...foundValues.data['Sents']],
            ['Templates']: [
              ...data['Templates'],
              ...foundValues.data['Templates'],
            ],
          });
        }
      );
    }
  }, [search, searchPid]);

  const getPerformanceNextPage = (type: string) => {
    const userId = getQueryString(search, 'userId');
    const nextPage = itemsCurrentPage[type] + 1;
    if (userId) {
      getNextPagePerformance(type, userId, nextPage, searchPid).then(
        (foundValues) => {
          setIsLoading(false);
          setItemsCurrentPage({
            ...itemsCurrentPage,
            [type]: nextPage,
          });
          // set types to drafts, sents and templates, so we can identify the items later
          setData({
            ...data,
            [type]: [...data[type], ...foundValues.data[type]],
          });
        }
      );
    }
  };

  const navigateToForm = (formItem, type) => {
    setFormItem(formItem);
    if (type === 'template') {
      setShowGreetModal(true);
    } else {
      openForm(formItem);
    }
  };

  const openURL = (item, str) => {
    if (props.doneAction) {
      props.doneAction(true);
    }
    props.history.push({
      pathname: '/performance-evaluation',
      search: `?userId=${userId}&${str}&v=${Math.random()
        .toString(36)
        .substring(7)}`,
    });
  };

  // const openOldBidURL = (item, str) => {
  //   if (props.doneAction) {
  //     props.doneAction(true);
  //   }
  //   props.history.push({
  //     pathname: '/bid-info-form/old-pdf-view',
  //     search: `?userId=${userId}&${str}`,
  //   });
  // };

  const navigate = (item, param = '') => {
    if (props.doneAction) {
      props.doneAction(true);
    }
    if (item['PEFUniqID']) {
      openURL(item, `formUniqueId=${item['PEFUniqID']}${param}`);
    } else {
      openURL(item, `formId=${item['PerformanceEvaluationId']}${param}`);
    }
  };

  // const openPDF = async (item) => {
  // if (props.doneAction) {
  //   props.doneAction(true);
  // }
  // setIsLoading(true);
  // if (isWebView()) {
  //   openOldBidURL(
  //     item,
  //     `formId=${item['BidInformationSheetId']}&openingOldBidPDF=true`
  //   );
  // } else {
  //   const pdfBase64 = await getPdfBase64(item['BidInformationSheetId']);
  //   let pdfWindow = window.open(
  //     window.location.href + '&code=print-pdf',
  //     '_blank'
  //   );
  //   pdfWindow.document.write(
  //     "<iframe width='99.6%' height='100%' src='" +
  //       encodeURI(pdfBase64) +
  //       "'></iframe>"
  //   );
  //   setIsLoading(false);
  //   // openOldBidURL(
  //   //   item,
  //   //   `formId=${item['BidInformationSheetId']}&openingOldBidPDF=true`
  //   // );
  // }
  // };

  const userId = getQueryString(search, 'userId');

  const openForm = (item) => {
    if (props.doneAction) {
      props.doneAction(true);
    }
    const isSentType = item.type === 'sents';

    if (isSentType) {
      navigate(item);
    } else {
      if (item['PEFUniqID']) {
        openURL(item, `formUniqueId=${item['PEFUniqID']}`);
      } else {
        openURL(item, `formId=${item['BidInformationSheetId']}`);
      }
    }
  };

  const onEditTemplateClick = () => {
    if (props.doneAction) {
      props.doneAction(true);
    }
    openForm(formItem);
  };

  const onNewDraftClick = async () => {
    if (props.doneAction) {
      props.doneAction(true);
    }
    setIsLoading(true);

    const goGetIt = formItem['PEFUniqID'];
    //   ? () => getByUniqID(formItem['PEFUniqID'])
    //   : () => getBidInfoById(formItem['BidInformationSheetId']);
    // const response = await goGetIt();

    // const parsed = parseFromApi({
    //   ...formItem,
    //   ...response.data.Data,
    // });
    // const saveDraftResponse = await saveDraft(parsed, userId);
    // const response2 = await getByUniqID(saveDraftResponse.data.id);

    // openForm(response2.data.Data);
    // setIsLoading(false);
  };

  return (
    <>
      {isLoading && <LoadingModal localLoading />}

      <Modal
        navigateToForm={navigateToForm}
        data={data}
        doneAction={
          props.doneAction
            ? () => props.doneAction(false)
            : () => {
                if (isWebView()) {
                  document.location.replace('/#/close-form');
                } else {
                  document.location.href = getInspectionURL();
                }
              }
        }
        background={showBackground}
        renderSignedSection={renderDraftSection}
        renderDraftSection={renderDraftSection}
        renderTemplateSection={renderTemplateSection}
        renderSentSection={renderSentSection}
        renderSignedSection={renderSignedSection}
        getNextPage={getPerformanceNextPage}
        archiveForm={archiveForm}
      />

      <ActionAlertModal
        title="Template"
        message="Do you want to edit or start a new draft based on the current template?"
        onConfirm={onNewDraftClick}
        confirmText="NEW DRAFT"
        onDismiss={onEditTemplateClick}
        dismissText="EDIT TEMPLATE"
        isVisible={showGreetModal}
      ></ActionAlertModal>
    </>
  );
};
