import React, { memo, useCallback } from 'react';
import { withFormik, FormikProps } from 'formik';
import { IBidProtectionDisinfectionPlans } from 'modules/BidInfoForm/interfaces';
import {
  ContentInput,
  InputFloatLabel,
  InputFloatLabelWithValidation,
  Accordion,
  IconButton,
  useFormProps,
  Tooltip,
} from 'core/components';
import {
  moneyMask,
  moneyMaskParams,
  decimalTwoPlace,
  decimalTwoPlaceParams,
} from 'core/utils/masks';
import {
  getAlphabetLetter,
  convertStringToNumber,
  formatPrice,
  toTitleCase,
} from 'core/utils';
import { formatNumberByMask } from 'core/utils/inputFormatsV2';
import { getProtectionJson } from 'modules/BidInfoForm/services/bidInfoService';
import styled from 'styled-components';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProtectionSpecification } from '../';
import './styles.scss';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { orderingItems } from '../CleaningSpecification/constants';
import { useGenericErrorHandler } from 'core/hooks/useGenericErrorHandler';
import {
  ButtonGroup,
  DisabledBoxValue,
  Select,
  BoxInput,
} from '../../../../../../core/components';

import { Select as SelectV2 } from 'core/components/SelectV2';

import {
  protectionPackageDefaultValue,
  protectionPackagePlanDefaultValue,
  optionsPlans,
} from 'modules/shared/bidDefaultValues';

const boxInputStyles = {
  maxwidth: '20%',
  border: '1px solid gray',
  height: '46px',
  paddingRight: '10px',
  borderRadius: '0px',
  boxShadow: 'none',
  appearance: 'none',
  outline: 'none',
  textAlign: 'center',
};

const ProtectionPlanBadgeWrapper = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 25px 10px 25px;
  color: white;
`;

const ProtectionPlanName = styled.span`
  padding-top: 5px;
  opacity: 0.6;
  font-size: 16px;
  font-family: OpenSans-Bold;
  transform: scale(1, 1.4);
  letter-spacing: normal;
`;

const ProtectionLabel = styled.span`
  font-family: OpenSans-LightItalic;
  font-size: 20px;
  font-family: OpenSans-SemiBoldItalic;
  transform: scale(1, 2);
  letter-spacing: 1px;
`;

const ProtectionAddServiceWrapper = styled.div`
  font-family: OpenSans-Bold;
  display: flex;
  flex-direction: row;
  color: #515151;
  align-items: center;
  padding-top: 15px;
`;

const ProtectionDisinfectionServiceRow = styled.div`
  padding: 20px;
  padding-top: 5px;
  background-color: #ededed;
  border: 1px solid silver;
  border-radius: 10px;
`;

const AddProtectionServiceButtonWrapper = styled.button`
  font-size: 16px;
  font-family: 'OpenSans-Regular';
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: lightgray;
  color: #515151;
  border-radius: 10px;
  width: 210px;
  height: 58px;
  margin-left: 20px;
  margin-bottom: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 6px 0px;
  &:disabled {
    opacity: 0.7;
  }
  margin-bottom: 5px;
`;

const ProtectionServiceWrapper = styled.div``;

const ProtectionServiceTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: OpenSans-Bold;
  padding-top: 20px;
`;

const ProtectionPackageWrapper = styled.div`
  border-radius: 10px;
  margin-top: 20px;
  border: 1px solid silver;
  padding: 10px 15px;
  position: relative;
  background-color: white;
`;

const PackageDeleteButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none !important;
  border: none;
  cursor: pointer;
`;

const ProtectionPackageTitle = styled.span`
  font-family: OpenSans-BoldItalic;
`;

const TooltipIconWrapper = styled.div`
  margin-left: 20px;
  border: 1px solid #216eab;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-right: 10px;
  height: 20px;
  width: 20px;
  border-radius: 20px;
`;

const AddProtectionOptionButton = styled(AddProtectionServiceButtonWrapper)`
  margin-left: 0px;
  width: 230px;
  margin-top: 10px;
`;

const PlanBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProtectionPackagePlanRow = styled.div`
  display: flex;
  flex-direction: row;

  ${PlanBox} {
    :not(:first-child) {
      margin-left: 20px;
    }
  }
`;

const ProtectionPackagePlanWrapper = styled.div`
  ${ProtectionPackagePlanRow} {
    margin-top: 15px;
  }
`;

const ProtectionPackateFooter = styled(ProtectionPackagePlanRow)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const AddPlanButton = styled.button`
  align-items: center;
  background-color: #ddd;
  border-radius: 6px;
  cursor: pointer;
  font-size: 12px;
  margin: auto;
  margin-right: 0;
  margin-top: 20px;
  margin-bottom: 5px;
  border: none;
  outline: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-self: flex-end;
  padding: 15px 12px;
  width: 110px;
  font-size: 16px;
  font-family: OpenSans-Regular;
`;

const ProtectionPlanRowDeleteButton = styled.button`
  background: none !important;
  border: none;
  cursor: pointer;
`;

const ProtectionPackagePlan = ({
  item: { plan, frequency, sqFtApplied, prodRate, perHour, subtotal },
  index,
  packageKey,
  disabled,
  plansOptions,
  frequencyOptions,
  sqftAppliedOptions,
  onClickRemovePlan,
  onChangePlanValues,
  onChange,
  className,
}) => {
  return (
    <ProtectionPackagePlanWrapper className={className}>
      <ProtectionPackagePlanRow>
        <PlanBox style={{ width: '32%' }} className="plan-box">
          Plan
          <SelectV2
            id={`plan_${packageKey}_${index}`}
            value={optionsPlans.filter((i) => i.plan == plan)[0]}
            disabled={disabled}
            items={plansOptions || []}
            getLabelFunction={(item) =>
              typeof item === 'object' ? `${item.plan}` : item
            }
            getStyleFunction={(i) => ({ color: i.color })}
            getInputStyleFunction={(i) => ({
              color: i.color,
              fontStyle: 'italic',
              textShadow: `0px 0px 1px ${i.color}`,
            })}
            getValueFunction={(i) => (typeof i === 'object' ? `${i.plan}` : i)}
            getLabelInputFuction={(item: any) =>
              typeof item === 'object' ? `${item.plan}` : item
            }
            name={`plan`}
            onChange={(name, value) => onChange(index, 'plan', value.plan)}
            width="100%"
            canTypeCustomValue={false}
            blockCustomInput={true}
            autoSelectOnBlur
          />
        </PlanBox>
        <PlanBox style={{ width: '32%' }} className="frequency-box">
          Frequency
          <SelectV2
            value={frequency}
            canTypeCustomValue={false}
            blockCustomInput={true}
            disabled={disabled}
            items={frequencyOptions || []}
            name={`frequency`}
            onChange={(name, value) => {
              onChangePlanValues(value, sqFtApplied, prodRate, perHour);
            }}
            // onChange={(name, value) => onChange(index, name, value)}
            width="100%"
          />
        </PlanBox>
      </ProtectionPackagePlanRow>
      <ProtectionPackagePlanRow style={{ justifyContent: 'flex-end' }}>
        <PlanBox style={{ width: '17%' }} className="sqFtApplied-box">
          Sq. Ft. Applied
          <SelectV2
            onlyNumbers
            // value={formatNumberByMask(sqFtApplied, {
            //   prefix: '',
            //   allowDecimal: true,
            //   // decimalLimit: 2,
            //   thousandsSeparatorSymbol: true,
            //   type: 'tel',
            // })}
            value={sqFtApplied}
            disabled={disabled}
            items={sqftAppliedOptions}
            id={`sqFtApplied_${packageKey}_${index}`}
            name="sqFtApplied"
            type="tel"
            onChange={(name, obj) => {
              const value = typeof obj === 'object' ? obj.value : obj;
              onChangePlanValues(frequency, value, prodRate, perHour);
            }}
            width="100%"
            getLabelInputFuction={(item: any) =>
              typeof item === 'object' ? `${item.value}` : item
            }
            getLabelFunction={(item: any) =>
              typeof item === 'object'
                ? `${item.label} - ${`${formatNumberByMask(item.value, {
                    prefix: '',
                    allowDecimal: true,
                    // decimalLimit: 2,
                    thousandsSeparatorSymbol: true,
                    type: 'tel',
                  })}`}`
                : item
            }
            getValueFunction={(item: any) =>
              typeof item === 'object' ? item.value : item
            }
            canTypeCustomValue={true}
          />
        </PlanBox>
        <PlanBox style={{ width: '17%' }} className="prodRate-box">
          <InputFloatLabel
            value={prodRate}
            disabled={disabled}
            id={`prodRate_${packageKey}_${index}`}
            label="Prod Rate"
            type="tel"
            name={'prodRate'}
            mask={decimalTwoPlace}
            maskParams={decimalTwoPlaceParams}
            onChange={(name, value) =>
              onChangePlanValues(frequency, sqFtApplied, value, perHour)
            }
            onBlur={() => {}}
          />
        </PlanBox>
        <PlanBox style={{ width: '17%' }} className="perHour-box">
          <InputFloatLabelWithValidation
            mask={moneyMask}
            maskParams={{
              ...moneyMaskParams,
              thousandsSeparatorSymbol: true,
            }}
            onBlur={(name, value) => {
              onChangePlanValues(frequency, sqFtApplied, prodRate, value);
            }}
            id={`perHour_${packageKey}_${index}`}
            value={perHour}
            disabled={disabled}
            label={'$/hr'}
            type="tel"
            name={'perHour'}
            onChange={(name, value) =>
              onChangePlanValues(frequency, sqFtApplied, prodRate, value)
            }
          />
        </PlanBox>
        <PlanBox style={{ width: '17%' }} className="subtotal-box">
          Subtotal
          <BoxInput
            value={formatPrice(subtotal || 0)}
            disabled={true}
            inputStyle={boxInputStyles}
            minWidth="100%"
            name="subtotal"
            onChange={(name, value) => onChange(index, name, value)}
            mask={moneyMask}
            maskParams={moneyMaskParams}
            flex
            flexDirection="flex-end"
          />
        </PlanBox>
        <PlanBox style={{ justifyContent: 'center' }}>
          <ProtectionPlanRowDeleteButton
            onClick={onClickRemovePlan}
            style={{ justifyContent: 'center', paddingTop: '25px' }}
          >
            <i className="trash-icon" />
            <span>Delete</span>
          </ProtectionPlanRowDeleteButton>
        </PlanBox>
      </ProtectionPackagePlanRow>
    </ProtectionPackagePlanWrapper>
  );
};

const ProtectionPackage = ({
  frequencyOptions,
  sqftAppliedOptions,
  item: { index, plans, total, totalOverride },
  disabled,
  onClickDelete,
  onClickAdd,
  onChange,
  onClickAddPlan,
  onClickRemovePlan,
  onChangePlanValues,
  filteredPlans,
}) => {
  const [biggerThan768, setBiggerThan768] = React.useState(false);

  const setBreakpoints = () => {
    setBiggerThan768(window.innerWidth >= 769);
  };

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  return (
    <>
      <ProtectionPackageWrapper
        data-cy={`protection-package_${index}`}
        key={`protection-package_${index}`}
        className={`protection-package_${index}`}
      >
        <PackageDeleteButton onClick={onClickDelete}>
          <i className="trash-icon" />
          <span>Delete Option</span>
        </PackageDeleteButton>
        <ProtectionPackageTitle>
          Protection+ Package {getAlphabetLetter(index)}
        </ProtectionPackageTitle>

        {plans &&
          plans.map((plan, idx) => {
            return (
              <ProtectionPackagePlan
                data-cy={`protection-package-plan_${index}_${idx}`}
                key={`protection-package-plan_${index}_${idx}`}
                className={`protection-package-plan_${index}_${idx}`}
                packageKey={`protection-package-plan_${index}_${idx}`}
                index={idx}
                item={plan}
                disabled={disabled}
                onChange={onChange('plans')}
                onChangePlanValues={onChangePlanValues(idx)}
                plansOptions={filteredPlans}
                onClickRemovePlan={() => onClickRemovePlan(idx)}
                frequencyOptions={frequencyOptions.filter(
                  (key) => plans.filter((p) => p.frequency == key).length == 0
                )}
                sqftAppliedOptions={sqftAppliedOptions}
              />
            );
          })}

        {plans.length < 3 && (
          <AddPlanButton onClick={() => onClickAddPlan()}>
            <FontAwesomeIcon icon={faPlus} size="lg" color="#999" />
            Plan(s)
          </AddPlanButton>
        )}

        <ProtectionPackateFooter>
          <PlanBox style={{ width: '45%', marginTop: '15px' }}>
            <DisabledBoxValue
              maxWidth="100%"
              minWidth="100%"
              labelWidth="60%"
              valueStyle={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
              labelStyle={{
                fontSize: biggerThan768 ? null : '14px',
              }}
              value={formatPrice(total || 0)}
              label={`Package ${getAlphabetLetter(index)} Calc'd Total:`}
            />
          </PlanBox>
          <PlanBox style={{ width: '18%', marginLeft: '10px' }}>
            <InputFloatLabelWithValidation
              mask={moneyMask}
              maskParams={{
                ...moneyMaskParams,
                thousandsSeparatorSymbol: true,
              }}
              onBlur={(name, value) => {
                onChange('totalOverride')(-1, undefined, value);
              }}
              disabled={disabled}
              className="break-label"
              label={`Package ${getAlphabetLetter(index)} Override:`}
              name={`totalOverride_${index}`}
              value={totalOverride}
              onChange={(name, value) =>
                onChange('totalOverride')(-1, undefined, value)
              }
            />
          </PlanBox>
        </ProtectionPackateFooter>
      </ProtectionPackageWrapper>
      {onClickAdd && (
        <AddProtectionOptionButton
          onClick={onClickAdd}
          style={{
            fontSize: biggerThan768 ? null : '14px',
            width: biggerThan768 ? null : '205px',
          }}
        >
          Add 2nd Protection+ Option
        </AddProtectionOptionButton>
      )}
    </>
  );
};

const ProtectionService = ({
  title,
  packages,
  disabled,
  onAddPackage,
  onAddPlan,
  onRemovePlan,
  onDeletePackage,
  onChangePackages,
  onChangeIncluded,
  includedInPrice,
  frequencyOptions,
  onChangePlanValues,
  sqftAppliedOptions,
  ...props
}) => {
  return (
    <ProtectionServiceWrapper>
      <ProtectionServiceTitleWrapper>
        {title}
        <ButtonGroup
          disabled={disabled || packages.length > 1}
          tabIndexable={false}
          options={[
            { value: true, label: 'Include in Price' },
            { value: false, label: 'Quote Separately' },
          ]}
          optionLabel="label"
          optionValue="value"
          type="oneOption"
          name="includeInPrice"
          onChange={onChangeIncluded}
          value={!!includedInPrice}
          style={{
            marginLeft: '0px',
            transformOrigin: 'right',
            transform: 'scale(.8)',
          }}
          buttonsStyle={{
            width: '105px',
            padding: '10px',
          }}
        />
        <Tooltip
          overlay={
            <span
              style={{
                fontSize: '13px',
                display: 'block',
                textAlign: 'justify',
              }}
            >
              Include in price is only available when a single Package is added
              to a quote.
            </span>
          }
          trigger="click"
          placement="topLeft"
          alignedItemId={`includeInPrice_${title}`}
          positioningFunction={(node, align) => {
            const targetEl = document.getElementById(`includeInPrice_${title}`);
            const rect = targetEl.getBoundingClientRect();
            node.style.left = `${rect.left - 25}px`;
          }}
        >
          <TooltipIconWrapper
            id={`includeInPrice_${title}`}
            onClick={(e) => e.stopPropagation()}
          >
            <FontAwesomeIcon icon={faInfo} size="xs" color="#216eab" />
          </TooltipIconWrapper>
        </Tooltip>
      </ProtectionServiceTitleWrapper>
      {packages.map((item, index, arr) => {
        const filteredPlans = optionsPlans.filter(
          (opt) => item.plans.filter((p) => p.plan == opt.plan).length == 0
        );
        return (
          <ProtectionPackage
            item={item}
            disabled={disabled}
            filteredPlans={filteredPlans}
            onChange={onChangePackages(index)}
            onChangePlanValues={onChangePlanValues(index)}
            onClickAdd={
              arr.length - 1 == index && arr.length < 2 && !includedInPrice
                ? onAddPackage
                : undefined
            }
            onClickAddPlan={onAddPlan(index)}
            onClickRemovePlan={onRemovePlan(index)}
            onClickDelete={() => onDeletePackage(index)}
            frequencyOptions={frequencyOptions}
            sqftAppliedOptions={sqftAppliedOptions}
          />
        );
      })}
    </ProtectionServiceWrapper>
  );
};

const AddProtectionServiceButton = (props) => {
  return (
    <AddProtectionServiceButtonWrapper {...props}>
      Add Protection+ Service
    </AddProtectionServiceButtonWrapper>
  );
};

const ProtectionPlanBadge = ({ children, planStyle, ...props }) => {
  return (
    <ProtectionPlanBadgeWrapper {...props}>
      <ProtectionLabel>PROTECTION +</ProtectionLabel>
      <ProtectionPlanName style={planStyle}>{children}</ProtectionPlanName>
    </ProtectionPlanBadgeWrapper>
  );
};

type IProps = FormikProps<IBidProtectionDisinfectionPlans>;
const ProtectionDisinfectionPlans = memo(
  ({
    values,
    setFieldValue,
    setValues,
    heightChanged,
    setHeightChanged,
  }: IProps) => {
    const {
      formValues,
      onChange,
      setFormValues,
      showDeleteConfirmationModal,
      setNeedsSaving,
    } = useFormProps();

    const onBlur = useCallback(
      (name: string) => () => onChange(name, formValues[name]),
      [formValues, onChange]
    );
    const isSmartCleanSet = formValues.smartClean && formValues.addFrequency;
    const hasMonthlyFrequency = formValues.frequencyService === 'monthly';
    const { setError } = useGenericErrorHandler();
    const hasSecondFrequency =
      formValues.secondFrequency &&
      !hasMonthlyFrequency &&
      formValues.secondFrequency.selecteds &&
      formValues.secondFrequency.selecteds.length > 0;

    const hasSecondMonthlyFrequency =
      hasMonthlyFrequency &&
      formValues.secondCleaning &&
      formValues.monthlyAddFrequency;

    const hasProtectionPackages =
      formValues.protectionPackages && formValues.protectionPackages.length > 0;

    const hasSecondProtectionPackages =
      formValues.secondProtectionPackages &&
      formValues.secondProtectionPackages.length > 0;

    const [sqftAppliedOptions, setSqftAppliedOptions] = React.useState([]);

    const { floorTypeTotals, sqftTotal, inventory } = formValues;

    React.useEffect(() => {
      if (
        floorTypeTotals.length === 1 &&
        floorTypeTotals[0].totalSqft === undefined
      ) {
        return;
      }
      let calculatedSqftTotal = sqftTotal;
      if (!sqftTotal) {
        inventory
          .filter((item: any, index: any) => item.floorType)
          .forEach((item: any) => {
            calculatedSqftTotal =
              (calculatedSqftTotal || 0) +
              parseFloat(`${item.totalSqft || 0}`.replace(/[,]/g, ''));
          });
      }

      const result = [];
      floorTypeTotals.forEach(
        (item: any) =>
          item.floorType &&
          result.push({
            key: item.floorType,
            label: `${formatNumberByMask(item.floorType, {
              prefix: '',
              allowDecimal: true,
              // decimalLimit: 2,
              thousandsSeparatorSymbol: true,
              type: 'tel',
            })}`,
            value: item.totalSqft,
          })
      );
      if (calculatedSqftTotal) {
        result.push({
          key: 'total',
          label: `Total Sq. Ft.`,
          value: calculatedSqftTotal,
        });
      }
      setSqftAppliedOptions(result);
    }, [floorTypeTotals]);

    const getQuoteTitle = (isMonthly = false) =>
      `${formValues.smartClean ? 'SmartClean' : 'Quote 1'}: ${
        formValues.quoteTitle ||
        (isMonthly
          ? `${formValues.cleaning}x `
          : `${
              formValues.smartClean
                ? formValues.frequency.selecteds.length +
                  ((formValues.secondFrequency &&
                    formValues.secondFrequency.selecteds.length) ||
                    0)
                : formValues.frequency.selecteds.length
            }x `) + `${toTitleCase(formValues.frequencyService)}`
      }`;

    const getSecondQuoteTitle = (isMonthly = false) =>
      `Quote 2: ${
        formValues.secondQuoteTitle ||
        (isMonthly
          ? `${formValues.secondCleaning}x `
          : `${
              (formValues.secondFrequency &&
                formValues.secondFrequency.selecteds.length) ||
              0
            }x `) + `${toTitleCase(formValues.frequencyService)}`
      }`;

    const onAddProtectionPackages = (name, value) => {
      if (name == 'protectionPackages') {
        const packages = [...formValues.protectionPackages];
        packages.push({ ...value, index: packages.length });
        onChange(name, packages);

        const secondPackages = formValues.secondProtectionPackages.map(
          (i, idx) => ({ ...i, index: packages.length + idx })
        );
        onChange('secondProtectionPackages', secondPackages);
      } else {
        const packages = [...formValues.secondProtectionPackages];
        packages.push({
          ...value,
          index: formValues.protectionPackages.length + packages.length,
        });
        onChange(name, packages);
      }
    };

    const onAddProtectionPlan = (packageName) => (index) => () => {
      const pkg = [...formValues[packageName]];
      pkg[index].plans.push({ ...protectionPackagePlanDefaultValue });
      onChange(packageName, pkg);
    };

    const onRemoveProtectionPlan = (packageName) => (index) => (planIndex) => {
      showDeleteConfirmationModal(() => {
        const pkg = [...formValues[packageName]];
        const newPlans = [...pkg[index].plans];
        newPlans.splice(planIndex, 1);
        pkg[index].plans = newPlans;
        onChange(packageName, pkg);
      });
    };

    const onRemoveProtectionPackages = (name, index) => {
      showDeleteConfirmationModal(() => {
        if (name == 'protectionPackages') {
          const packages = formValues.protectionPackages
            .filter((i, idx) => idx != index)
            .map((i, idx) => ({ ...i, index: idx }));
          onChange(name, packages);

          const secondPackages = formValues.secondProtectionPackages.map(
            (i, idx) => ({ ...i, index: packages.length + idx })
          );
          onChange('secondProtectionPackages', secondPackages);
        } else {
          const packages = formValues.secondProtectionPackages
            .filter((i, idx) => idx != index)
            .map((i, idx) => ({
              ...i,
              index: formValues.protectionPackages.length + idx,
            }));

          onChange(name, packages);
        }
      });
    };

    const onChangeProtectionPackages =
      (packageName) =>
      (packageIndex) =>
      (propertyName) =>
      (index, name, value) => {
        const pkg = [...formValues[packageName]];
        if (index != -1) {
          pkg[packageIndex][propertyName][index][name] = value;
        } else {
          pkg[packageIndex][propertyName] = value;
        }

        onChange(packageName, [...pkg]);
      };

    const getCleaningFrequency = () => {
      const cleaningFreq = [...orderingItems];
      cleaningFreq.pop();
      cleaningFreq.pop();
      return cleaningFreq;

      // if (sessionStorage.getItem('sessionStorageCleaningSpecification')) {
      //   return JSON.parse(
      //     sessionStorage.getItem('sessionStorageCleaningSpecification')
      //   );
      // } else {
      //   return {};
      // }
    };

    React.useEffect(() => {
      try {
        const protectionPackages = JSON.parse(
          JSON.stringify([...formValues.protectionPackages])
        );

        protectionPackages.map((pkg) => {
          let total = 0;
          pkg.plans.map((plan) => {
            // plan.subtotal =
            //   (plan.sqFtApplied / plan.prodRate) *
            //   (hasMonthlyFrequency
            //     ? formValues.cleaning
            //     : formValues.frequency.selecteds.length == 7
            //     ? 7.5
            //     : formValues.frequency.selecteds.length) *
            //   plan.perHour *
            //   4.33;
            const days = hasMonthlyFrequency
              ? formValues.cleaning
              : formValues.frequency.selecteds.length == 7
              ? 7.5
              : formValues.frequency.selecteds.length;

            plan.subtotal = calculatePlanSubtotal(
              days,
              plan.frequency,
              plan.sqFtApplied,
              plan.prodRate,
              plan.perHour
            );

            total += plan.subtotal || 0;
          });
          pkg.total = total;
        });

        if (
          JSON.stringify(protectionPackages) !=
          JSON.stringify(formValues.protectionPackages)
        ) {
          setFormValues({
            ...formValues,
            protectionPackages: protectionPackages,
          });

          setNeedsSaving(false);
        }
      } catch (e) {
        setError(e);
      }
    }, [
      formValues.frequency.selecteds,
      formValues.cleaning,
      hasMonthlyFrequency,
      formValues.protectionPackages,
    ]);

    React.useEffect(() => {
      try {
        const secondProtectionPackages = JSON.parse(
          JSON.stringify([...formValues.secondProtectionPackages])
        );
        if (
          (formValues.secondFrequency &&
            formValues.secondFrequency.selecteds) ||
          formValues.secondCleaning
        ) {
          secondProtectionPackages.map((pkg) => {
            let total = 0;
            pkg.plans.map((plan) => {
              // plan.subtotal =
              //   (plan.sqFtApplied / plan.prodRate) *
              // (hasMonthlyFrequency
              //   ? formValues.secondCleaning
              //   : formValues.frequency.selecteds.length == 7
              //   ? 7.5
              //   : formValues.frequency.selecteds.length) *
              //   plan.perHour *
              //   4.33;
              const days = hasSecondMonthlyFrequency
                ? formValues.secondCleaning
                : hasSecondFrequency
                ? formValues.secondFrequency.selecteds.length == 7
                  ? 7.5
                  : formValues.secondFrequency.selecteds.length
                : 0;

              plan.subtotal = calculatePlanSubtotal(
                days,
                plan.frequency,
                plan.sqFtApplied,
                plan.prodRate,
                plan.perHour
              );
              total += plan.subtotal || 0;
            });
            pkg.total = total;
          });
          if (
            JSON.stringify(secondProtectionPackages) !=
            JSON.stringify(formValues.secondProtectionPackages)
          ) {
            setFormValues({
              ...formValues,
              secondProtectionPackages: secondProtectionPackages,
            });

            setNeedsSaving(false);
          }
        }
      } catch (e) {
        setError(e);
      }
    }, [
      formValues.secondFrequency,
      formValues.secondCleaning,
      hasMonthlyFrequency,
      formValues.secondProtectionPackages,
    ]);

    const calculatePlanSubtotal = (
      days,
      frequency,
      sqFtApplied,
      prodRate,
      perHour
    ) => {
      let subtotal = 0;
      if (frequency) {
        switch (frequency.toLowerCase()) {
          case 'nightly':
            if (convertStringToNumber(prodRate)) {
              subtotal =
                (convertStringToNumber(sqFtApplied) /
                  convertStringToNumber(prodRate)) *
                (convertStringToNumber(days) == 7
                  ? 7.5
                  : convertStringToNumber(days)) *
                convertStringToNumber(perHour) *
                4.33;
            }
            break;
          case 'weekly':
            if (convertStringToNumber(prodRate)) {
              subtotal =
                (convertStringToNumber(sqFtApplied) /
                  convertStringToNumber(prodRate)) *
                convertStringToNumber(perHour) *
                4.33;
            }
            break;
          case 'every other week':
            if (convertStringToNumber(prodRate)) {
              subtotal =
                (convertStringToNumber(sqFtApplied) /
                  convertStringToNumber(prodRate)) *
                convertStringToNumber(perHour) *
                (4.33 / 2);
            }
            break;
          case 'twice per month':
            if (convertStringToNumber(prodRate)) {
              subtotal =
                (convertStringToNumber(sqFtApplied) /
                  convertStringToNumber(prodRate)) *
                convertStringToNumber(perHour) *
                2;
            }
            break;
          case 'monthly':
            if (convertStringToNumber(prodRate)) {
              subtotal =
                (convertStringToNumber(sqFtApplied) /
                  convertStringToNumber(prodRate)) *
                convertStringToNumber(perHour);
            }
            break;
          case 'every other month':
            if (convertStringToNumber(prodRate)) {
              subtotal =
                ((convertStringToNumber(sqFtApplied) /
                  convertStringToNumber(prodRate)) *
                  convertStringToNumber(perHour)) /
                2;
            }
            break;
          case 'quarterly':
            if (convertStringToNumber(prodRate)) {
              subtotal =
                ((convertStringToNumber(sqFtApplied) /
                  convertStringToNumber(prodRate)) *
                  convertStringToNumber(perHour)) /
                3;
            }
            break;
          case 'semi annual':
            if (convertStringToNumber(prodRate)) {
              subtotal =
                ((convertStringToNumber(sqFtApplied) /
                  convertStringToNumber(prodRate)) *
                  convertStringToNumber(perHour)) /
                6;
            }
            break;
          case 'annual':
            if (convertStringToNumber(prodRate)) {
              subtotal =
                ((convertStringToNumber(sqFtApplied) /
                  convertStringToNumber(prodRate)) *
                  convertStringToNumber(perHour)) /
                12;
            }
            break;
        }
      }
      return subtotal;
    };

    const onChangeProtectionPackagePlanValues =
      (packageName, days) =>
      (packageIndex) =>
      (planIndex) =>
      (frequency, sqFtApplied, prodRate, perHour) => {
        try {
          const subtotal = calculatePlanSubtotal(
            days,
            frequency,
            sqFtApplied,
            prodRate,
            perHour
          );

          // const subtotal = 777;

          const newPackage = JSON.parse(
            JSON.stringify(formValues[packageName])
          );

          newPackage[packageIndex].plans[planIndex] = {
            ...newPackage[packageIndex].plans[planIndex],
            frequency: frequency,
            subtotal: !isNaN(subtotal) ? subtotal : 0,
            sqFtApplied: sqFtApplied,
            prodRate: prodRate,
            perHour: perHour,
          };
          const packageTotal = newPackage[packageIndex].plans.reduce(
            (acc, el) => {
              acc += el.subtotal;
              return acc;
            },
            0
          );
          newPackage[packageIndex].total = !isNaN(packageTotal)
            ? packageTotal
            : 0;

          // setNeedsSaving(true);
          onChange(packageName, newPackage);
        } catch (e) {
          setError(e);
        }
      };

    return (
      <div className="protection-disinfection-plans-root">
        <div className="protection-disinfection-plans-content">
          {/* <ContentInput flex justify="space-between" flexDirection="row">
            <ProtectionPlanBadge
              style={{ backgroundColor: '#216EAB' }}
              planStyle={{ color: 'white' }}
            >
              BLUE
            </ProtectionPlanBadge>
            <ProtectionPlanBadge
              style={{ backgroundColor: '#F7A11D' }}
              planStyle={{ color: '#BA6706', opacity: 1 }}
            >
              ORANGE
            </ProtectionPlanBadge>
            <ProtectionPlanBadge
              style={{ backgroundColor: '#959595' }}
              planStyle={{ color: 'black', opacity: 0.55 }}
            >
              PLATINUM
            </ProtectionPlanBadge>
          </ContentInput> */}

          <ContentInput
            width="100%"
            padding="0"
            justify="flex-start"
            flexDirection="column"
          >
            <ProtectionDisinfectionServiceRow>
              {!hasProtectionPackages && (
                <ProtectionAddServiceWrapper>
                  {getQuoteTitle(hasMonthlyFrequency)}
                  <AddProtectionServiceButton
                    onClick={() =>
                      onAddProtectionPackages('protectionPackages', {
                        ...protectionPackageDefaultValue,
                        plans: [{ ...protectionPackagePlanDefaultValue }],
                      })
                    }
                  />
                </ProtectionAddServiceWrapper>
              )}

              {hasProtectionPackages && (
                <ProtectionService
                  title={getQuoteTitle(hasMonthlyFrequency)}
                  packages={formValues.protectionPackages}
                  includedInPrice={formValues.protectionIncludedInPrice}
                  frequencyOptions={getCleaningFrequency().map((i) =>
                    toTitleCase(i)
                  )}
                  sqftAppliedOptions={sqftAppliedOptions}
                  onAddPackage={() =>
                    onAddProtectionPackages('protectionPackages', {
                      ...protectionPackageDefaultValue,
                      plans: [{ ...protectionPackagePlanDefaultValue }],
                    })
                  }
                  onDeletePackage={(index) =>
                    onRemoveProtectionPackages('protectionPackages', index)
                  }
                  onAddPlan={onAddProtectionPlan('protectionPackages')}
                  onRemovePlan={onRemoveProtectionPlan('protectionPackages')}
                  onChangePlanValues={onChangeProtectionPackagePlanValues(
                    'protectionPackages',
                    formValues.frequencyService == 'weekly'
                      ? formValues.frequency.selecteds.length
                      : formValues.cleaning
                  )}
                  onChangePackages={onChangeProtectionPackages(
                    'protectionPackages'
                  )}
                  onChangeIncluded={(name, value) =>
                    onChange('protectionIncludedInPrice', value)
                  }
                  disabled={formValues.status == 2}
                />
              )}
            </ProtectionDisinfectionServiceRow>

            {(hasSecondFrequency || hasSecondMonthlyFrequency) && (
              <ProtectionDisinfectionServiceRow style={{ marginTop: '20px' }}>
                {!hasSecondProtectionPackages && (
                  <ProtectionAddServiceWrapper>
                    {getSecondQuoteTitle(hasSecondMonthlyFrequency)}
                    <AddProtectionServiceButton
                      onClick={() =>
                        onAddProtectionPackages('secondProtectionPackages', {
                          ...protectionPackageDefaultValue,
                          plans: [{ ...protectionPackagePlanDefaultValue }],
                        })
                      }
                    />
                  </ProtectionAddServiceWrapper>
                )}

                {hasSecondProtectionPackages && (
                  <ProtectionService
                    title={getSecondQuoteTitle(hasSecondMonthlyFrequency)}
                    packages={formValues.secondProtectionPackages}
                    includedInPrice={formValues.secondProtectionIncludedInPrice}
                    frequencyOptions={getCleaningFrequency().map((i) =>
                      toTitleCase(i)
                    )}
                    sqftAppliedOptions={sqftAppliedOptions}
                    onAddPackage={() =>
                      onAddProtectionPackages('secondProtectionPackages', {
                        ...protectionPackageDefaultValue,
                        plans: [{ ...protectionPackagePlanDefaultValue }],
                      })
                    }
                    onDeletePackage={(index) =>
                      onRemoveProtectionPackages(
                        'secondProtectionPackages',
                        index
                      )
                    }
                    onAddPlan={onAddProtectionPlan('secondProtectionPackages')}
                    onRemovePlan={onRemoveProtectionPlan(
                      'secondProtectionPackages'
                    )}
                    onChangePlanValues={onChangeProtectionPackagePlanValues(
                      'secondProtectionPackages',
                      formValues.frequencyService == 'weekly'
                        ? (formValues.secondFrequency &&
                            formValues.secondFrequency.selecteds.length) ||
                            0
                        : formValues.secondCleaning
                    )}
                    onChangePackages={onChangeProtectionPackages(
                      'secondProtectionPackages'
                    )}
                    onChangeIncluded={(name, value) =>
                      onChange('secondProtectionIncludedInPrice', value)
                    }
                    disabled={formValues.status == 2}
                  />
                )}
              </ProtectionDisinfectionServiceRow>
            )}
          </ContentInput>
          <ProtectionSpecification
            changeHeight={() => setHeightChanged(!heightChanged)}
            formValues={formValues}
            onChange={onChange}
            showDeleteConfirmationModal={showDeleteConfirmationModal}
          />
        </div>
      </div>
    );
  }
);

const mapForm = {
  mapPropsToValues: () => ({}),
  handleSubmit: () => '',
};

const EnhancedProtectionDisinfectionPlans = withFormik<
  any,
  IBidProtectionDisinfectionPlans
>(mapForm)(ProtectionDisinfectionPlans);

export { EnhancedProtectionDisinfectionPlans as ProtectionDisinfectionPlans };
